import React, { useState } from "react";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  FormControl,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { SettingsWizardPageProps } from "./SettingsWizardPage";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { UpsyPreview, UpsyPreviewContainer } from "./UpsyPreview";
import { transformCommonProperties } from "../../common/ConfigurationsContext/dashboardToBackendTransformer";
import { useWidgetContextAndScreenByTabIndex } from "./useWidgetContextAndScreenByTabIndex";
import { ALLOWED_LANGUAGE, allowedLanguage } from "../../Configs/language";
import CustomSelectField from "../../common/CustomUiComponent/Select";
import { Language } from "../../common/ConfigurationsContext/Configuration";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export function SettingsSummary({ id }: SettingsWizardPageProps) {
  const { configuration } = useConfiguration(id);
  const [value, setValue] = React.useState(0);
  const { screen, context } = useWidgetContextAndScreenByTabIndex(value);
  const [selectedLang, setSelectedLang] = useState<
    ALLOWED_LANGUAGE | "default"
  >("default");

  const handleLangChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setSelectedLang(e.target.value as ALLOWED_LANGUAGE);
  };

  const classes = useClasses();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography
        gutterBottom
        style={{ fontWeight: 700 }}
        variant="h6"
        color="textPrimary"
      >
        Summary
      </Typography>
      <Typography
        gutterBottom
        style={{ fontWeight: 500 }}
        color="textSecondary"
      >
        You can see the summary of the latest version of the UPSY widget and
        publish the widget after you approve the changes.
      </Typography>
      <Grid container item xs={12} justify="flex-end">
        <Grid item xs={12} style={{ maxWidth: 300, marginLeft: -10 }}>
          <FormControl>
            <CustomSelectField
              value={selectedLang}
              labelFor="language"
              labelText="Language"
              placeholder="Language"
              variant="outlined"
              name="language"
              fullWidth
              onChange={handleLangChange}
            >
              <MenuItem value={"default"}>Default language</MenuItem>
              {Object.entries(allowedLanguage).map((entry) => (
                <MenuItem key={entry[0]} value={entry[1]}>
                  {entry[0]}
                </MenuItem>
              ))}
            </CustomSelectField>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            classes={{ root: classes.tabsRootStyle }}
            aria-label="Vertical tabs example"
          >
            <Tab label="Home Page" {...a11yProps(0)} />
            <Tab label="Category" {...a11yProps(1)} />
            <Tab label="New Arrivals" {...a11yProps(2)} />
            <Tab label="Daily Deals" {...a11yProps(3)} />
            <Tab label="Up Sell" {...a11yProps(4)} />
            <Tab label="Cross Sell" {...a11yProps(5)} />
            <Tab label="FAQs" {...a11yProps(6)} />
          </Tabs>
        </Grid>
        <Grid item xs={12} lg={9}>
          {configuration ? (
            <React.Fragment>
              <Typography
                className={classes.previewText}
                style={{ fontWeight: 700 }}
                variant="h6"
                color="textPrimary"
              >
                Preview
              </Typography>
              <UpsyPreviewContainer minHeight={348}>
                <UpsyPreview
                  widgetContext={context}
                  widgetScreen={screen}
                  config={{
                    ...transformCommonProperties(configuration)
                      .frontendConfiguration,
                    language: selectedLang as Language,
                  }}
                ></UpsyPreview>
              </UpsyPreviewContainer>
            </React.Fragment>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const useClasses = createUseClasses((theme) => ({
  previewText: {
    marginBottom: "-10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  },
  tabsRootStyle: {
    marginTop: 42,
    marginRight: 24,
    [theme.breakpoints.down("md")]: {
      marginTop: 6,
      marginRight: 0,
      "& .MuiTabs-flexContainerVertical": {
        flexDirection: "row",
        gap: "6px",
      },
    },
    "& span": {
      backgroundColor: "unset",
    },
    "& button": {
      backgroundColor: "#F5F5F5",
      marginBottom: "3px",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "140%",
      color: "#9E9E9E",
      textTransform: "capitalize",
    },
    "& .Mui-selected": {
      backgroundColor: "#ffffff",
      color: "#6793FF",
      border: "1px solid #6793FF",
    },
  },
}));
