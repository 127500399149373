import { createContext, useContext, useState } from "react";
import { ChildComponentsProps } from "../Types/ProductDescription";
import { useProductDescriptionGeneratorChildComponent } from "../Hooks/useProductDescriptionGeneratorChildComponent";

type ProductDescriptionContenxt = {
  componentProps: ChildComponentsProps;
  componentType: ChildComponentsProps["type"];
  updateComponentType: React.Dispatch<
    React.SetStateAction<ChildComponentsProps["type"]>
  >;
  isWithUpsy: boolean;
  updateIsWithUpsy: React.Dispatch<React.SetStateAction<boolean>>;
};
const ProductDescriptionContext = createContext<
  ProductDescriptionContenxt | undefined
>(undefined);

export const ProductDescriptionProvider: React.FC = ({ children }) => {
  const { componentProps, componentType, updateComponentType } =
    useProductDescriptionGeneratorChildComponent();
  const [isWithUpsy, updateIsWithUpsy] = useState(false);

  return (
    <ProductDescriptionContext.Provider
      value={{
        componentProps,
        componentType,
        updateComponentType,
        isWithUpsy,
        updateIsWithUpsy,
      }}
    >
      {children}
    </ProductDescriptionContext.Provider>
  );
};

export const useProductDescriptionContext = () => {
  const context = useContext(ProductDescriptionContext);
  if (!context) {
    throw new Error(
      "useProductDescriptionContext must be used within a ProductDescriptionProvider"
    );
  }
  return context;
};
