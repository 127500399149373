import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import useStateWithValidation, {
  ValidationErrors,
} from "../../../common/Hooks/useStateWithValidation";
import { Alert } from "@material-ui/lab";
import CustomSelectField from "../../../common/CustomUiComponent/Select";
import CustomTextField from "../../../common/CustomUiComponent/TextField";
import { useProductDescriptionChat } from "../Hooks/useProductDescriptionChat";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { ChatCard, ChatCardSkeleton } from "./Components/ChatCard";
import { useProductDescriptionContext } from "../Context/productDescriptionGenerate";
import { useApiKey } from "./ConstentAndInfoScreen";

export type DescriptionGeneratorProps = {
  children?: React.ReactNode;
};

export type DescriptionGeneratorState = {
  title: string;
  language: string;
  attributes?: string;
};

const descriptionGenerateFormvalidationRules = (
  state: DescriptionGeneratorState
): ValidationErrors<DescriptionGeneratorState> => {
  const errors: ValidationErrors<DescriptionGeneratorState> = {};

  const { title = "", language = "", attributes = "" } = state;

  const trimmedTitle = title.trim();
  const trimmedLanguage = language.trim();
  const trimmedAttributes = attributes.trim();

  const validateField = (
    field: string,
    fieldName: keyof ValidationErrors<DescriptionGeneratorState>,
    minLength: number,
    maxLength: number,
    requiredMessage: string,
    lengthMessage: string
  ) => {
    if (!field) {
      errors[fieldName] = requiredMessage;
    } else if (field.length < minLength || field.length > maxLength) {
      errors[fieldName] = lengthMessage;
    }
  };

  validateField(
    trimmedTitle,
    "title",
    3,
    100,
    "Product title is required",
    "Product title must be between 3 and 100 characters"
  );
  validateField(
    trimmedLanguage,
    "language",
    3,
    15,
    "Language is required",
    "Language must be between 3 and 15 characters"
  );
  if (trimmedAttributes) {
    validateField(
      trimmedAttributes,
      "attributes",
      3,
      100,
      "",
      "Product attributes must be between 3 and 100 characters"
    );
  }

  return errors;
};

const languages = [
  { language: "english" },
  { language: "finnish" },
  { language: "dutch" },
  { language: "german" },
  { language: "swedish" },
];

export default function DescriptionGenerator(props: DescriptionGeneratorProps) {
  const initialState = {
    title: "",
    language: "finnish",
    attributes: "",
  };
  const { chats, loading, error, generate, clearError } =
    useProductDescriptionChat();

  const lastChat = [...chats].pop();

  const { updateComponentType, isWithUpsy } = useProductDescriptionContext();

  const { apiKey } = useApiKey();

  const {
    state: { title, language, attributes },
    errors,
    isValid,
    update,
    isDirty,
  } = useStateWithValidation(
    initialState,
    descriptionGenerateFormvalidationRules
  );

  function handleChatGenerate() {
    if (!isValid) return;
    (async function () {
      await generate({
        title: title,
        language: language,
        apiKey: !isWithUpsy ? apiKey : undefined,
        attributes,
        withUpsy: isWithUpsy,
      });
      update("title", "", false);
      update("attributes", "", false);
    })();
  }

  return (
    <Grid container item style={{ position: "relative" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        item
        justify="flex-end"
        style={{ marginBottom: "14px", gap: 12 }}
      >
        <Button
          onClick={() => {
            updateComponentType("ConsentAndInfoScreen");
          }}
        >
          <ArrowBack fontSize="small" style={{ marginRight: 6 }} /> Back
        </Button>
        <Button
          onClick={() => {
            updateComponentType("DescriptionHistory");
          }}
        >
          Local History
          <ArrowForward fontSize="small" style={{ marginLeft: 6 }} />
        </Button>
      </Grid>
      <Grid item container direction="column">
        {!isWithUpsy ? (
          <Grid style={{ marginBlock: 10 }}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontStyle: "italic" }}
            >
              *You are using your personal api key: {apiKey}*
            </Typography>
          </Grid>
        ) : null}
        {error ? (
          <Alert
            severity="error"
            onClose={() => {
              clearError();
            }}
          >
            {error}
          </Alert>
        ) : null}
        <Grid>
          <FormControl
            error={isDirty.language && errors.language ? true : false}
          >
            <CustomSelectField
              required
              value={language}
              onChange={(e) => update("language", e.target.value as string)}
              style={{ width: "50%" }}
              variant="outlined"
              labelText="Select response language"
              labelFor="language"
              id="language"
            >
              {languages.map((language) => (
                <MenuItem value={language.language} key={language.language}>
                  <small>{language.language}</small>
                </MenuItem>
              ))}
            </CustomSelectField>
            {isDirty.language && errors.language ? (
              <FormHelperText>{errors.language}</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid>
          <FormControl>
            <CustomTextField
              error={isDirty.title && errors.title ? true : false}
              helperText={isDirty.title && errors.title ? errors.title : ""}
              required
              value={title}
              onChange={(e) => update("title", e.target.value as string)}
              labelText="Product Title"
              labelFor="Product Title"
              variant="outlined"
              placeholder="Enter product Title"
              autoComplete="off"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid>
          <FormControl>
            <CustomTextField
              error={isDirty.attributes && errors.attributes ? true : false}
              helperText={
                isDirty.attributes && errors.attributes ? errors.attributes : ""
              }
              value={attributes}
              onChange={(e) => update("attributes", e.target.value as string)}
              labelText="Product Atrributes/Tags"
              labelFor="Product Atrributes/Tags"
              variant="outlined"
              placeholder="Enter products attributes/tags"
              autoComplete="off"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid>
          <Button
            variant="outlined"
            color="primary"
            disabled={loading || !isValid}
            onClick={handleChatGenerate}
          >
            {" "}
            {loading ? "Loading..." : "Generate"}{" "}
          </Button>
        </Grid>
      </Grid>
      <Grid item container style={{ marginTop: 20 }}>
        {loading ? (
          <ChatCardSkeleton />
        ) : lastChat ? (
          <ChatCard key={lastChat.id} chat={{ ...lastChat }} />
        ) : null}
      </Grid>
    </Grid>
  );
}
