import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useConfiguration } from "../../../common/ConfigurationsContext/ConfigurationsContext";
import CustomTextField from "../../../common/CustomUiComponent/TextField";
import { SettingsWizardPageProps } from "../SettingsWizardPage";
import ButtonGroup from "./ButtonGroup";
import useStyles from "./style";
import useHelper from "./useHelper";
import { useGreetingLang } from "../GrettingLangContext";

export default function Category({ id }: SettingsWizardPageProps) {
  const classes = useStyles();
  const { configuration, setCommonFrontendConfiguration } =
    useConfiguration(id);

  const { countCharacter, errorMessageLabel } = useHelper();
  const { lang } = useGreetingLang();
  const upperText =
    lang === "default"
      ? configuration?.greetings?.category?.upperText || ""
      : configuration?.greetings?.category?.locale?.[lang]?.upperText || "";
  const [upperTextState, setUpperTextState] = useState(upperText);
  useEffect(() => {
    setUpperTextState(upperText);
  }, [lang, upperText]);
  const defaultHandler = () => {
    const defaultUpperText =
      configuration?.greetings?.category?.upperText || "";
    setUpperTextState(defaultUpperText);
  };
  const submithandler = () => {
    if (countCharacter(upperTextState, 20)) return false;
    let updatedData = {
      ...(configuration?.greetings?.category || {}),
      upperText: upperTextState || "",
    };

    if (lang !== "default") {
      updatedData = {
        ...(configuration?.greetings?.category || {}),
        upperText: configuration?.greetings?.category?.upperText || "",
        locale: {
          ...(configuration?.greetings?.category?.locale || {}),
          [lang]: {
            upperText: upperTextState || "",
          },
        },
      };
    }

    setCommonFrontendConfiguration({
      greetings: {
        ...configuration?.greetings,
        category: updatedData,
      },
    });
  };

  const handleChange = (value: string) => {
    setUpperTextState(value);
  };
  return (
    <>
      <Typography className={classes.title}>Upper Text</Typography>
      <CustomTextField
        onChange={(e) => handleChange(e.target.value)}
        value={upperTextState}
        error={countCharacter(upperTextState, 20)}
        placeholder={"Hello there!"}
        helperText={countCharacter(upperTextState, 20) && errorMessageLabel(20)}
      />

      <ButtonGroup
        submithandler={submithandler}
        defaultHandler={defaultHandler}
      />
    </>
  );
}
