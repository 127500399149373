import React from "react";
import {
  activeWidgetScreenEnum,
  ActiveWidgetScreenType,
  UpsyContextEnum,
  UpsyContextType,
} from "./types/widgetPreview";

export interface IWidgetHookReturn {
  context: UpsyContextType;
  screen: ActiveWidgetScreenType;
}

const widgetContextAndScreenEntries: [
  UpsyContextType | null,
  ActiveWidgetScreenType
][] = [
  [null, activeWidgetScreenEnum.GREETINGLG],
  [UpsyContextEnum.CATEGORY, activeWidgetScreenEnum.WIDGETMINIFIED],
  [UpsyContextEnum.NEW_ARRIVAL, activeWidgetScreenEnum.WIDGETMINIFIED],
  [UpsyContextEnum.DAILY_DEALS, activeWidgetScreenEnum.WIDGETEXPANDED],
  [UpsyContextEnum.UP_SELL, activeWidgetScreenEnum.WIDGETMINIFIED],
  [UpsyContextEnum.CROSS_SELL, activeWidgetScreenEnum.WIDGETMINIFIED],
  [UpsyContextEnum.FAQ, activeWidgetScreenEnum.WIDGETEXPANDED],
  [null, activeWidgetScreenEnum.FEEDBACK],
];

export function useWidgetContextAndScreenByTabIndex(
  index: number
): IWidgetHookReturn {
  const [context, setContext] = React.useState<UpsyContextType>(
    UpsyContextEnum.CATEGORY
  );
  const [screen, setScreen] = React.useState<ActiveWidgetScreenType>(
    activeWidgetScreenEnum.GREETINGLG
  );

  React.useEffect(() => {
    const context = widgetContextAndScreenEntries[index][0];
    const screen = widgetContextAndScreenEntries[index][1];
    context && setContext(context);
    screen && setScreen(screen);
  }, [index]);

  return { context, screen };
}
