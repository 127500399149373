import React from "react";
import { UserContextProvider } from "./common/UserContext/UserContext";
import { HashRouter } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { Theme } from "./common/Theme/Theme";
import { ConfigurationsContextProvider } from "./common/ConfigurationsContext/ConfigurationsContext";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";

function App() {
  return (
    <Theme>
      <UserContextProvider>
        <ConfigurationsContextProvider>
          <HashRouter>
            <AppRoutes />
          </HashRouter>
        </ConfigurationsContextProvider>
      </UserContextProvider>
    </Theme>
  );
}

export default App;
