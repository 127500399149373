import {
  defaultPlatform,
  IConfiguration,
  IConfigurationCommonBackend,
  newConfigurationId,
  Platform,
} from "../../common/ConfigurationsContext/Configuration";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  IntegrationWizardPageProps,
  IntegrationWizardPageType,
} from "./IntegrationWizardPage";

import { CustomHtmlTooltip } from "./../../common/Tooltip/Tooltip";
import { WooCommerceSpecificConfiguration } from "./WooCommerceSpecificConfiguration";
import CustomTextField from "../../common/CustomUiComponent/TextField";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import CustomSelectField from "../../common/CustomUiComponent/Select";

export function CommonBackendConfiguration({
  id,
  setPageType,
  isWooCommerceSpecificConfiguration,
}: IntegrationWizardPageProps & {
  setPageType: (pageType: IntegrationWizardPageType) => void;
  isWooCommerceSpecificConfiguration: boolean;
}) {
  const classes = useCommonBackendConfigurationClasses();
  const { configuration, setCommonBackendConfiguration } = useConfiguration(id);
  const { platform, storeName, storeNameFormatted, storeUrl } =
    getPartialCommonBackendConfiguration(configuration);
  return (
    <>
      <Typography className={classes.title}>
        Store Platform Information
      </Typography>
      <Typography className={classes.subTitle}>
        The integration process is to connect the UPSY widget to your website.
        This setup only needs to be done once. After this integration, UPSY will
        be ready to work.
      </Typography>
      <Grid
        container
        item
        xs={12}
        md={9}
        lg={8}
        justify="space-between"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={12} lg={5}>
          <CustomHtmlTooltip title="Give a name to your store. The name must be one worded (no spaces).">
            <CustomTextField
              required
              labelText="Store Name"
              labelFor="Store Name"
              variant="outlined"
              placeholder="Store Name"
              InputProps={{
                readOnly: id !== newConfigurationId,
              }}
              value={storeNameFormatted || storeName}
              inputProps={{ maxLength: 25 }}
              onChange={(event) =>
                setCommonBackendConfiguration({
                  storeName: event.target.value.replace(/ /g, "_"),
                })
              }
            />
          </CustomHtmlTooltip>
        </Grid>
        <Grid item xs={12} lg={5}>
          <CustomHtmlTooltip title="Provide the web address of your store.">
            <CustomTextField
              required
              labelText="Store Url"
              labelFor="Store Url"
              placeholder="Store Url"
              value={storeUrl}
              onChange={(event) =>
                setCommonBackendConfiguration({ storeUrl: event.target.value })
              }
            />
          </CustomHtmlTooltip>
        </Grid>
        <Grid item xs={12} lg={5}>
          <CustomHtmlTooltip title="Select the ecommerce platform your store is based on">
            <FormControl>
              <CustomSelectField
                labelFor="Platform"
                labelText="Platform"
                placeholder="Platform"
                value={platform}
                fullWidth
                variant="outlined"
                onChange={(event) =>
                  setCommonBackendConfiguration({
                    platform: event.target.value as Platform,
                  })
                }
              >
                {[Platform.WooCommerce].map((platform) => {
                  return <MenuItem value={platform}>{platform}</MenuItem>;
                })}
              </CustomSelectField>
            </FormControl>
          </CustomHtmlTooltip>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.platformSpecificConfigurationWrapper}>
        {isWooCommerceSpecificConfiguration && (
          <WooCommerceSpecificConfiguration id={id} setPageType={setPageType} />
        )}
      </div>
    </>
  );
}

function getPartialCommonBackendConfiguration(
  configuration?: IConfiguration
): IConfigurationCommonBackend {
  console.log(configuration);
  return {
    id: configuration?.id ?? newConfigurationId,
    platform: configuration?.platform ?? defaultPlatform,
    storeName: configuration?.storeName ?? "",
    storeNameFormatted: configuration?.storeNameFormatted ?? "",
    storeUrl: configuration?.storeUrl ?? "",
  };
}

const useCommonBackendConfigurationClasses = createUseClasses((theme) => ({
  title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px 0px",
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#636364",
  },
  divider: {
    margin: "36px 0",
  },
  platformSpecificConfigurationWrapper: {
    display: "block",
    width: "100%",
    height: "auto",
    minHeight: "150px",
  },
}));
