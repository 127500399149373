import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  IConfiguration,
  newConfigurationId,
} from "../../common/ConfigurationsContext/Configuration";
import { Grid, Typography } from "@material-ui/core";
import { SettingsWizardPageProps } from "./SettingsWizardPage";
import { DynamicRoutePath, StaticRoutePath } from "../../AppRoutes";
import { Link } from "react-router-dom";
import * as React from "react";

export function SettingsSuccess({ id }: SettingsWizardPageProps) {
  const { configuration } = useConfiguration(id);
  const { storeName, storeNameFormatted } = configuration as IConfiguration;

  return (
    <>
      <Typography variant="h5">Success</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1">
            Store configuration {storeNameFormatted || storeName}{" "}
            {id === newConfigurationId ? "created" : "updated"} successfully!
          </Typography>
          <Typography variant="body1">
            Go to <Link to={StaticRoutePath.Stores}>stores list</Link> or{" "}
            <Link to={DynamicRoutePath.StoreMetrics(storeName as string)}>
              {storeNameFormatted || storeName} dashboard
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
