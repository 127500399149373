import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CustomButton } from "../CustomUiComponent/CustomButton";
import { createUseClasses } from "../Theme/createUseClasses";
import { ReactComponent as UploadIcon } from "./../../common/SVG/uploadIcon.svg";

const useClassesFileUploadWidget = createUseClasses((theme) => ({
  uploadIcon: {
    "& path": {
      fill: "currentColor",
    },
  },
}));
export type OnFileChangeEventType = (
  e: React.ChangeEvent<HTMLInputElement>
) => void;

export interface fileUploadWidgetInterface {
  id: string;
  fieldName: string;
  text: React.ReactElement | string;
  multiple?: boolean;
  className?: string;
  accept?: string;
  onChange: OnFileChangeEventType;
}

export default function FileUploadWidget({
  onChange,
  id,
  fieldName,
  text,
  multiple = false,
  accept,
  className,
}: fileUploadWidgetInterface) {
  const classes = useClassesFileUploadWidget();
  return (
    <CustomButton
      startIcon={<UploadIcon className={classes.uploadIcon} />}
      component="label"
      variant="contained"
      htmlFor={id}
      fullWidth
    >
      {text}
      <input
        accept={accept}
        type="file"
        name={fieldName}
        id={id}
        hidden
        onChange={onChange}
      />
    </CustomButton>
  );
}
