import * as React from "react";
import { useState } from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { IPasswordResetRequiredParams, StaticRoutePath } from "../AppRoutes";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Button, Grid, TextField, Typography, Link } from "@material-ui/core";
import { Title } from "../common/Title/Title";
import { AuthWrapper } from "../common/AuthWrapper/AuthWrapper";
import StyledLabel from "../common/StyledLabel/StyledLabel";

export function PasswordResetRequired() {
  const { getPasswordRecoveryCode, recoverPassword } = useUserContext();
  const { email } = useParams<IPasswordResetRequiredParams>();
  const [codeRequested, setCodeRequested] = useState(true);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordRecovered, setPasswordRecovered] = useState(false);
  const [submitError, setSubmitError] = useState<undefined | string>();

  async function getRecoveryCode() {
    try {
      if (!email) {
        return setSubmitError("No email found");
      }
      const success = await getPasswordRecoveryCode(email);
      if (success) {
        setCodeRequested(true);
      }
    } catch (error) {
      setSubmitError((error as { message?: string }).message);
    }
  }

  async function recover() {
    try {
      if (!email) {
        return setSubmitError("No email found");
      }
      const success = await recoverPassword(email, code, newPassword);
      if (success) {
        setPasswordRecovered(true);
      }
    } catch (error) {
      setSubmitError((error as { message?: string }).message);
    }
  }

  return (
    <AuthWrapper innerWrapperHeight="90%">
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Title>Please reset your account password</Title>
          <br />
          <Typography variant="body2">
            We’ve made some updates to the dashboard and require our users to
            reset their password to have those changes take effect. An email
            with the verification code should soon arrive to your email inbox.
            In case you need assistance please contact us at
            support@upsyshopping.com.
          </Typography>
        </Grid>
        {passwordRecovered ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">
                Your password was succesfully reset!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link component={RouterLink} to={StaticRoutePath.Login}>
                Login from here
              </Link>
            </Grid>
          </>
        ) : codeRequested ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body2">
                The email address linked to your user account is {email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledLabel>Code</StyledLabel>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Code"
                autoComplete="new-password"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledLabel>New Password</StyledLabel>
              <TextField
                size="small"
                variant="outlined"
                placeholder="New Password"
                type="password"
                autoComplete="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={recover}
              >
                Set new password
              </Button>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <p>{submitError}</p>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <StyledLabel>Email</StyledLabel>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Email"
                value={email}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={getRecoveryCode}
              >
                Recover password
              </Button>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <p>{submitError}</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <Link component={RouterLink} to={StaticRoutePath.Login}>
                Login from here
              </Link>
            </Grid>
          </>
        )}
      </Grid>
    </AuthWrapper>
  );
}
