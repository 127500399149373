import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IBackendConfiguration } from "../ConfigurationsContext/Configuration";
import { User } from "../UserContext/User";
import {
  generateNewAccessToken,
  getAwsTokenFromLocalStorage,
} from "../utils/token";
import ApiError from "./ApiError";
import {
  CreateUserApiError,
  CreateUserRequest,
  FetchShopConfigurationsResponse,
  GetUserResponse,
  UpsertShopConfigurationOperationType,
  UpsertShopConfigurationRequest,
} from "./BackendResponse";
import { getCognitoUserSession } from "../UserContext/CognitoUserPool";
let totalTry = 0;
const maxTryForGettingIdToken = 3;

const backendClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const backendImageUploadClient = axios.create({
  baseURL: process.env.REACT_APP_AWS_API_BASE_URL,
  headers: {
    "content-Type": "multipart/Form-data",
  },
});

backendImageUploadClient.interceptors.response.use(
  (response) => {
    totalTry = 0;
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      totalTry = totalTry + 1;
      if (totalTry <= maxTryForGettingIdToken) {
        const newToken = await generateNewAccessToken();
        error.config.headers.Authorization = newToken;
        return backendImageUploadClient(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const addAuthParameters = (user: User) =>
  `user=${encodeURIComponent(
    user?.email || ""
  )}&customerSecretKey=${encodeURIComponent(user?.secretKey || "")}`;

export const callBackend = async (
  axiosResponse: Promise<AxiosResponse>,
  errorHandler?: (errData: Object | string) => Error
): Promise<any> => {
  try {
    const response = await axiosResponse;
    return response && response.data;
  } catch (err) {
    if (err.response && err.response.data) {
      if (errorHandler) {
        errorHandler(err.response.data);
      } else {
        throw new Error(err.response.data);
      }
    }
    throw new Error("Unknown error");
  }
};

export const createUser = async (data: CreateUserRequest): Promise<User> =>
  callBackend(
    backendClient.post("user/create", data),
    (errData: CreateUserApiError | string) => {
      throw new ApiError(errData);
    }
  );

export const getUser = async (user: User): Promise<GetUserResponse> =>
  callBackend(backendClient.get(`user?${addAuthParameters(user)}`));

export const saveShopConfiguration = async (
  operationType: UpsertShopConfigurationOperationType,
  user: User,
  data: UpsertShopConfigurationRequest
): Promise<void> =>
  callBackend(
    backendClient.post(
      `shop-configuration/upsert?${addAuthParameters(
        user
      )}&type=${operationType}`,
      data
    )
  );

export const fetchShopConfigurations = async (
  user: User
): Promise<Array<FetchShopConfigurationsResponse>> =>
  callBackend(
    backendClient.get(`shop-configuration/list?${addAuthParameters(user)}`)
  );

export const testShopConfiguration = async (
  user: User,
  shopConfiguration: IBackendConfiguration
): Promise<void> =>
  callBackend(
    backendClient.post(
      `shop-configuration/test?${addAuthParameters(user)}`,
      shopConfiguration
    )
  );

export const getQuickSightEmbedUrl = async (user: User): Promise<string> =>
  callBackend(
    backendClient.get(`quicksight-embed-url?${addAuthParameters(user)}`)
  );

export const deleteStore = async (
  storeId: string,
  user: User
): Promise<void> => {
  return callBackend(
    backendClient.delete(
      `shop-configuration/delete?tenantId=${storeId}&${addAuthParameters(user)}`
    )
  );
};

const createAwsLambdaApiClient = (headers?: AxiosRequestConfig["headers"]) => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_AWS_API_BASE_URL,
    headers: {
      ...axios.defaults.headers,
      ...(headers ? headers : {}),
    },
  });

  client.interceptors.request.use(async (request) => {
    const cognitoUserSession = await getCognitoUserSession();
    const token = cognitoUserSession
      ? cognitoUserSession.getAccessToken().getJwtToken()
      : "";
    request.headers.Authorization = `${token}`;
    return request;
  });

  return client;
};

export const abTestClient = createAwsLambdaApiClient({
  "Content-Type": "application/json",
});
export const llmClient = createAwsLambdaApiClient({
  "Content-Type": "text/plain",
});
