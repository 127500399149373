import * as React from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { StaticRoutePath } from "../AppRoutes";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";
import { Loader } from "../common/Loader/Loader";
import { Title } from "../common/Title/Title";
import { AuthWrapper } from "../common/AuthWrapper/AuthWrapper";

export function Lobby() {
  const navigate = useNavigate();
  const { loading, user } = useUserContext();
  const shouldShowConfirmEmailView = user && !user.verified;

  if (loading) {
    return <Loader />;
  }
  if (shouldShowConfirmEmailView) {
    return <Navigate to={StaticRoutePath.ConfirmEmail} />;
  }
  if (user) {
    return <Navigate to={StaticRoutePath.Stores} />;
  }

  return (
    <AuthWrapper>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        justify="center"
        spacing={1}
      >
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginBottom: "20px" }}
        >
          <Grid item>
            <Title>Welcome!</Title>
            <Typography variant="subtitle1">
              Please register or log in.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => navigate(StaticRoutePath.Register)}
          >
            Register
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => navigate(StaticRoutePath.Login)}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
