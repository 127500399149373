import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { SettingsWizardPageProps } from "./SettingsWizardPage";
import HomePage from "./GreetingTab/HomePage";
import Category from "./GreetingTab/Category";
import NewArrival from "./GreetingTab/NewArrival";
import DailyDeal from "./GreetingTab/DailyDeal";
import Faq from "./GreetingTab/Faq";
import FeedBack from "./GreetingTab/Feedback";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { useWidgetContextAndScreenByTabIndex } from "./useWidgetContextAndScreenByTabIndex";
import { UpsyPreview, UpsyPreviewContainer } from "./UpsyPreview";
import { transformCommonProperties } from "../../common/ConfigurationsContext/dashboardToBackendTransformer";
import UpsellCrossSell from "./GreetingTab/UpSell";
import Upsell from "./GreetingTab/UpSell";
import CrossSell from "./GreetingTab/CrossSell";
import { Language } from "../../common/ConfigurationsContext/Configuration";
import { useGreetingLang } from "./GrettingLangContext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    "& .MuiBox-root": {
      padding: "5px",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "20px",
        width: "300px",
      },
      width: "500px",
      paddingLeft: "80px",
    },
  },
  tabsRootStyle: {
    "& span": {
      backgroundColor: "unset",
    },
    "& button": {
      backgroundColor: "#F5F5F5",
      marginBottom: "3px",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "140%",
      color: "#9E9E9E",
      textTransform: "capitalize",
    },
    "& .Mui-selected": {
      backgroundColor: "#ffffff",
      color: "#6793FF",
      border: "1px solid #6793FF",
    },
  },
  tabPannelStyle: {
    padding: "5px",
    backgroundColor: "red",
  },
}));

export default function GreetingTab({ id }: SettingsWizardPageProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { configuration } = useConfiguration(id);
  const { context, screen } = useWidgetContextAndScreenByTabIndex(value);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const { lang } = useGreetingLang();
  return (
    <>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          classes={{ root: classes.tabsRootStyle }}
          aria-label="Vertical tabs example"
        >
          <Tab label="Home Page" {...a11yProps(0)} />
          <Tab label="Category" {...a11yProps(1)} />
          <Tab label="New Arrivals" {...a11yProps(2)} />
          <Tab label="Daily Deals" {...a11yProps(3)} />
          <Tab label="Up Sell" {...a11yProps(4)} />
          <Tab label="Cross Sell" {...a11yProps(5)} />
          <Tab label="FAQs" {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <HomePage id={id} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Category id={id} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <NewArrival id={id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DailyDeal id={id} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Upsell id={id} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <CrossSell id={id} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Faq id={id} />
        </TabPanel>
      </div>
      {configuration && (
        <UpsyPreviewContainer minHeight={348}>
          <UpsyPreview
            widgetContext={context}
            widgetScreen={screen}
            config={{
              ...transformCommonProperties(configuration).frontendConfiguration,
              language: lang as Language,
            }}
          />
        </UpsyPreviewContainer>
      )}
    </>
  );
}
