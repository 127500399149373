import {
  beginningOfMonth,
  beginningOfYear,
  daysBefore,
  monthsBefore,
  previousMonday,
  weekBefore,
  yearsBefore,
} from "./dateUtils";

const today = new Date();
const lastFullDay = daysBefore(today, 1);

export const dateFilterShortcuts = [
  {
    label: "Week to Date",
    setFilter: () => {
      const startOfWeek = previousMonday(lastFullDay);
      const lastWeeksMonday = weekBefore(startOfWeek);
      const lastWeekToday = weekBefore(lastFullDay);
      return [startOfWeek, lastFullDay, lastWeeksMonday, lastWeekToday];
    },
  },
  {
    label: "Month to Date",
    setFilter: () => {
      const startOfMonth = beginningOfMonth(lastFullDay);
      const startOfPreviousMonth = monthsBefore(startOfMonth, 1);
      const monthAgoToday = monthsBefore(lastFullDay, 1);
      return [startOfMonth, lastFullDay, startOfPreviousMonth, monthAgoToday];
    },
  },
  {
    label: "Year to Date",
    setFilter: () => {
      const startOfYear = beginningOfYear(lastFullDay);
      const startOfPreviousYear = yearsBefore(startOfYear, 1);
      const lastYearToday = yearsBefore(lastFullDay, 1);
      return [startOfYear, lastFullDay, startOfPreviousYear, lastYearToday];
    },
  },
  {
    label: "Previous Week",
    setFilter: () => {
      const startOfPreviousWeek = weekBefore(previousMonday(today));
      const endOfPreviousWeek = daysBefore(previousMonday(today), 1);
      const startOfWeekBeforePreviousWeek = weekBefore(startOfPreviousWeek);
      const endOfWeekBeforePreviousWeek = weekBefore(endOfPreviousWeek);
      return [
        startOfPreviousWeek,
        endOfPreviousWeek,
        startOfWeekBeforePreviousWeek,
        endOfWeekBeforePreviousWeek,
      ];
    },
  },
  {
    label: "Previous Month",
    setFilter: () => {
      const startOfPreviousMonth = monthsBefore(beginningOfMonth(today), 1);
      const endOfPreviousMonth = daysBefore(beginningOfMonth(today), 1);
      const startOfMonthBeforePreviousMonth = monthsBefore(
        startOfPreviousMonth,
        1
      );
      const endOfMonthBeforePreviousMonth = daysBefore(startOfPreviousMonth, 1);
      return [
        startOfPreviousMonth,
        endOfPreviousMonth,
        startOfMonthBeforePreviousMonth,
        endOfMonthBeforePreviousMonth,
      ];
    },
  },
  {
    label: "Show Last 7 Days",
    setFilter: () => {
      const sevenDaysAgo = daysBefore(lastFullDay, 6);
      const weekBeforeSevenDaysAgo = daysBefore(sevenDaysAgo, 7);
      const lastWeekToday = daysBefore(sevenDaysAgo, 1);
      return [sevenDaysAgo, lastFullDay, weekBeforeSevenDaysAgo, lastWeekToday];
    },
  },
  {
    label: "Show Last 14 Days",
    setFilter: () => {
      const fourteenDaysAgo = daysBefore(lastFullDay, 13);
      const startOfPreviousFourteenDays = daysBefore(fourteenDaysAgo, 14);
      const endOfPreviousFourteenDays = daysBefore(fourteenDaysAgo, 1);
      return [
        fourteenDaysAgo,
        lastFullDay,
        startOfPreviousFourteenDays,
        endOfPreviousFourteenDays,
      ];
    },
  },
  {
    label: "Show Last 30 Days",
    setFilter: () => {
      const thirtyDaysAgo = daysBefore(lastFullDay, 29);
      const startOfPreviousThirtyDays = daysBefore(thirtyDaysAgo, 30);
      const endOfPreviousThirtyDays = daysBefore(thirtyDaysAgo, 1);
      return [
        thirtyDaysAgo,
        lastFullDay,
        startOfPreviousThirtyDays,
        endOfPreviousThirtyDays,
      ];
    },
  },
  {
    label: "Show Last Year",
    setFilter: () => {
      const startOfPreviousYear = yearsBefore(beginningOfYear(today), 1);
      const endOfPreviousYear = daysBefore(beginningOfYear(today), 1);
      const startOfYearBeforePreviousYear = yearsBefore(startOfPreviousYear, 1);
      const endOfYearBeforePreviousYear = daysBefore(startOfPreviousYear, 1);
      return [
        startOfPreviousYear,
        endOfPreviousYear,
        startOfYearBeforePreviousYear,
        endOfYearBeforePreviousYear,
      ];
    },
  },
];
