import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MobileStepper,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { IConfiguration } from "../../common/ConfigurationsContext/Configuration";
import {
  useCommonBackendConfigurationValidator,
  useConfiguration,
  useConfigurations,
  useConfigurationTester,
} from "../../common/ConfigurationsContext/ConfigurationsContext";
import { CommonBackendConfiguration } from "./CommonBackendConfiguration";
import { ConfigurationSummary } from "./ConfigurationSummary";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import {
  BasicIntegrationWizardPages,
  IntegrationWizardCommonPageType,
  IntegrationWizardPageProps,
  IntegrationWizardPagesByType,
  IntegrationWizardPageType,
} from "./IntegrationWizardPage";
import { LoadingButton } from "../../common/LoadingButton/LoadingButton";
import { ConfigurationSuccess } from "./ConfigurationSuccess";
import { CustomStepper } from "../../common/CustomeStepper";
import { TopContainer } from "../../common/Page/TopContainer";
import { MainContainer } from "../../common/Page/MainContainer";
import { useNavigate } from "react-router-dom";
import { DynamicRoutePath } from "../../AppRoutes";

function getStepperLabelsFromPageTypes(
  configurationsPages: IntegrationWizardPageType[]
) {
  return configurationsPages
    .map((pageType) => {
      switch (pageType) {
        case IntegrationWizardCommonPageType.CommonBackend:
          return "Integration";
        case IntegrationWizardCommonPageType.StoreSpecificBackend:
          return "";
        case IntegrationWizardCommonPageType.Success:
          return "";
        default:
          return "";
      }
    })
    .filter((item) => item);
}

export function IntegrationWizard({ id }: { id: string }) {
  const { configuration } = useConfiguration(id);
  const platform = configuration?.platform;
  const IntegrationWizardPages = platform
    ? IntegrationWizardPagesByType[platform]
    : BasicIntegrationWizardPages;
  const [pageType, setPageType] = useState<IntegrationWizardPageType>(
    IntegrationWizardPages[0]
  );
  const [steps, setSteps] = useState<string[]>([]);

  useEffect(() => {
    setSteps((prev) => [
      ...getStepperLabelsFromPageTypes(IntegrationWizardPages),
    ]);
  }, []);

  const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <>
      <TopContainer style={{ paddingBottom: "16px" }}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <CustomStepper
              steps={steps}
              activeStep={0}
              hideStepperProgressBar
              stepLabelContainerAlignment="left"
            />
          </Grid>
        </Grid>
      </TopContainer>
      <MainContainer>
        <IntegrationWizardPage
          id={id}
          pageType={pageType}
          setPageType={setPageType}
        />
        <IntegrationWizardNavigation
          id={id}
          pageType={pageType}
          setPageType={setPageType}
          IntegrationWizardPages={IntegrationWizardPages}
          setActiveStep={setActiveStep}
        />
      </MainContainer>
    </>
  );
}

function IntegrationWizardPage({
  id,
  pageType,
  setPageType,
}: IntegrationWizardPageProps & {
  pageType: IntegrationWizardPageType;
  setPageType: (pageType: IntegrationWizardPageType) => void;
}) {
  switch (pageType) {
    case IntegrationWizardCommonPageType.CommonBackend:
      return (
        <CommonBackendConfiguration
          id={id}
          isWooCommerceSpecificConfiguration={false}
          setPageType={setPageType}
        />
      );
    case IntegrationWizardCommonPageType.StoreSpecificBackend:
      return (
        <CommonBackendConfiguration
          id={id}
          isWooCommerceSpecificConfiguration={true}
          setPageType={setPageType}
        />
      );
    case IntegrationWizardCommonPageType.Summary:
      return <ConfigurationSummary id={id} />;
    case IntegrationWizardCommonPageType.Success:
      return <ConfigurationSuccess id={id} />;
    default:
      return null;
  }
}

const isIgnoreErrorBoxEnabled: boolean =
  process.env.REACT_APP_ENABLE_IGNORE_TEST_ERROR_BOX === "true";

function IntegrationWizardNavigation({
  id,
  pageType,
  setPageType,
  setActiveStep,
  IntegrationWizardPages,
}: IntegrationWizardPageProps & {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  pageType: IntegrationWizardPageType;
  setPageType: (pageType: IntegrationWizardPageType) => void;
  IntegrationWizardPages: IntegrationWizardPageType[];
}) {
  const { configuration, configurationError } = useConfiguration(id);
  const { testingConfiguration, testingError, testBackendShopConfiguration } =
    useConfigurationTester(configuration);

  const {
    commonBackendConfigurationValidationError,
    validateCommonBackendConfiguration,
  } = useCommonBackendConfigurationValidator(configuration);

  const classes = useNavigationButtonsClasses();
  const [ignoreError, setIgnoreError] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const minIndex = 0;
  const isCommonBackendConfigPage =
    pageType === IntegrationWizardCommonPageType.CommonBackend;
  const isShopConfigTestPage =
    pageType === IntegrationWizardCommonPageType.StoreSpecificBackend;
  const isSummaryPage = pageType === IntegrationWizardCommonPageType.Summary;
  const isSuccessPage = pageType === IntegrationWizardCommonPageType.Success;

  useEffect(() => {
    setActiveStep(pageIndex);
  }, [pageIndex, setActiveStep]);

  const moveToPage = (pageIndex: number) => {
    setPageIndex(pageIndex);
    const pageType = IntegrationWizardPages[pageIndex];
    if (pageType === IntegrationWizardCommonPageType.StoreSpecificBackend) {
      console.log("Landed on store specific config page");
      setPageType(pageType);
    } else {
      setPageType(pageType);
    }
  };

  function previous() {
    if (minIndex < pageIndex) {
      moveToPage(pageIndex - 1);
    }
  }

  return (
    <Grid container item xs={12} style={{ marginTop: "80px" }}>
      {!isSuccessPage && (
        <Grid item xs={12}>
          <MobileStepper
            classes={{ root: classes.mobileStepper }}
            className={classes.buttonsContainer}
            backButton={
              <Button
                style={{ borderRadius: "8px" }}
                variant="outlined"
                color="primary"
                disabled={
                  minIndex === pageIndex ||
                  isSuccessPage ||
                  testingConfiguration
                }
                onClick={previous}
              >
                Previous
              </Button>
            }
            nextButton={
              <ProceedButton
                id={id}
                pageType={pageType}
                pageIndex={pageIndex}
                moveToPage={moveToPage}
                testingConfiguration={testingConfiguration}
                testBackendShopConfiguration={testBackendShopConfiguration}
                validateCommonBackendConfiguration={
                  validateCommonBackendConfiguration
                }
                ignoreError={ignoreError}
                IntegrationWizardPages={IntegrationWizardPages}
              />
            }
            steps={IntegrationWizardPages.length - 1}
            activeStep={pageIndex}
            position="static"
          />
        </Grid>
      )}
      {isShopConfigTestPage && testingError && (
        <Grid item xs={12}>
          <Alert className={classes.alert} severity="error">
            {testingError}
          </Alert>
          {isIgnoreErrorBoxEnabled && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={ignoreError}
                  onChange={(event) => setIgnoreError(event.target.checked)}
                />
              }
              label="Ignore Error"
            />
          )}
        </Grid>
      )}
      {isCommonBackendConfigPage && commonBackendConfigurationValidationError && (
        <Grid item xs={12}>
          <Alert className={classes.alert} severity="error">
            {commonBackendConfigurationValidationError}
          </Alert>
        </Grid>
      )}
      {isSummaryPage && configurationError && (
        <Grid item xs={12}>
          <Alert className={classes.alert} severity="error">
            {configurationError}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}

function ProceedButton({
  id,
  pageType,
  pageIndex,
  moveToPage,
  testingConfiguration,
  ignoreError,
  testBackendShopConfiguration,
  validateCommonBackendConfiguration,
  IntegrationWizardPages,
}: IntegrationWizardPageProps & {
  pageType: IntegrationWizardPageType;
  pageIndex: number;
  moveToPage: (index: number) => void;
  testingConfiguration: boolean;
  ignoreError: boolean;
  testBackendShopConfiguration: (successCallback: () => void) => void;
  validateCommonBackendConfiguration: (successCallback: () => void) => void;
  IntegrationWizardPages: IntegrationWizardPageType[];
}) {
  const maxIndex = IntegrationWizardPages.length - 1;
  const isCommonBackendPage =
    pageType === IntegrationWizardCommonPageType.CommonBackend;
  const isShopConfigTestPage =
    pageType === IntegrationWizardCommonPageType.StoreSpecificBackend;
  const isSummaryPage = pageType === IntegrationWizardCommonPageType.Summary;
  const isSuccessPage = pageType === IntegrationWizardCommonPageType.Success;
  const { configuration, saveConfiguration, clearTempConfiguration } =
    useConfiguration(id);
  const { loading } = useConfigurations();
  const navigate = useNavigate();
  function next() {
    if (pageIndex < maxIndex) {
      moveToPage(pageIndex + 1);
    }
  }

  function handleCancelButton() {
    navigate("/stores");
  }

  const classes = useProceedButtonClasses();

  return (
    <Grid container item justify="flex-end" style={{ gap: "20px" }}>
      <Button style={{ marginRight: "15px" }} onClick={handleCancelButton}>
        {"Cancel"}
      </Button>
      {isCommonBackendPage ? (
        <Button
          classes={{ root: classes.root }}
          variant="contained"
          color="primary"
          onClick={() => validateCommonBackendConfiguration(next)}
        >
          {"Save & Next"}
        </Button>
      ) : isShopConfigTestPage ? (
        <LoadingButton
          classes={{ root: classes.root }}
          loading={testingConfiguration}
          loadingText="Verifying..."
          buttonText="Save & Next"
          onClick={
            ignoreError ? next : () => testBackendShopConfiguration(next)
          }
        />
      ) : isSummaryPage ? (
        <LoadingButton
          classes={{ root: classes.root }}
          loading={loading}
          loadingText="Saving..."
          buttonText="Save & Publish"
          onClick={async () => {
            try {
              await saveConfiguration(configuration as IConfiguration);
              clearTempConfiguration();
              next();
              // navigate(DynamicRoutePath.StoreSettings(id));
            } catch (err) {}
          }}
        />
      ) : isSuccessPage ? null : (
        <Button
          classes={{ root: classes.root }}
          variant="contained"
          color="primary"
          onClick={next}
        >
          {"Save & Next"}
        </Button>
      )}
    </Grid>
  );
}

const useNavigationButtonsClasses = createUseClasses((theme) => ({
  alert: {
    whiteSpace: "pre-wrap",
  },
  buttonsContainer: {
    padding: `${theme.spacing(2)}px 0`,
    alignItems: "flex-end",
  },
  mobileStepper: {
    "& .MuiMobileStepper-dots": {
      display: "none",
      width: 0,
      opacity: 0,
    },
  },
}));

const useProceedButtonClasses = createUseClasses((theme) => ({
  root: {
    borderRadius: "8px",
    border: "1px solid #6793FF",
  },
}));
