import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useConfiguration } from "../../../common/ConfigurationsContext/ConfigurationsContext";
import CustomTextField from "../../../common/CustomUiComponent/TextField";
import { SettingsWizardPageProps } from "../SettingsWizardPage";
import ButtonGroup from "./ButtonGroup";
import useStyles from "./style";
import useHelper from "./useHelper";
import { useGreetingLang } from "../GrettingLangContext";

export default function HomePage({ id }: SettingsWizardPageProps) {
  const classes = useStyles();
  const { configuration, setCommonFrontendConfiguration } =
    useConfiguration(id);
  const { countCharacter, errorMessageLabel } = useHelper();
  const { lang } = useGreetingLang();

  const textInBubble =
    lang === "default"
      ? configuration?.greetings?.homePage?.textInBubble || ""
      : configuration?.greetings?.homePage?.locale?.[lang]?.textInBubble || "";
  const [textInBubbleForm, setTextInBubbleForm] = useState(textInBubble);
  useEffect(() => {
    setTextInBubbleForm(textInBubble);
  }, [lang, textInBubble]);
  const defaultHandler = () => {
    const defaultTextInBubble =
      configuration?.greetings?.homePage?.textInBubble || "";
    setTextInBubbleForm(defaultTextInBubble);
  };
  const submithandler = () => {
    if (countCharacter(textInBubbleForm, 30)) return false;
    let updatedData = {
      ...(configuration?.greetings?.homePage || {}),
      textInBubble: textInBubbleForm || "",
    };

    if (lang !== "default") {
      updatedData = {
        ...(configuration?.greetings?.homePage || {}),
        textInBubble: configuration?.greetings?.homePage?.textInBubble || "",
        locale: {
          ...(configuration?.greetings?.homePage?.locale || {}),
          [lang]: {
            textInBubble: textInBubbleForm || "",
          },
        },
      };
    }
    setCommonFrontendConfiguration({
      greetings: {
        ...configuration?.greetings,
        homePage: updatedData,
      },
    });
  };

  return (
    <>
      <Typography className={classes.title}>Text in the bubble</Typography>
      <CustomTextField
        onChange={(e) => setTextInBubbleForm(e.target.value)}
        error={countCharacter(textInBubbleForm, 30)}
        placeholder={"Hi! What is on your mind?"}
        value={textInBubbleForm}
        helperText={
          countCharacter(textInBubbleForm, 30) && errorMessageLabel(30)
        }
      />
      <ButtonGroup
        submithandler={submithandler}
        defaultHandler={defaultHandler}
      />
    </>
  );
}
