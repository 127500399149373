import {
  IBackendConfiguration,
  IConfigurationCommonFrontend,
  OperationModeFE,
} from "../ConfigurationsContext/Configuration";
import { IFlowRule } from "../../Stores/StoreSettings/flowRules";
import { UserRoleType } from "../UserContext/User";

export interface Store {
  name?: string;
  tenantId: string;
  role?: string;
}

export interface GetUserResponse {
  fullName: string;
  user: string;
  secretKey: string;
  stores?: Array<Store>;
  id: string;
  role?: UserRoleType;
}

export interface CreateUserRequest {
  fullName: string;
  email: string;
  password: string;
}

export interface CreateUserApiError {
  user?: string;
  password?: string;
}

export interface IConfigurationCommonFrontendDynamoDB
  extends Omit<Omit<IConfigurationCommonFrontend, "flows">, "operationMode"> {
  flows: IFlowRule[];
  testMode: OperationModeFE;
  tenantId: string;
}

export interface FetchShopConfigurationsResponse {
  backendConfiguration: IBackendConfiguration;
  frontendConfiguration: IConfigurationCommonFrontendDynamoDB;
}

export interface UpsertShopConfigurationRequest {
  backendConfiguration: IBackendConfiguration;
  frontendConfiguration: IConfigurationCommonFrontendDynamoDB;
}

export enum UpsertShopConfigurationOperationType {
  New = "new",
  Update = "update",
}
