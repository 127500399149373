import { FormControl, Grid, MenuItem, Typography } from "@material-ui/core";
import { SettingsWizardPageProps } from "./SettingsWizardPage";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { CategoryForm } from "./CategoryForm";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import Categories from "./Categories";
import { CATEGORY } from "./FAQs";
import { useState, createContext, useContext } from "react";
import { ALLOWED_LANGUAGE, allowedLanguage } from "../../Configs/language";
import CustomSelectField from "../../common/CustomUiComponent/Select";

type ICategoryLanguageContext = {
  lang: ALLOWED_LANGUAGE | "default";
  updateLang: (lang: ICategoryLanguageContext["lang"]) => void;
};
const CategoryLanguageContext = createContext<ICategoryLanguageContext | null>(
  null
);

const CategoryLanguageProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [selectedLang, setSelectedLang] =
    useState<ICategoryLanguageContext["lang"]>("default");
  return (
    <CategoryLanguageContext.Provider
      value={{
        lang: selectedLang,
        updateLang: (lang: ICategoryLanguageContext["lang"]) => {
          setSelectedLang(lang);
        },
      }}
    >
      {children}
    </CategoryLanguageContext.Provider>
  );
};

const useCategoryLang = () => {
  const context = useContext(CategoryLanguageContext);
  if (!context)
    throw new Error(
      "useCategoryLang  must be used within the CategoryLanguageProvider."
    );
  return context;
};

export const useCategory = ({ category }: { category: CATEGORY }) => {
  const [categoryState, setCategoryState] = useState({ ...category });
  const { lang: selectedLang, updateLang: setSelectedLang } = useCategoryLang();

  const handleLangChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setSelectedLang(e.target.value as ALLOWED_LANGUAGE);
  };

  const handleCategoryChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const { name, value } = e.target;

    if (!name) return;
    if (selectedLang === "default") {
      setCategoryState((prev) => ({ ...prev, [name]: value }));
      return;
    }
    setCategoryState((prev) => {
      return {
        ...prev,
        locale: {
          ...prev.locale,
          [selectedLang]: {
            ...prev.locale?.[selectedLang],
            [name]: value,
          },
        },
      };
    });
  };
  const resetLang = () => {
    setSelectedLang("default");
  };
  const resetCategory = () => {
    setCategoryState({ ...category });
  };
  const categoryName =
    selectedLang !== "default"
      ? categoryState?.locale?.[selectedLang]?.categoryName ?? ""
      : categoryState.categoryName ?? "";

  const categoryURL =
    selectedLang !== "default"
      ? categoryState?.locale?.[selectedLang]?.categoryURL ?? ""
      : categoryState.categoryURL ?? "";
  return {
    selectedLang,
    handleLangChange,
    resetLang,
    handleCategoryChange,
    resetCategory,
    categoryName,
    categoryURL,
    categoryState,
  };
};
// Language select component for category
const LanguageSelector = () => {
  const { lang: selectedLang, updateLang } = useCategoryLang();
  return (
    <FormControl>
      <CustomSelectField
        value={selectedLang}
        labelFor="language"
        labelText="Language"
        placeholder="Language"
        fullWidth
        variant="outlined"
        name="language"
        onChange={(e) => {
          updateLang(e.target.value as ALLOWED_LANGUAGE);
        }}
      >
        <MenuItem value={"default"}>Default language</MenuItem>
        {Object.entries(allowedLanguage).map((entry) => (
          <MenuItem key={entry[0]} value={entry[1]}>
            {entry[0]}
          </MenuItem>
        ))}
      </CustomSelectField>
    </FormControl>
  );
};

export function CommonFrontendCategories({ id }: SettingsWizardPageProps) {
  const classes = useClasses();
  const { configuration } = useConfiguration(id);
  return (
    <CategoryLanguageProvider>
      <Grid container item xs={12} md={9} lg={7} spacing={2}>
        <Grid item xs={12}>
          <LanguageSelector />
        </Grid>
      </Grid>
      <Grid
        container
        item
        classes={{ root: classes.gridContainerRootStyle }}
        xs={12}
        md={9}
        lg={7}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={classes.avatarContainer}
        >
          <Typography className={classes.typographyForAvatarTitle}>
            Add New Category
          </Typography>
        </Grid>
        <CategoryForm id={id} />
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        md={9}
        sm={12}
        style={{ marginTop: "80px", minWidth: "100%" }}
      >
        {configuration!.customerCategories!.length > 0 && (
          <Categories id={id} />
        )}
      </Grid>
    </CategoryLanguageProvider>
  );
}

const useClasses = createUseClasses((theme) => ({
  avatarContainer: {
    marginLeft: "5px",
    marginBottom: "10px",
  },
  typographyForAvatarTitle: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "0.04em",
    color: "#202F4E",
  },
  gridItemRoot: {
    "&.MuiGrid-item": {
      padding: "4px",
    },
  },
  gridContainerRootStyle: {
    backgroundColor: "#E1EAFF",
    borderRadius: "15px",
    padding: "10px",
  },
}));
