import { Grid, styled } from "@material-ui/core";
import * as React from "react";
import { ReactNode } from "react";
import { CenteringContainer } from "../CenteringContainer/CenteringContainer";
import { LogoUpsyShoppingHelper } from "../SVG/Component/LogoUpsyShoppingHelper";
import { createUseClasses } from "../Theme/createUseClasses";
import upsyBackgroundImg from "../SVG/upsy-background.svg";

type CssUnitType = `${number}${
  | "px"
  | "em"
  | "rem"
  | "%"
  | "vh"
  | "vw"
  | "vmin"
  | "vmax"
  | "ch"
  | "ex"
  | "in"
  | "pt"
  | "cm"}`;

export function AuthWrapper({
  children,
  innerWrapperHeight = "78%",
}: {
  children: NonNullable<ReactNode>;
  innerWrapperHeight?: CssUnitType;
}) {
  const classes = useClasses();

  return (
    <GradientBackgroundContainer container>
      <Grid item sm={5} xs={12}>
        <CenteringContainer>
          <LogoUpsyShoppingHelper variant="dark" width="50%" />
        </CenteringContainer>
      </Grid>
      <Grid
        item
        sm={7}
        container
        xs={12}
        alignItems="center"
        justify="center"
        className={classes.fullHeightContainer}
      >
        <Grid
          item
          sm={8}
          xs={12}
          style={{ height: innerWrapperHeight }}
          className={classes.wrapperContainerGrid}
        >
          {children}
        </Grid>
      </Grid>
    </GradientBackgroundContainer>
  );
}

const GradientBackgroundContainer = styled(Grid)({
  backgroundImage: `url("${upsyBackgroundImg}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundOrigin: "border-box",
  height: "100%",
  minHeight: "100vh",
});

const useClasses = createUseClasses((theme) => ({
  fullHeightContainer: {
    height: "100vh",
    [theme.breakpoints.only("xs")]: {
      minHeight: "62vh",
    },
  },
  wrapperContainerGrid: {
    border: "3px solid rgba(103, 147, 255, 0.3)",
    borderRadius: "40px",
    height: "78%",
    maxHeight: "690px",
    maxWidth: "690px",
    width: "100%",
    backdropFilter: "blur(25px)",
    padding: "0px 45px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
}));
