import React from "react";

import Button, { ButtonProps } from "@material-ui/core/Button";
import { createUseClasses } from "../Theme/createUseClasses";

export const CustomButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    component?: React.ElementType;
    htmlFor?: string;
  }
>(({ children, className, ...props }, ref) => {
  const classes = useClasses();
  return (
    <Button className={`${classes.button} ${className}`} {...props} ref={ref}>
      {children}
    </Button>
  );
});

const useClasses = createUseClasses(() => ({
  button: {
    borderRadius: "8px",
    "&.MuiButton-outlined": {
      borderColor: "#6793FF",
      "& .MuiButton-label": {
        color: "#364F83",
        "& .MuiButton-startIcon": {
          color: "#6793FF",
        },
      },
    },
    "&.MuiButton-contained": {
      background: "#364F83",
      boxShadow: "none",
      "& .MuiButton-label": {
        color: "#FFFFFF",
      },
    },
  },
}));
