import * as React from "react";
import { Page } from "../common/Page/Page";
import { ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DynamicRoutePath, IStoreRouteParams } from "../AppRoutes";
import { useConfiguration } from "../common/ConfigurationsContext/ConfigurationsContext";
import { Tab, Tabs } from "@material-ui/core";
import { newConfigurationId } from "../common/ConfigurationsContext/Configuration";
import { createUseClasses } from "../common/Theme/createUseClasses";
import { Title } from "../common/Title/Title";

export function Store({
  children,
  storeId,
}: {
  children?: ReactNode;
  storeId?: string;
}) {
  const { id = "" } = useParams<IStoreRouteParams>();
  const { configuration } = useConfiguration(storeId || id);

  return (
    <Page
      title={
        <Title>
          {id === newConfigurationId
            ? "New Configuration"
            : configuration
            ? configuration.storeNameFormatted
            : "Error: Could not find store"}
        </Title>
      }
    >
      {children}
    </Page>
  );
}

const useClasses = createUseClasses((theme) => ({
  tabs: {
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));
