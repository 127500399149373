import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { User } from "./User";
import { createUser, getUser as getUserFromDB } from "../Client/backendClient";

const userPool = new CognitoUserPool({
  UserPoolId:
    process.env.REACT_APP_CONGNITO_USER_POOL_ID || "mock_user_pool_id",
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "mock_client_id",
});

async function addRoleToUser(
  user: NonNullable<User>
): Promise<NonNullable<User>> {
  const dbUser = await getUserFromDB(user);
  return {
    ...user,
    role: dbUser ? dbUser.role : null,
  };
}

function getUser(email: string) {
  return new CognitoUser({
    Username: email,
    Pool: userPool,
  });
}

export async function register(
  fullName: string,
  email: string,
  password: string
): Promise<User> {
  return await createUser({ fullName, email, password });
}

export function confirmEmail(
  email: string,
  confirmationCode: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    getUser(email).confirmRegistration(
      confirmationCode,
      true,
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
          // TODO: Create client secret (backend api call) and store it to the cognito user attributes
        }
      }
    );
  });
}

export function resendConfirmationCode(email: string): Promise<any> {
  return new Promise((resolve, reject) => {
    getUser(email).resendConfirmationCode((error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });
}

export function logIn(
  email: string,
  password: string
): Promise<{ error: any; user: User }> {
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  return new Promise((resolve, reject) => {
    getUser(email).authenticateUser(authDetails, {
      onSuccess: async (session, userConfirmationNecessary) => {
        const user = userPool.getCurrentUser();
        if (user && (await hasValidSession(user))) {
          resolve({
            error: null,
            user: await addRoleToUser({
              name: await getAttribute(user, "name"),
              email,
              verified: !userConfirmationNecessary,
              secretKey: await getAttribute(user, "custom:userSecret"),
            }),
          });
        } else {
          reject("logIn: Unexpected error");
        }
      },
      onFailure: async (error) => {
        if (error.code === "PasswordResetRequiredException") {
          await getUser(email).forgotPassword({
            onSuccess: () => resolve({ error, user: null }),
            onFailure: reject,
          });
        } else {
          reject(error);
        }
      },
    });
  });
}

export async function getCurrentUser(): Promise<User> {
  try {
    const currentUser = userPool.getCurrentUser();
    if (currentUser && (await hasValidSession(currentUser))) {
      return await addRoleToUser({
        name: await getAttribute(currentUser, "name"),
        email: await getUserEmail(currentUser),
        verified: true,
        secretKey: await getAttribute(currentUser, "custom:userSecret"),
      });
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function hasValidSession(user: CognitoUser): Promise<boolean> {
  return new Promise((resolve, reject) => {
    user.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) {
        reject(error);
      } else {
        resolve(session.isValid());
      }
    });
  });
}

export function getPasswordRecoveryCode(email: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    getUser(email).forgotPassword({
      onSuccess: () => resolve(true),
      onFailure: reject,
    });
  });
}

export function recoverPassword(
  email: string,
  code: string,
  newPassword: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    getUser(email).confirmPassword(code, newPassword, {
      onSuccess: () => resolve(true),
      onFailure: reject,
    });
  });
}

function getUserEmail(user: CognitoUser): Promise<string> {
  return new Promise((resolve, reject) => {
    user.getUserAttributes((error, attributes) => {
      if (error) {
        reject(error);
      } else {
        const emailAttribute = (attributes as CognitoUserAttribute[]).find(
          (attribute) => attribute.getName() === "email"
        );
        if (!emailAttribute) {
          reject("getUserEmail: Unexpected error");
        } else {
          resolve(emailAttribute.getValue());
        }
      }
    });
  });
}

export function logOut() {
  userPool.getCurrentUser()?.signOut();
}

function getAttribute(
  user: CognitoUser,
  attributeName: string
): Promise<string | undefined> {
  return new Promise((resolve) => {
    user.getUserAttributes((error, userAttributes) => {
      resolve(
        userAttributes?.length
          ? userAttributes
              .find(
                (userAttribute) => userAttribute.getName() === attributeName
              )
              ?.getValue()
          : undefined
      );
    });
  });
}

export async function getCognitoUserSession(): Promise<CognitoUserSession | null> {
  const user = userPool.getCurrentUser();
  if (!user) return null;
  return new Promise((resolve) => {
    user.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) resolve(null);
      resolve(session);
    });
  });
}
