/* eslint-disable no-unused-vars */
import { embedDashboard } from "amazon-quicksight-embedding-sdk";

/*
  Reference for EmbeddingOptions:

  type EmbeddingOptions = {
    url: string,
    container: HTMLElement | string,
    errorCallback: ?Function,
    loadCallback: ?Function,
    parametersChangeCallback: ?Function,
    selectedSheetChangeCallback: ?Function,
    parameters: ?Object,
    printEnabled: ?boolean,
    sheetTabsDisabled: ?boolean,
    sheetId: ?string,
    defaultEmbeddingVisualType: ?string,
    iframeResizeOnSheetChange: ?boolean,
    width: ?string,
    height: ?string,
    loadingHeight: ?string,
    scrolling: ?string,
    className: ?string,
    locale: ?string,
    footerPaddingEnabled: ?boolean
};
  */
interface DateRange {
  startDate: string | null | Date;
  endDate: string | null | Date;
}

export interface QuicksightEmbedDateParameters {
  dateRange: DateRange | null;
  //comparisonDateRange?: DateRange | null;
}
const formatTenantId = (tenantId: string): string => {
  const noPrefix = tenantId.replace(/^(\s*ups-)/, "");
  return `${noPrefix.charAt(0).toUpperCase()}${noPrefix.slice(1)}`;
};
export interface QuicksightEmbedParameters
  extends QuicksightEmbedDateParameters {
  experimentId?: string;
  customer?: string;
}

export const embedQuicksight = (
  container: HTMLIFrameElement,
  url: string,
  parameters: QuicksightEmbedParameters,
  setLoaded: (loaded: true) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return embedDashboard({
    url,
    container,
    parameters: parseParameters(parameters),
    loadCallback: () => setLoaded(true),
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setQuicksightParameters(
  dashboard: any,
  parameters: QuicksightEmbedParameters
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    dashboard.setParameters(parseParameters(parameters));
  } catch (error) {
    console.error(error);
  }
}

function parseParameters(parameters: QuicksightEmbedParameters) {
  const { dateRange, customer, experimentId } = parameters;

  const params = {
    tenantId: customer,
    datefrom: dateRange?.startDate,
    dateto: dateRange?.endDate,
    experimentId,
    // datefromcomparison: getStartDateFilter(comparisonDateRange.start),
    // datetocomparison: getEndDateFilter(comparisonDateRange.end),
  };
  console.log({ embedParams: params });
  return params;
}
