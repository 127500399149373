import {
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createUseClasses } from "../Theme/createUseClasses";

export type ConfigurationStepperProps = {
  steps: string[];
  activeStep: number;
  breakpointDirection?: "up" | "down";
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl";
  hideStepperProgressBar?: boolean;
  stepLabelContainerAlignment?: string;
};

const colorActive = "#A4BEFF";
const mutedColor = "#CACDD5";
const whiteColor = "#ffffff";
const stepperTextColor = "#202F4E";

const useConfigurationStepperClasses = createUseClasses(() => ({
  root: {
    backgroundColor: whiteColor,
    "&.MuiStepper-horizontal.MuiStepper-alternativeLabel": {
      paddingBlock: 0,
      paddingInline: 0,
    },
    "& .MuiStepLabel-iconContainer, & .MuiStepConnector-root": {
      display: (props: { hideStepperProgressBar: boolean }) =>
        props.hideStepperProgressBar && "none",
    },
    "& .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel": {
      padding: 0,
      minWidth: "80px",
    },
    "&.MuiStepper-alternativeLabel": {
      alignItems: "stretch",
    },
    "& .MuiStepConnector-vertical": {
      padding: 0,
      marginLeft: 18,
    },
    "& .MuiStepConnector-root.MuiStepConnector-completed, & .MuiStepConnector-root.MuiStepConnector-active":
      {
        "& .MuiStepConnector-line": {
          borderWidth: 2.6,
          borderColor: colorActive,
        },
      },
    "& .MuiStepConnector-line": {
      borderColor: mutedColor,
      borderWidth: "2px",
    },

    "& .MuiStepLabel-root.Mui-disabled": {
      "& .MuiSvgIcon-root.MuiStepIcon-root": {
        border: `2px solid ${mutedColor}`,
        fill: whiteColor,
        borderRadius: "50%",
        "& .MuiStepIcon-text": {
          fill: mutedColor,
        },
      },
    },

    "&.MuiStepper-root .MuiStepLabel-vertical .MuiStepLabel-iconContainer": {
      marginLeft: "8px",
    },
    "&.MuiStepper-root .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-active":
      {
        color: colorActive,
      },
    "&.MuiStepper-root .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: colorActive,
      },
    "&.MuiStepper-root .MuiStepLabel-root.MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel":
      {
        flexDirection: "column-reverse",
        gap: "36px",
        alignItems: "center",
        height: "100%",
      },
    "& .MuiStepLabel-horizontal .MuiStepLabel-labelContainer": {
      display: "flex",
      justifyContent: (props: { stepLabelContainerAlignment: string }) =>
        props.stepLabelContainerAlignment,
    },
    "& .MuiStepLabel-labelContainer .MuiStepLabel-label.MuiStepLabel-alternativeLabel":
      {
        color: stepperTextColor,
        display: "inline-block",
        fontSize: "16px",
        lineHeight: "22.4px",
        fontWeight: 600,
        opacity: 0.7,
        textAlign: "center",
        position: "relative",
        "&.MuiStepLabel-active": {
          opacity: 1,
          "&::before": {
            position: "absolute",
            content: '""',
            display: "block",
            bottom: "-16px",
            width: "100%",
            height: "5px",
            borderRadius: "4px",
            left: 0,
            background: "#acaef3",
          },
        },
      },
    "& .MuiStepConnector-root.MuiStepConnector-alternativeLabel": {
      top: "unset",
      bottom: "12px",
      left: "calc(-50% + 12px)",
      right: "calc(50% + 12px)",
    },
    "&.MuiStepper-root .MuiStepLabel-root .MuiStepLabel-label.MuiStepLabel-completed":
      {
        opacity: 1,
      },
  },
}));

export function CustomStepper(props: ConfigurationStepperProps) {
  const {
    steps,
    activeStep,
    breakpointDirection = "down",
    breakpoint = "sm",
    hideStepperProgressBar = false,
    stepLabelContainerAlignment = "center",
  } = props;
  const theme = useTheme();
  const matches = useMediaQuery(
    theme.breakpoints[breakpointDirection](breakpoint)
  );
  const classes = useConfigurationStepperClasses({
    ...props,
    hideStepperProgressBar,
    stepLabelContainerAlignment,
  });

  return (
    <>
      {matches ? (
        <Stepper
          classes={{ root: classes.root }}
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Stepper
          classes={{ root: classes.root }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
    </>
  );
}
