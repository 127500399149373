import React from "react";
import { Grid } from "@material-ui/core";
import { createUseClasses } from "../Theme/createUseClasses";

import type { ReactElementPropsType } from "../ElementProps";

export function MainContainer({
  children,
  style,
  ...props
}: ReactElementPropsType<typeof Grid>) {
  const classes = useClasses();
  return (
    <Grid
      className={classes.container}
      style={{
        margin: "36px",
        padding: "32px",
        ...style,
      }}
      {...props}
    >
      {children}
    </Grid>
  );
}

const useClasses = createUseClasses((theme) => ({
  container: {
    backgroundColor: "#ffffff",
    boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
}));
