import * as React from "react";
import { MainContainer } from "../../common/Page/MainContainer";
import { Store } from "../Store";

export function Dashboard() {
  return (
    <Store>
      <MainContainer>
        <p>Welcome to Upsy Dashboard!</p>
        <p>
          Upsy Dashboard is the place where you can access your store’s Upsy
          metrics and manage Upsy settings. For now, the configuration tool to
          manage Upsy settings is only available for WooCommerce stores, and it
          will become available for all users soon.
        </p>
        <p>
          In case you need our help or have any questions, you can always reach
          us at{" "}
          <a href="mailto:support@upsyshopping.com">support@upsyshopping.com</a>
          .
        </p>
      </MainContainer>
    </Store>
  );
}
