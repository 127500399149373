import React from "react";
import { ReactElementPropsType } from "../ElementProps";
import { createUseClasses } from "../Theme/createUseClasses";
import { Grid, Select } from "@material-ui/core";
import DropDownIcon from "./DropDownIcon";

interface useClassesPropsType {
  dropDownIconColor?: string;
  dropDownIconHeight?: number;
  dropDownIconWidth?: number;
  dropDownIconGapFromRight?: number;
}

export default function CustomSelectField(
  props: ReactElementPropsType<typeof Select> & {
    labelText?: string;
    labelFor?: string;
    labelClassName?: string;
  } & useClassesPropsType
) {
  const {
    labelText,
    labelFor,
    labelClassName,
    dropDownIconColor,
    dropDownIconHeight,
    dropDownIconWidth,
    dropDownIconGapFromRight,
    ...rest
  } = props;
  const useClassesProps: useClassesPropsType = {
    dropDownIconColor,
    dropDownIconHeight,
    dropDownIconWidth,
    dropDownIconGapFromRight,
  };

  const classes = useClasses(useClassesProps);

  return (
    <Grid container direction="column" item xs={12}>
      {labelText && (
        <Grid item xs={12}>
          <label htmlFor={labelFor} className={labelClassName || classes.label}>
            {labelText}
          </label>
        </Grid>
      )}
      <Grid item xs={12}>
        <Select
          IconComponent={DropDownIcon.bind(null, {
            height: dropDownIconHeight,
            width: dropDownIconWidth,
          })}
          id={labelFor}
          className={classes.selectRoot}
          {...rest}
        />
      </Grid>
    </Grid>
  );
}

const useClasses = createUseClasses((theme) => ({
  label: (props: { required?: boolean }) => {
    return {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",
      color: "#202F4E",
      display: "block",
      width: "100%",
      margin: "10px 0px",
      "&:after": {
        content: props.required ? "'*'" : "''",
      },
    };
  },
  selectRoot: {
    "&.MuiInputBase-root": {
      "&:is(.Mui-disabled)": {
        backgroundColor: "rgba(0,0,0,0.1)",
        color: "rgba(0,0,0,0.3)",
        "&:not(:hover) fieldset": {
          border: "1px solid rgba(0,0,0,0.2)",
        },
      },
      background: "#ffffff",
      marginBottom: "16px",
      boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.04)",
      borderRadius: "6px",
      "& fieldset": {
        borderColor: "#E1EAFF",
      },
      "& input, & .MuiSelect-root": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#636364",
        paddingBlock: "16px",
        paddingLeft: "16px",
      },
      "&:hover fieldset": {
        borderColor: "#E1EAFF",
        borderWidth: "2px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6793FF",
      },
      "& svg": {
        top: "calc(50% - 4px)",
        right: (props: useClassesPropsType) =>
          props?.dropDownIconGapFromRight == 0 ||
          props?.dropDownIconGapFromRight
            ? props.dropDownIconGapFromRight
            : 24,
        height: (props: useClassesPropsType) =>
          props?.dropDownIconHeight ? props?.dropDownIconHeight : "30px",
        width: (props: useClassesPropsType) =>
          props?.dropDownIconWidth ? props?.dropDownIconWidth : "30px",
        fill: (props: useClassesPropsType) =>
          props?.dropDownIconColor ? props?.dropDownIconColor : "#6793FF",
      },
    },
  },
}));
