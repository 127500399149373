import { Navigate, useParams } from "react-router-dom";
import { IStoreRouteParams, StaticRoutePath } from "../../AppRoutes";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { IntegrationWizard } from "./IntegrationWizard";
import { Store } from "../Store";
import { newConfigurationId } from "../../common/ConfigurationsContext/Configuration";
import { isAdmin, useUserContext } from "../../common/UserContext/UserContext";
import { UserRole } from "../../common/UserContext/User";

export function StoreIntegration() {
  const { id = "" } = useParams<IStoreRouteParams>();
  const { user } = useUserContext();
  const { configuration } = useConfiguration(id);

  if (id == newConfigurationId && !isAdmin(user)) {
    return <Navigate to={StaticRoutePath.Stores} replace={true} />;
  }

  if (!configuration) {
    return <Navigate to={StaticRoutePath.NotFound} replace={true} />;
  }
  return (
    <Store>
      <IntegrationWizard id={id} />
    </Store>
  );
}
