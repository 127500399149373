import { Platform } from "../../common/ConfigurationsContext/Configuration";

export interface IntegrationWizardPageProps {
  id: string;
}

export enum IntegrationWizardCommonPageType {
  CommonBackend = "CommonBackend",
  StoreSpecificBackend = "StoreSpecificBackend",
  Summary = "Summary",
  Success = "Success",
  CommonFrontendFAQs = "CommonFrontendFAQs",
}

export type IntegrationWizardPageType =
  | IntegrationWizardCommonPageType
  | Platform;

export const fullIntegrationWizardPages: IntegrationWizardPageType[] = [
  IntegrationWizardCommonPageType.CommonBackend,
  IntegrationWizardCommonPageType.StoreSpecificBackend,
  IntegrationWizardCommonPageType.Summary,
  IntegrationWizardCommonPageType.Success,
];

export const BasicIntegrationWizardPages: IntegrationWizardPageType[] = [
  IntegrationWizardCommonPageType.CommonBackend,
  IntegrationWizardCommonPageType.Summary,
  IntegrationWizardCommonPageType.Success,
];

export const IntegrationWizardPagesByType = {
  [Platform.WooCommerce]: fullIntegrationWizardPages,
  [Platform.Liana]: BasicIntegrationWizardPages,
  [Platform.Magento]: BasicIntegrationWizardPages,
  [Platform.MyCashFlow]: BasicIntegrationWizardPages,
  [Platform.GoogleShoppingXML]: BasicIntegrationWizardPages,
  [Platform.Refox]: BasicIntegrationWizardPages,
  [Platform.Sotka]: BasicIntegrationWizardPages,
  [Platform.Shopify]: BasicIntegrationWizardPages,
};
