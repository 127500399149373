import { SettingsWizardPageProps } from "./SettingsWizardPage";
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  removeFAQ,
  FAQsAction,
  init,
  addBulkFAQ,
  AddFAQWithPayload,
  UpdateFAQWithPayload,
  setFaqPined,
} from "./FAQsReducer";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { FAQType, IFAQ } from "./FAQs";
import FileUploadWidget, {
  OnFileChangeEventType,
} from "../../common/FileUploadWidget/FileUploadWidget";
import {
  csvStringToObjectArray,
  readFileAsText,
} from "../../common/utils/file";
import { createDefaultFAQ } from "./FAQs";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, Pagination } from "@material-ui/lab";
import usePagination from "../../common/Hooks/usePagination";
import { CustomButton } from "../../common/CustomUiComponent/CustomButton";
import CustomTextField from "../../common/CustomUiComponent/TextField";
import CustomSelectField from "../../common/CustomUiComponent/Select";

import { ReactComponent as EditIcon } from "./../../common/SVG/editIcon.svg";
import { ReactComponent as TrashIcon } from "./../../common/SVG/trashIcon.svg";
import { ReactComponent as UploadIcon } from "./../../common/SVG/uploadIcon.svg";
import { ReactComponent as PinIcon } from "./../../common/SVG/pinIcon.svg";
import { ReactComponent as UnPinIcon } from "./../../common/SVG/unPinIcon.svg";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import AddIcon from "@material-ui/icons/Add";
import { ALLOWED_LANGUAGE, allowedLanguage } from "../../Configs/language";

const allowedLanguageKeySwapWithValue = Object.fromEntries(
  Object.entries(allowedLanguage).map(([key, value]) => [value, key])
);
export interface IFAQUploadMsgType {
  success: string | React.ReactElement;
  fail: string | React.ReactElement;
}

// Faq language context
type IFaqLanguageContext = {
  lang: ALLOWED_LANGUAGE | "default";
  updateLang: (lang: IFaqLanguageContext["lang"]) => void;
};
const FaqLanguageContext = createContext<IFaqLanguageContext | null>(null);

const FaqLanguageProvider = ({ children }: { children?: React.ReactNode }) => {
  const [selectedLang, setSelectedLang] =
    useState<IFaqLanguageContext["lang"]>("default");
  return (
    <FaqLanguageContext.Provider
      value={{
        lang: selectedLang,
        updateLang: (lang: IFaqLanguageContext["lang"]) => {
          setSelectedLang(lang);
        },
      }}
    >
      {children}
    </FaqLanguageContext.Provider>
  );
};

const useFaqLang = () => {
  const context = useContext(FaqLanguageContext);
  if (!context)
    throw new Error("useFaqLang  must be used within the FaqLanguageProvider.");
  return context;
};

const useFaqChange = (faq: IFAQ, index: number = 0) => {
  const [faqState, setFaqState] = useState({ ...faq });
  const [newIndex, setNewIndex] = useState(index);

  const { lang: selectedLang, updateLang: setSelectedLang } = useFaqLang();

  const handleLangChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setSelectedLang(e.target.value as ALLOWED_LANGUAGE);
  };

  const handleFaqChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const { name, value } = e.target;

    if (name) {
      setFaqState((prev) => {
        if (selectedLang !== "default") {
          // Update the locale property when a language is selected
          return {
            ...prev,
            locale: {
              ...prev.locale,
              [selectedLang]: {
                ...prev.locale?.[selectedLang],
                [name]: value,
              },
            },
          };
        } else {
          // Update faqState directly when default language is selected
          return {
            ...prev,
            [name]: value,
          };
        }
      });
    }
  };
  const handleUndo = (e: React.MouseEvent) => {
    setFaqState({
      ...faq,
      locale: {
        ...faq.locale,
      },
    });
    setNewIndex(index);
    // setSelectedLang("default");
  };
  const question =
    selectedLang !== "default"
      ? faqState?.locale?.[selectedLang]?.question ?? ""
      : faqState.question ?? "";

  const answer =
    selectedLang !== "default"
      ? faqState?.locale?.[selectedLang]?.answer ?? ""
      : faqState.answer ?? "";
  return {
    faqState,
    setFaqState,
    selectedLang,
    handleLangChange,
    handleFaqChange,
    handleUndo,
    question,
    answer,
    newIndex,
    setNewIndex,
    setSelectedLang,
  };
};
//Only csv file type are allowed
const allowedUploadFileTypes = ["text/csv", "application/vnd.ms-excel"];

//Convert any csv parse object array to faq object array
function csvToFaqs(csvArr: { [key: string]: string }[]): IFAQ[] {
  if (!csvArr.length) {
    return [];
  }

  const faqs: IFAQ[] = [];
  const defaultFAQ = createDefaultFAQ();
  const faqKeys = Object.keys(defaultFAQ);

  for (const csvObject of csvArr) {
    const hasAllFaqProps = faqKeys.every((key) =>
      Object.prototype.hasOwnProperty.call(csvObject, key)
    );
    if (hasAllFaqProps) {
      const faq = faqKeys.reduce(function (parseObject, key) {
        key = key.trim();
        if (key === "type") {
          if (
            csvObject[key].toLowerCase() === FAQType.Text.toLowerCase() ||
            csvObject[key].toLowerCase() === FAQType.Url.toLowerCase()
          ) {
            parseObject[key] = csvObject[key]
              .trim()
              .replace(/\b\w/g, function (faqType) {
                return faqType.toUpperCase();
              });
          } else {
            parseObject[key] = FAQType.Text;
          }
        } else {
          parseObject[key] = csvObject[key] ? csvObject[key].trim() : "";
        }
        return parseObject;
      }, {} as { [key: string]: string });

      faqs.push(faq as unknown as IFAQ);
    }
  }

  return faqs;
}

export enum EnumFaqUploadTyped {
  BY_CSV_UPLOAD = "BY_CSV_UPLOAD",
  BY_FILLING_FORM = "BY_FILLING_FORM",
}

export type IFaqUploadType = `${EnumFaqUploadTyped}`;

const useFAQAddFormClasses = createUseClasses((theme) => ({
  root: {
    background: "#E1EAFF",
    paddingInline: 30,
    paddingBlock: 48,
    boxShadow:
      "0px 12px 24px rgba(44, 39, 56, 0.02), 0px 32px 64px rgba(44, 39, 56, 0.04)",
    borderRadius: 24,
  },
  cancelBtn: {
    background: "#FFFFFF",
    marginLeft: "auto",
    marginRight: 24,
    border: "none",
  },
}));

const useFaqListClasses = createUseClasses((theme) => ({
  tableRoot: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.md,
    marginTop: 48,
    "& .MuiTableCell-root": {
      borderBottom: "none",
      paddingBlock: 24,
      verticalAlign: "top",
      maxWidth: 480,
      "&:not(.MuiTableCell-head)": {
        color: theme.palette.text.secondary,
        fontWeight: 500,
        lineHeight: "18px",
      },
    },
    "& .MuiTableCell-head": {
      fontWeight: 700,
      lineHeight: "18px",
    },
  },
}));

const useFaqsClasses = createUseClasses((theme) => ({
  uploadIcon: {
    "& path": {
      fill: "currentColor",
    },
  },
}));

const useEditableFaqItemClasses = createUseClasses((theme) => ({
  faqIconContainer: {
    marginTop: 16,
    gap: 3,
  },
  root: {
    background: "rgba(228, 255, 228, 0.7)",
  },
  label: {
    marginTop: -6,
    marginBottom: 8,
  },
  labelLessInput: {
    marginTop: "28px",
  },
}));

function EditableFaqItem({
  allFaqs,
  actualIndex,
  faq,
  onUpdate,
  onCancel,
}: {
  allFaqs: IFAQ[];
  actualIndex: number;
  faq: IFAQ;
  onUpdate: (
    updateContext: { oldIndex: number; newIndex: number; faq: IFAQ },
    e: React.MouseEvent
  ) => void;
  onCancel: (e: React.MouseEvent) => void;
}) {
  const classes = useEditableFaqItemClasses();
  const {
    faqState,
    selectedLang,
    handleFaqChange,
    handleUndo,
    question,
    answer,
    newIndex,
    setNewIndex,
  } = useFaqChange(faq, actualIndex);

  return (
    <TableRow className={classes.root}>
      <TableCell>
        <CustomSelectField
          dropDownIconGapFromRight={10}
          dropDownIconHeight={12}
          dropDownIconWidth={12}
          style={{ marginTop: 26 }}
          variant="outlined"
          value={newIndex}
          onChange={(e) => {
            setNewIndex(Number(e.target.value));
          }}
        >
          {allFaqs.map((_, indexValue) => {
            return (
              <MenuItem key={indexValue} value={indexValue}>
                {indexValue + 1}
              </MenuItem>
            );
          })}
        </CustomSelectField>
      </TableCell>
      <TableCell width={"7.5rem"}>
        <Typography style={{ marginTop: 26 }}>
          {allowedLanguageKeySwapWithValue[selectedLang]}
        </Typography>
        {/* <CustomSelectField
          dropDownIconGapFromRight={10}
          dropDownIconHeight={12}
          dropDownIconWidth={12}
          style={{ marginTop: 26, width: "7.5rem" }}
          variant="outlined"
          value={selectedLang}
          name="language"
          onChange={handleLangChange}
        >
          <MenuItem value={"default"}>Default language</MenuItem>
          {Object.entries(allowedLanguage).map((entry) => (
            <MenuItem key={entry[0]} value={entry[1]}>
              {entry[0]}
            </MenuItem>
          ))}
        </CustomSelectField> */}
      </TableCell>
      <TableCell width={"6rem"}>
        <CustomSelectField
          dropDownIconGapFromRight={10}
          dropDownIconHeight={12}
          dropDownIconWidth={12}
          style={{ marginTop: 26, width: "6rem" }}
          variant="outlined"
          value={faqState.type}
          name="type"
          onChange={handleFaqChange}
        >
          <MenuItem value={FAQType.Url}>{FAQType.Url}</MenuItem>
          <MenuItem value={FAQType.Text}>{FAQType.Text}</MenuItem>
        </CustomSelectField>
      </TableCell>
      <TableCell>
        <>
          <Grid item>
            <Typography className={classes.label}>Question</Typography>
            <CustomTextField
              value={question}
              name="question"
              onChange={handleFaqChange}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.label}>Answer</Typography>
            <CustomTextField
              value={answer}
              name="answer"
              onChange={handleFaqChange}
              multiline
              rowsMax={12}
            />
          </Grid>
        </>
      </TableCell>
      <TableCell>
        <>
          <Grid container justify="center" className={classes.faqIconContainer}>
            <IconButton aria-label="undo" size="small" onClick={handleUndo}>
              <ReplayOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={onCancel}
              aria-label="cancel"
              size="small"
              style={{
                color: "rgb(255, 0, 0)",
                height: 32,
                width: 32,
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={onUpdate.bind(null, {
                oldIndex: actualIndex,
                newIndex,
                faq: faqState,
              })}
              aria-label="update"
              size="small"
              style={{
                color: "rgb(103, 147, 255)",
                height: 32,
                width: 32,
              }}
            >
              <DoneOutlinedIcon />
            </IconButton>
          </Grid>
        </>
      </TableCell>
    </TableRow>
  );
}

const useFaqItemsClasses = createUseClasses((theme) => ({
  pined: {
    background: "#F7F6FE",
  },
  faqLabel: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18px",
  },
  faqContent: {
    background: "#FFFFFF",
    border: "1px solid #DBE2EA",
    boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.04)",
    borderRadius: "6px",
    padding: 16,
    marginBlock: 10,
    wordBreak: "break-word",
  },
  faqIconContainer: {
    marginTop: 16,
    gap: 4,
  },
}));

function FAQItem({
  currentListIndex,
  onEdit,
  onPin,
  onDelete,
  faq,
  pined,
}: {
  currentListIndex: number;
  onEdit: (e: React.MouseEvent) => void;
  onDelete: (e: React.MouseEvent) => void;
  onPin: (e: React.MouseEvent) => void;
  faq: IFAQ;
  pined?: boolean;
}) {
  const classes = useFaqItemsClasses();
  const { selectedLang, question, answer } = useFaqChange(faq);
  return (
    <TableRow className={`${pined ? classes.pined : ""}`}>
      <TableCell>{"#" + (currentListIndex + 1)}</TableCell>
      <TableCell width={"7.5rem"}>
        {allowedLanguageKeySwapWithValue[selectedLang]}
        {/* <CustomSelectField
          dropDownIconGapFromRight={10}
          dropDownIconHeight={12}
          dropDownIconWidth={12}
          style={{ marginTop: -8, width: "7.5rem" }}
          variant="outlined"
          value={selectedLang}
          name="language"
          onChange={handleLangChange}
        >
          <MenuItem value={"default"}>Default language</MenuItem>
          {Object.entries(allowedLanguage).map((entry) => (
            <MenuItem key={entry[0]} value={entry[1]}>
              {entry[0]}
            </MenuItem>
          ))}
        </CustomSelectField> */}
      </TableCell>
      <TableCell style={{ width: "6.5rem" }}>{faq.type}</TableCell>
      <TableCell>
        <>
          <Grid item>
            <Typography className={classes.faqLabel}>Question</Typography>
            <Typography className={classes.faqContent}>{question}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.faqLabel}>Answer</Typography>
            <Typography className={classes.faqContent}>{answer}</Typography>
          </Grid>
        </>
      </TableCell>
      <TableCell>
        <Grid container justify="center" className={classes.faqIconContainer}>
          <>
            <IconButton aria-label="pin" size="small" onClick={onPin}>
              {faq.isPinded ? <UnPinIcon /> : <PinIcon />}
            </IconButton>
            <IconButton aria-label="edit" size="small" onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={onDelete} aria-label="delete" size="small">
              <TrashIcon />
            </IconButton>
          </>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

interface IFAQExtended extends IFAQ {
  actualIndex: number;
}
function FAQList({
  currentFaqs,
  dispatch,
  allFaqs,
  currentPage,
  maxPage,
  itemPerPage,
  onPaginationChange,
}: {
  currentFaqs: IFAQ[];
  allFaqs: IFAQ[];
  currentPage: number;
  maxPage: number;
  itemPerPage: number;
  onPaginationChange: (event: object, page: number) => void;
  dispatch: FAQProps["dispatch"];
}) {
  const classes = useFaqListClasses();
  const MAX_PINABLE_FAQ = 3;
  const [pinedFaqs, setPinedFaqs] = useState<IFAQExtended[]>([]);
  const [editablePinedFaqIndex, setEditablePinedFaqIndex] = useState(-1);
  const [editableFaqIndex, setEditableFaqIndex] = useState(-1);
  const [pinedSnackbarOpen, setPinedSnackbarOpen] = useState(false);
  useEffect(() => {
    setPinedFaqs((prev) =>
      allFaqs
        .map((faq, index) => ({ ...faq, actualIndex: index }))
        .filter((item) => item.isPinded)
    );
  }, [allFaqs]);

  const handlePin = ({
    index,
    isPined,
  }: {
    index: number;
    isPined: boolean;
  }) => {
    if (isPined === false) {
      dispatch(setFaqPined(index, isPined));
    } else if (isPined === true && pinedFaqs.length < MAX_PINABLE_FAQ) {
      dispatch(setFaqPined(index, isPined));
    } else if (isPined === true && pinedFaqs.length >= MAX_PINABLE_FAQ) {
      setPinedSnackbarOpen(true);
    }
  };

  const handlePinedPin = (index: number) => {
    dispatch(setFaqPined(index, false));
  };

  const handleDelete = (index: number) => {
    dispatch(removeFAQ(index));
  };

  const handlePinedItemCancelUpdate = (e: React.MouseEvent) => {
    setEditablePinedFaqIndex(-1);
  };
  const handleCancelUpdate = (e: React.MouseEvent) => {
    setEditableFaqIndex(-1);
  };
  const handleUpdate = (
    {
      oldIndex,
      newIndex,
      faq,
    }: { oldIndex: number; newIndex: number; faq: IFAQ },
    e: React.MouseEvent
  ) => {
    setEditableFaqIndex(-1);
    dispatch(UpdateFAQWithPayload(oldIndex, newIndex, faq));
  };
  const handlePinedItemUpdate = (
    {
      oldIndex,
      newIndex,
      faq,
    }: { oldIndex: number; newIndex: number; faq: IFAQ },
    e: React.MouseEvent
  ) => {
    setEditablePinedFaqIndex(-1);
    dispatch(UpdateFAQWithPayload(oldIndex, newIndex, faq));
  };

  const isEditableRow = (index: number) => {
    return index === editableFaqIndex;
  };

  const isEditablePinedRow = (index: number) => {
    return index === editablePinedFaqIndex;
  };

  return (
    <>
      {allFaqs.length ? (
        <>
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ maxWidth: 920 }}>
              <Typography variant="h6" gutterBottom>
                FAQs List
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ maxWidth: 920 }}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                You can pin (lock) 3 FAQs, which will always be shown on the
                first three lines. The rest of the questions will be selected by
                AI after learning the most popular FAQs
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ maxWidth: 920, marginTop: 12 }}>
              <Typography variant="body2" color="textSecondary">
                The first three (3) FAQs will be shown by default in the FAQ
                search view. The rest will still be searchable by the user.
              </Typography>
            </Grid>
          </Grid>
          <TableContainer>
            <Table className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell>Order</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>FAQ Type</TableCell>
                  <TableCell>FAQ</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pinedFaqs.map((faq, currentListIndex) => {
                  const actualIndex = faq.actualIndex;
                  const FaqItem = isEditablePinedRow(currentListIndex) ? (
                    <EditableFaqItem
                      onCancel={handlePinedItemCancelUpdate}
                      actualIndex={actualIndex}
                      faq={faq}
                      onUpdate={handlePinedItemUpdate}
                      key={faq.question + currentListIndex}
                      allFaqs={allFaqs}
                    />
                  ) : (
                    <FAQItem
                      pined={true}
                      onEdit={(e) => {
                        setEditablePinedFaqIndex(currentListIndex);
                      }}
                      onDelete={handleDelete.bind(null, actualIndex)}
                      onPin={handlePinedPin.bind(null, actualIndex)}
                      key={faq.question + currentListIndex}
                      currentListIndex={currentListIndex}
                      faq={faq}
                    />
                  );
                  return FaqItem;
                })}

                {currentFaqs.map((faq, currentListIndex) => {
                  const actualIndex =
                    itemPerPage * (currentPage - 1) + currentListIndex;
                  const FaqItem = isEditableRow(currentListIndex) ? (
                    <EditableFaqItem
                      onCancel={handleCancelUpdate}
                      actualIndex={actualIndex}
                      faq={faq}
                      onUpdate={handleUpdate}
                      key={faq.question + currentListIndex}
                      allFaqs={allFaqs}
                    />
                  ) : (
                    <FAQItem
                      onDelete={handleDelete.bind(null, actualIndex)}
                      onPin={handlePin.bind(null, {
                        index: actualIndex,
                        isPined: !faq.isPinded,
                      })}
                      onEdit={(e) => {
                        setEditableFaqIndex(currentListIndex);
                      }}
                      key={faq.question + currentListIndex}
                      currentListIndex={actualIndex}
                      faq={faq}
                    />
                  );

                  return FaqItem;
                })}

                {allFaqs.length && (
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>
                      <Grid item xs={12} container justify="flex-start">
                        <Grid item>
                          <Pagination
                            disabled={maxPage < 1}
                            count={maxPage}
                            page={currentPage}
                            onChange={onPaginationChange}
                            showFirstButton
                            showLastButton
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Snackbar
            open={pinedSnackbarOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={5000}
            onClose={() => setPinedSnackbarOpen(false)}
            message={`Unpin a pined item first. You can pin maximum ${MAX_PINABLE_FAQ} faq.`}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setPinedSnackbarOpen(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </>
      ) : null}
    </>
  );
}

function FAQAddForm({
  id,
  faqLength,
  dispatch,
}: {
  id?: string;
  faqLength: number;
  dispatch: FAQProps["dispatch"];
}) {
  const classes = useFAQAddFormClasses();
  const oldIndex = faqLength;

  const defaultFaq: IFAQ = {
    type: FAQType.Text,
    question: "",
    answer: "",
    originalIndex: oldIndex,
  };

  const {
    question,
    answer,
    setNewIndex,
    faqState: faq,
    setSelectedLang,
    handleFaqChange,
    setFaqState: setFaq,
    handleUndo: handleCancelFaqSave,
    newIndex,
  } = useFaqChange(defaultFaq, oldIndex);

  const handleFaqSave = (e: React.MouseEvent) => {
    dispatch(AddFAQWithPayload(oldIndex, newIndex, faq));
    setFaq(() => ({ ...defaultFaq }));
    setNewIndex(oldIndex);
    setSelectedLang("default");
  };

  return (
    <Grid container item xs={12} className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Add new question and answer
      </Typography>
      <Grid
        container
        item
        xs={12}
        style={{ marginTop: 40 }}
        justify="space-between"
      >
        {/* <Grid container item xs={12}>
          <Grid item xs={12} md={5}>
            <FormControl>
              <CustomSelectField
                value={selectedLang}
                labelFor="language"
                labelText="Language"
                placeholder="Language"
                fullWidth
                variant="outlined"
                name="language"
                onChange={handleLangChange}
              >
                <MenuItem value={"default"}>Default language</MenuItem>
                {Object.entries(allowedLanguage).map((entry) => (
                  <MenuItem key={entry[0]} value={entry[1]}>
                    {entry[0]}
                  </MenuItem>
                ))}
              </CustomSelectField>
            </FormControl>
          </Grid>
        </Grid> */}
        <Grid item xs={12} md={5}>
          <FormControl>
            <CustomSelectField
              value={faq.type}
              labelFor="type"
              labelText="Type"
              placeholder="Type"
              fullWidth
              variant="outlined"
              name="type"
              onChange={handleFaqChange}
            >
              {[FAQType.Text, FAQType.Url].map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </CustomSelectField>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl>
            <CustomSelectField
              value={newIndex}
              name="order"
              onChange={(e) => {
                setNewIndex(Number(e.target.value));
              }}
              labelFor="order"
              labelText="Order"
              placeholder="Order"
              fullWidth
              variant="outlined"
            >
              {Array(oldIndex + 1)
                .fill(1)
                .map((type, order) => {
                  return (
                    <MenuItem key={order} value={order}>
                      {order + 1}
                    </MenuItem>
                  );
                })}
            </CustomSelectField>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            value={question}
            name="question"
            onChange={handleFaqChange}
            labelText="question"
            labelFor="Question"
            placeholder="Question"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="answer"
            onChange={handleFaqChange}
            value={answer}
            labelText="answer"
            labelFor="Answer"
            placeholder="Answer"
            multiline
            rows={4}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={9}
          style={{ marginLeft: "auto", marginTop: 24 }}
        >
          <CustomButton
            onClick={handleCancelFaqSave}
            variant="outlined"
            className={classes.cancelBtn}
          >
            cancel
          </CustomButton>
          <CustomButton
            onClick={handleFaqSave}
            variant="contained"
            style={{ marginRight: 8, paddingInline: 24 }}
          >
            save
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

const LanguageSelector = () => {
  const { lang: selectedLang, updateLang } = useFaqLang();
  return (
    <FormControl>
      <CustomSelectField
        value={selectedLang}
        labelFor="language"
        labelText="Language"
        placeholder="Language"
        fullWidth
        variant="outlined"
        name="language"
        onChange={(e) => {
          updateLang(e.target.value as ALLOWED_LANGUAGE);
        }}
      >
        <MenuItem value={"default"}>Default language</MenuItem>
        {Object.entries(allowedLanguage).map((entry) => (
          <MenuItem key={entry[0]} value={entry[1]}>
            {entry[0]}
          </MenuItem>
        ))}
      </CustomSelectField>
    </FormControl>
  );
};
export function CommonFrontendFAQs({ id }: SettingsWizardPageProps) {
  const { dispatchFAQsAction, customQnA } = useFAQs(id);
  const [faqUploadType, setFaqUploadType] = useState<IFaqUploadType>();
  const classes = useFaqsClasses();
  const itemsPerPage = 10;
  const {
    jumpToPage,
    prevPage,
    getPaginatedData,
    maxPage,
    startIndex,
    currentPage,
  } = usePagination(customQnA, itemsPerPage);

  const currentFaqs = getPaginatedData();
  const onPaginationChange = (event: object, page: number) => {
    jumpToPage(page);
  };
  function handleUploadTypeCsv() {
    setFaqUploadType(EnumFaqUploadTyped.BY_CSV_UPLOAD);
  }
  function handleUploadTypeForm() {
    setFaqUploadType(EnumFaqUploadTyped.BY_FILLING_FORM);
  }

  return (
    <FaqLanguageProvider>
      <Grid container item xs={12} style={{ maxWidth: 720 }}>
        <Grid item xs={12}>
          <LanguageSelector />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Select your frequently asked questions (FAQs) and answers
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        style={{ fontStyle: "italic", color: "#C60101" }}
      >
        You can skip this step and complete it later. UPSY widget will work
        without FAQs.
      </Typography>
      <Grid
        container
        item
        xs={12}
        style={{ marginTop: 36, maxWidth: 720, gap: 8 }}
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={12} md={5}>
          <CustomButton
            onClick={handleUploadTypeCsv}
            startIcon={<UploadIcon className={classes.uploadIcon} />}
            variant={
              faqUploadType === EnumFaqUploadTyped.BY_CSV_UPLOAD
                ? "contained"
                : "outlined"
            }
            fullWidth
          >
            Upload FAQs from CSV File
          </CustomButton>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography align="center" color="textSecondary">
            or
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <CustomButton
            onClick={handleUploadTypeForm}
            startIcon={
              <AddIcon
                className={classes.uploadIcon}
                style={{ fontSize: "25px" }}
              />
            }
            variant={
              faqUploadType === EnumFaqUploadTyped.BY_FILLING_FORM
                ? "contained"
                : "outlined"
            }
            fullWidth
          >
            Add FAQ manually
          </CustomButton>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 40, color: "#F1F4F8" }} />
      {faqUploadType && (
        <>
          <Grid
            container
            item
            xs={12}
            style={{ maxWidth: 720, gap: 8 }}
            alignItems="center"
            justify="space-between"
          >
            {faqUploadType === EnumFaqUploadTyped.BY_CSV_UPLOAD ? (
              <Grid item xs={12} style={{ marginTop: 40 }}>
                <CsvFAQUpload id={id} />
              </Grid>
            ) : faqUploadType === EnumFaqUploadTyped.BY_FILLING_FORM ? (
              <Grid item xs={12} style={{ marginTop: 8 }}>
                <FAQAddForm
                  faqLength={customQnA.length}
                  dispatch={dispatchFAQsAction}
                />
              </Grid>
            ) : null}
          </Grid>
          <Divider style={{ marginTop: 40, color: "#F1F4F8" }} />
        </>
      )}

      <Grid container>
        <Grid item xs={12} container style={{ marginTop: 32 }}>
          <FAQList
            dispatch={dispatchFAQsAction}
            allFaqs={customQnA}
            currentFaqs={currentFaqs}
            currentPage={currentPage}
            maxPage={maxPage}
            itemPerPage={itemsPerPage}
            onPaginationChange={onPaginationChange}
          />
        </Grid>
      </Grid>
    </FaqLanguageProvider>
  );
}

type FAQProps = {
  index: number;
  dispatch: Dispatch<FAQsAction>;
  faqs?: IFAQ[];
} & IFAQ;

function CsvFAQUpload({ id }: SettingsWizardPageProps) {
  const { dispatchFAQsAction } = useFAQs(id);
  const [file, setFile] = useState<File | null>(null);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [uploadMsg, setUploadMsg] = useState<IFAQUploadMsgType>({
    success: "",
    fail: "",
  });
  const maxFaqUploadLimit = 50;
  const classes = useCsvFaqUploadClasses();

  const loadCsvData = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsCsvLoading(true);
    readFileAsText(file as File)
      .then((readAsText) => {
        const csvObjArr = csvStringToObjectArray(readAsText);
        let faqs = csvToFaqs(csvObjArr);
        if (faqs.length) {
          if (faqs.length > maxFaqUploadLimit) {
            faqs = faqs.slice(0, maxFaqUploadLimit);
          }
          dispatchFAQsAction(addBulkFAQ(faqs));
          setFile(null);
          setUploadMsg({
            success:
              "The CSV file was processed successfully and you should find the given FAQ items at the bottom of the list.",
            fail: "",
          });
        } else {
          setFile(null);
          setUploadMsg({
            success: "",
            fail: (
              <span>
                Sorry! you haven't provided a valid FAQ data in the CSV file.
                Please look into the sample FAQ CSV file to know about the
                format. <a href="/csv-faq-example.csv">CSV TEMPLATE LINK</a>
              </span>
            ),
          });
        }

        setIsCsvLoading(false);
      })
      .catch((e) => {
        setFile(null);
        setUploadMsg({
          success: "",
          fail: (
            <span>
              Sorry! Something went wrong. The processing of the CSV file was
              failed. Please try again or look into the sample CSV file to know
              about the valid format.{" "}
              <a href="/csv-faq-example.csv">CSV TEMPLATE LINK</a>
            </span>
          ),
        });
        setIsCsvLoading(false);
      });
  };

  const handleFileChange: OnFileChangeEventType = (e) => {
    if (!file) {
      const target = e.target;
      const uploadFile = target && target.files && target.files[0];
      const type = uploadFile && uploadFile.type;

      //Only csv file type are acceptable
      if (type && allowedUploadFileTypes.includes(type)) {
        setFile(uploadFile);
        setUploadMsg({
          success: "The CSV file was uploaded successfully.",
          fail: "",
        });
      } else {
        setUploadMsg({
          success: "",
          fail: "Sorry! Please upload a CSV file with right format.",
        });
        setFile(null);
      }
    } else {
      setUploadMsg({
        success: "",
        fail: "Please remove the uploaded CSV file first or load the FAQ from uploaded CSV file.",
      });
    }

    e.target.value = "";
  };

  const handleFileClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setFile(null);
  };

  const handleAlertClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setUploadMsg({
      success: "",
      fail: "",
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          Please download the csv template below and fill in the following
          information in lower case letters:
        </Typography>
        <ul className={classes.ul}>
          <li>
            FAQ type (accepted values are <b>text</b> or <b>URL</b> if using
            hyperlinks)
          </li>
          <li>Question</li>
          <li>Answer</li>
        </ul>
        <Typography
          variant="body2"
          gutterBottom
          style={{ fontStyle: "italic", color: "#C60101" }}
        >
          Maximum FAQ upload limit is {maxFaqUploadLimit} FAQ's at once.
        </Typography>
        <Box my={4}>
          {(uploadMsg.success || uploadMsg.fail) && (
            <Box mt={1}>
              <Alert
                classes={{ icon: classes.alertIcon }}
                onClose={handleAlertClose}
                severity={uploadMsg.fail ? "error" : "success"}
              >
                {uploadMsg.success || uploadMsg.fail}
              </Alert>
            </Box>
          )}
          {file && (
            <List className={classes.root}>
              <ListItem className={classes.fileListItem}>
                <ListItemAvatar>
                  <Avatar>
                    <AttachFileIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={file.name}
                  secondary={Math.ceil(file.size / 1023) + "kb"}
                />
                <IconButton onClick={handleFileClose}>
                  <CloseIcon color="secondary" />
                </IconButton>
              </ListItem>
            </List>
          )}
          {file && (
            <Grid item xs={12} md={6}>
              <CustomButton
                fullWidth
                size="small"
                onClick={loadCsvData}
                variant="contained"
                color="primary"
              >
                {isCsvLoading ? "Saving FAQ ..." : "Save FAQ"}
              </CustomButton>
            </Grid>
          )}
        </Box>

        <Grid
          container
          item
          xs={12}
          style={{ gap: 8 }}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <CustomButton
              startIcon={<UploadIcon className={classes.uploadIcon} />}
              component="a"
              href="/csv-faq-example.csv"
              variant="outlined"
              fullWidth
            >
              Download csv template
            </CustomButton>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography align="center" color="textSecondary">
              or
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FileUploadWidget
              accept=".csv"
              className={classes.uploadBtn}
              id="csv-file-upload"
              fieldName="csv-file"
              text={"Upload csv file"}
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function useFAQs(id: string) {
  const { configuration, dispatchFAQsAction } = useConfiguration(id);
  useEffect(() => {
    dispatchFAQsAction(init());
    // eslint-disable-next-line
  }, []);

  const FAQsWithOriginalIndex = configuration?.customQnA?.map((faq, i) => ({
    ...faq,
    originalIndex: i,
  }));

  return {
    customQnA: FAQsWithOriginalIndex || [],
    dispatchFAQsAction,
  };
}

const useCsvFaqUploadClasses = createUseClasses((theme) => ({
  listItem: {
    backgroundColor: theme.palette.background.paper,
  },
  ul: {
    listStyleType: "none",
    paddingLeft: 0,
    margin: 0,
    marginTop: 20,
    marginBottom: 8,
    color: theme.palette.text.secondary,
    "& li": {
      "&:before": {
        content: "'-'",
        paddingRight: 4,
      },
    },
  },
  fileListItem: {
    borderRadius: "4px",
    marginBottom: "5px",
    background: theme.palette.background.paper,
  },
  alertIcon: {
    alignItems: "center",
  },
}));
