import * as React from "react";
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import { IConfiguration } from "./Configuration";
import {
  clearTempConfiguration,
  ConfigurationAction,
  configurationsInitialState,
  localStorageConfigurationsReducer,
  setAllConfigurations,
  setCommonBackendConfiguration,
  SetCommonBackendConfigurationAction,
  setCommonFrontendConfiguration,
  SetCommonFrontendConfigurationAction,
  setCommonFrontendFAQs,
  SetCommonFrontendFAQsAction,
  setCommonFrontendFlowRules,
  SetCommonFrontendFlowRulesAction,
  setWooCommerceSpecificConfiguration,
  SetWooCommerceSpecificConfigurationAction,
} from "./configurationsReducer";
import { loadConfigurations, saveConfiguration } from "./configurationsApi";
import { backendTransformationByType } from "./dashboardToBackendTransformer";
import { deleteStore, testShopConfiguration } from "../Client/backendClient";
import { useUserContext } from "../UserContext/UserContext";

const ConfigurationsLoadingContext = createContext(true);
const ConfigurationSaveContext = createContext(
  (configuration: IConfiguration) => {}
);
const ConfigurationsRefreshContext = createContext(async () => {});
const ConfigurationDeleteContext = createContext((storeId: string) => {});
const ConfigurationErrorContext = createContext<string | undefined>(undefined);
const ConfigurationsStateContext = createContext<IConfiguration[]>([]);
const ConfigurationsDispatchContext = createContext<
  Dispatch<ConfigurationAction>
>(() => {});

export function ConfigurationsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { user } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(
    localStorageConfigurationsReducer,
    configurationsInitialState
  );
  const [configurationError, setConfigurationError] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    loadConfigurations(user).then((configurations) => {
      dispatch(setAllConfigurations(configurations));
      setLoading(false);
    });
  }, [user]);

  async function save(configuration: IConfiguration) {
    setLoading(true);

    try {
      await saveConfiguration(configuration, user);
    } catch (err) {
      setConfigurationError(err ? err.message : "Failed saving configuration");
      setLoading(false);
      throw err;
    }

    const configurations = await loadConfigurations(user);
    dispatch(setAllConfigurations(configurations));
    setLoading(false);
    setConfigurationError(undefined);
  }

  async function refreshConfigurations() {
    const configurations = await loadConfigurations(user);
    dispatch(setAllConfigurations(configurations));
  }

  async function deleteStoreConfiguration(storeId: string) {
    await deleteStore(storeId, user);
    const configurations = await loadConfigurations(user);
    dispatch(setAllConfigurations(configurations));
  }

  return (
    <ConfigurationsLoadingContext.Provider value={loading}>
      <ConfigurationsStateContext.Provider value={state}>
        <ConfigurationsDispatchContext.Provider value={dispatch}>
          <ConfigurationsRefreshContext.Provider value={refreshConfigurations}>
            <ConfigurationSaveContext.Provider value={save}>
              <ConfigurationDeleteContext.Provider
                value={deleteStoreConfiguration}
              >
                <ConfigurationErrorContext.Provider value={configurationError}>
                  {children}
                </ConfigurationErrorContext.Provider>
              </ConfigurationDeleteContext.Provider>
            </ConfigurationSaveContext.Provider>
          </ConfigurationsRefreshContext.Provider>
        </ConfigurationsDispatchContext.Provider>
      </ConfigurationsStateContext.Provider>
    </ConfigurationsLoadingContext.Provider>
  );
}

export function useConfigurations() {
  const loading = useContext(ConfigurationsLoadingContext);
  const configurations = useContext(ConfigurationsStateContext);
  return {
    loading,
    configurations,
  };
}

export const useConfigurationTester = (
  configuration: Partial<IConfiguration | undefined>
) => {
  const [testingConfiguration, setTestingConfiguration] =
    useState<boolean>(false);
  const [testingError, setTestingError] = useState<string | undefined>(
    undefined
  );
  const { user } = useUserContext();

  return {
    testBackendShopConfiguration: async (
      successCallback: () => void
    ): Promise<void> => {
      const spec =
        backendTransformationByType[
          configuration?.platform ? configuration.platform.toLowerCase() : ""
        ];
      if (!user || !spec || !configuration) {
        setTestingError(
          "Internal error, unable to validate store configuration"
        );
        return Promise.resolve();
      }

      setTestingConfiguration(true);
      try {
        await testShopConfiguration(
          user,
          spec.transform(configuration).backendConfiguration
        );
        setTestingConfiguration(false);
        setTestingError(undefined);
        successCallback();
      } catch (err) {
        setTestingError(err.message || "Failed validating shop configuration");
        setTestingConfiguration(false);
      }
    },
    testingConfiguration,
    testingError,
  };
};

const isValidURL = (urlString?: string): boolean => {
  try {
    return /^(http|https)/.test(new URL(urlString || "").protocol);
  } catch (_) {
    return false;
  }
};

export const useCommonBackendConfigurationValidator = (
  configuration: Partial<IConfiguration | undefined>
) => {
  const [
    commonBackendConfigurationValidationError,
    setCommonBackendConfigurationValidationError,
  ] = useState<string | undefined>(undefined);

  return {
    validateCommonBackendConfiguration: (successCallback: () => void): void => {
      const { storeName, storeUrl } = configuration || {};
      if (!storeName || !storeUrl) {
        setCommonBackendConfigurationValidationError(
          "Store name and store URL are both required!"
        );
      } else if (!isValidURL(storeUrl)) {
        setCommonBackendConfigurationValidationError(
          "The entered store URL is not valid"
        );
      } else {
        successCallback();
      }
    },
    commonBackendConfigurationValidationError,
  };
};

export function useRefreshConfigurations() {
  return useContext(ConfigurationsRefreshContext);
}
export function useConfiguration(id: string) {
  const configurations = useContext(ConfigurationsStateContext);
  const dispatch = useContext(ConfigurationsDispatchContext);
  const saveConfiguration = useContext(ConfigurationSaveContext);
  const deleteConfiguration = useContext(ConfigurationDeleteContext);
  const configurationError = useContext(ConfigurationErrorContext);
  return {
    configuration: configurations.find(
      (configuration) => configuration.id === id
    ),
    setCommonBackendConfiguration: (
      commonBackendConfiguration: SetCommonBackendConfigurationAction["commonBackendConfiguration"]
    ) =>
      dispatch(setCommonBackendConfiguration(id, commonBackendConfiguration)),
    setCommonFrontendConfiguration: (
      commonFrontendConfiguration: SetCommonFrontendConfigurationAction["commonFrontendConfiguration"]
    ) =>
      dispatch(setCommonFrontendConfiguration(id, commonFrontendConfiguration)),
    dispatchFlowRulesAction: (
      flowRulesAction: SetCommonFrontendFlowRulesAction["flowRulesAction"]
    ) => dispatch(setCommonFrontendFlowRules(id, flowRulesAction)),
    dispatchFAQsAction: (
      FAQsAction: SetCommonFrontendFAQsAction["FAQsAction"]
    ) => dispatch(setCommonFrontendFAQs(id, FAQsAction)),
    setWooCommerceSpecificConfiguration: (
      wooCommerceSpecificConfiguration: SetWooCommerceSpecificConfigurationAction["wooCommerceSpecificConfiguration"]
    ) =>
      dispatch(
        setWooCommerceSpecificConfiguration(
          id,
          wooCommerceSpecificConfiguration
        )
      ),
    saveConfiguration,
    deleteConfiguration,
    configurationError,
    clearTempConfiguration: () => dispatch(clearTempConfiguration()),
  };
}
