export const UserRole = {
  admin: "admin",
} as const;

export type UserRoleType = keyof typeof UserRole | null | undefined;

export type User = {
  name?: string;
  email: string;
  verified: boolean;
  secretKey?: string;
  role?: UserRoleType;
} | null;
