import { Button } from "@material-ui/core";
import React from "react";
import { useEffect, useRef, useState } from "react";
import ScriptTag from "react-script-tag";
import { IConfigurationCommonFrontendDynamoDB } from "../../common/Client/BackendResponse";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import {
  activeWidgetScreenEnum,
  ActiveWidgetScreenType,
  IWidget,
  UpsyContextType,
} from "./types/widgetPreview";

const widgetJsUrl = process.env.REACT_APP_WIDGET_JS_URL;
const widgetBranch = process.env.REACT_APP_WIDGET_BRANCH;

export function UpsyPreview({
  config,
  widgetScreen,
  widgetContext,
}: {
  config: IConfigurationCommonFrontendDynamoDB;
  widgetScreen?: ActiveWidgetScreenType;
  widgetContext?: UpsyContextType;
}) {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [widgetClicked, setWidgetClicked] = useState(false);
  const onScriptLoaded = () => {
    setScriptLoaded(true);
  };
  const upsyWidget = useRef<IWidget | null>(null);
  const initialWidgetContext = useRef<UpsyContextType | null>(null);
  const initialWidgetScreen = useRef<ActiveWidgetScreenType | null>(null);
  const initialHeight = 120;
  const [height, setHeight] = useState<number>(initialHeight);
  const widgetScriptSrc =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:8000/static/upsy.js"
      : widgetJsUrl
      ? widgetJsUrl
      : "https://upsy-widget.upsyshopping.com/static/upsy.js";

  const widgetPreviewEnvironment =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "local"
      : widgetBranch
      ? widgetBranch
      : "production";

  useEffect(() => {
    (async () => {
      if (scriptLoaded && config && parentRef.current) {
        setWidgetClicked(false);
        try {
          upsyWidget.current = (await (window as any).upsy_sdk.preview(
            config,
            parentRef.current,
            widgetPreviewEnvironment
          )) as IWidget;

          initialWidgetScreen.current = upsyWidget.current.getWidgetScreen();
          initialWidgetContext.current = upsyWidget.current.getWidgetContext();
          widgetScreen && upsyWidget.current.setWidgetScreen(widgetScreen);
          widgetContext && upsyWidget.current.setWidgetContext(widgetContext);

          upsyWidget.current.subscribeScreen((screen) => {
            if (
              [
                activeWidgetScreenEnum.WIDGETMINIFIED,
                activeWidgetScreenEnum.FEEDBACK,
              ].includes(screen as activeWidgetScreenEnum)
            ) {
              setHeight(300);
            } else if (screen === activeWidgetScreenEnum.WIDGETEXPANDED) {
              setHeight(698);
            } else {
              setHeight(initialHeight);
            }
          });
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [scriptLoaded, parentRef.current]);

  useEffect(() => {
    if (upsyWidget.current) {
      widgetScreen && upsyWidget.current.setWidgetScreen(widgetScreen);
      widgetContext && upsyWidget.current.setWidgetContext(widgetContext);
    }
  }, [widgetContext, widgetScreen]);

  useEffect(() => {
    if (upsyWidget.current) {
      upsyWidget.current.updateConfig({ ...config });
    }
  }, [config]);

  const reset = () => {
    if (!upsyWidget.current) {
      return;
    }
    setWidgetClicked(false);
    initialWidgetContext.current &&
      upsyWidget.current.setWidgetContext(initialWidgetContext.current);
    initialWidgetScreen.current &&
      upsyWidget.current.setWidgetScreen(initialWidgetScreen.current);
    widgetScreen && upsyWidget.current.setWidgetScreen(widgetScreen);
    widgetContext && upsyWidget.current.setWidgetContext(widgetContext);
  };

  return (
    <>
      <ScriptTag
        type="text/javascript"
        src={widgetScriptSrc}
        onLoad={onScriptLoaded}
      />
      {widgetClicked ? (
        <Button
          style={{ marginLeft: config?.widgetToLeft ? "auto" : "0" }}
          variant="contained"
          color="primary"
          onClick={reset}
        >
          Reset
        </Button>
      ) : (
        <></>
      )}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: `${height}px`,
        }}
        ref={parentRef}
        onClick={() => setWidgetClicked(true)}
      ></div>
    </>
  );
}

// Wrapper container for upsy preview section
export function UpsyPreviewContainer({
  children,
  minHeight = 250,
}: {
  children: React.ReactNode;
  minHeight?: number;
}) {
  const classes = usePreviewContainerClasses();
  return (
    <div style={{ minHeight }} className={classes.root}>
      {children}
    </div>
  );
}

const usePreviewContainerClasses = createUseClasses((theme) => ({
  root: {
    width: "100%",
    margin: "20px 0 5px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "baseline",
    background: "rgb(252, 238, 230)",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    padding: "0.7rem",
    borderRadius: "0.5rem",
  },
}));
