import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { SettingsWizardPageProps } from "./SettingsWizardPage";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { Box, Grid, IconButton, MenuItem, Typography } from "@material-ui/core";
import CustomTextField from "../../common/CustomUiComponent/TextField";
import { ReactComponent as EditIcon } from "./../../common/SVG/editIcon.svg";
import { ReactComponent as TrashIcon } from "./../../common/SVG/trashIcon.svg";
import { ReactComponent as PinIcon } from "./../../common/SVG/pinIcon.svg";
import { ReactComponent as UnPinIcon } from "./../../common/SVG/unPinIcon.svg";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import { InlineImageUploadWidget } from "../../common/ImageUploader/InlineImageUpload";
import CustomSelectField from "../../common/CustomUiComponent/Select";
import { CATEGORY } from "./FAQs";
import { useCategory } from "./CommonFrontendCategories";
import { allowedLanguage } from "../../Configs/language";
const allowedLanguageKeySwapWithValue = Object.fromEntries(
  Object.entries(allowedLanguage).map(([key, value]) => [value, key])
);
interface ICategory {
  category: CATEGORY;
  index: number;
  id: string;
  onEdit: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    flex: "1 1 100%",
    marginBottom: "40px",
    fontWeight: "bold",
  },
  cellHeadStyle: {
    "&.MuiTableCell-head": {
      fontWeight: "bold",
    },
    borderBottom: "0px",
  },
  tableRowRoot: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F7F6FE",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#FFFFFF",
    },
    height: "122px",
  },
  categoryImage: {
    width: "64px",
    height: "64px",
    border: "1px solid #DBE2EA",
    padding: "5px",
    borderRadius: "5px",
  },
  iconContainer: {
    marginTop: "-15px",
    gap: 3,
  },
  tableCellStyle: {
    borderBottom: "0px",
  },
});

export default function Categories({ id }: SettingsWizardPageProps) {
  const classes = useStyles();
  const { configuration } = useConfiguration(id);
  const [categoryIdForEdit, setCategoryIdForEdit] = useState(-1);

  const isCurrentItemSelected = (id: number) => id === categoryIdForEdit;

  return (
    <>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "20px" }}
      >
        Category List
      </Typography>
      <TableContainer component={Paper} style={{ boxShadow: "unset" }}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell classes={{ root: classes.cellHeadStyle }}></TableCell>
              <TableCell align="left" classes={{ root: classes.cellHeadStyle }}>
                Order
              </TableCell>
              <TableCell align="left" classes={{ root: classes.cellHeadStyle }}>
                Language
              </TableCell>
              <TableCell
                align="left"
                classes={{ root: classes.cellHeadStyle }}
                width="200"
              >
                Category Name
              </TableCell>
              <TableCell align="left" classes={{ root: classes.cellHeadStyle }}>
                Category Image
              </TableCell>
              <TableCell align="left" classes={{ root: classes.cellHeadStyle }}>
                Category URL
              </TableCell>
              <TableCell
                align="center"
                classes={{ root: classes.cellHeadStyle }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {configuration?.customerCategories?.map((category, index) => {
              const categories = !isCurrentItemSelected(category.order) ? (
                <CategoryList
                  category={category}
                  index={index}
                  id={id}
                  onEdit={() => {
                    setCategoryIdForEdit(category.order);
                  }}
                />
              ) : (
                <EditCategoryItem
                  category={category}
                  onFinished={setCategoryIdForEdit}
                  id={id}
                />
              );
              return categories;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function EditCategoryItem({
  category,
  onFinished,
  id,
}: {
  category: CATEGORY;
  onFinished: (index: number) => void;
  id: string;
}) {
  const classes = useStyles();
  const { setCommonFrontendConfiguration, configuration } =
    useConfiguration(id);
  const [categoryAvatarURL, setCategoryAvatarURL] = useState(
    category.categoryAvatarURL
  );

  const {
    categoryState,
    categoryName,
    handleCategoryChange,
    selectedLang,
    categoryURL,
    resetCategory,
  } = useCategory({ category });
  const totalCategoriesLength = configuration?.customerCategories || [];

  const categoryIndex =
    configuration?.customerCategories?.findIndex(
      (item) => item.order === category.order
    ) || 0;

  const [newIndex, setNewIndex] = useState(categoryIndex);

  const storeName = configuration?.storeName;

  const onResetField = () => {
    setCategoryAvatarURL(category.categoryAvatarURL);
    resetCategory();
    setNewIndex(categoryIndex);
  };

  const handleUpdateCategory = () => {
    const oldCategories = configuration?.customerCategories || [];
    const updatedCategory: CATEGORY = {
      categoryName: categoryState.categoryName,
      categoryAvatarURL,
      categoryURL: categoryState.categoryURL,
      order: category.order,
      isPinned: category?.isPinned,
      locale: {
        ...categoryState.locale,
      },
    };
    const categoryRemoved = [
      ...oldCategories.slice(0, categoryIndex),
      ...oldCategories.slice(categoryIndex + 1, oldCategories.length),
    ];
    const categoryReplaced = [
      ...categoryRemoved.slice(0, newIndex),
      updatedCategory,
      ...categoryRemoved.slice(newIndex, categoryRemoved.length),
    ];

    setCommonFrontendConfiguration({
      customerCategories: categoryReplaced,
    });

    onFinished(oldCategories.length * 5);
  };

  const onCanceled = () => {
    onResetField();
    onFinished(totalCategoriesLength.length * 5);
  };

  return (
    <TableRow
      key={category.categoryName}
      className={classes.tableRowRoot}
      style={{ backgroundColor: "rgba(228, 255, 228, 0.7)" }}
    >
      <TableCell
        component="th"
        scope="row"
        className={classes.tableCellStyle}
      ></TableCell>
      <TableCell align="left" className={classes.tableCellStyle}>
        <CustomSelectField
          dropDownIconGapFromRight={10}
          dropDownIconHeight={12}
          dropDownIconWidth={12}
          variant="outlined"
          value={newIndex}
          onChange={(e) => {
            setNewIndex(Number(e.target.value));
          }}
        >
          {configuration?.customerCategories?.map((_, indexValue) => {
            return (
              <MenuItem key={indexValue} value={indexValue}>
                {indexValue + 1}
              </MenuItem>
            );
          })}
        </CustomSelectField>
      </TableCell>
      <TableCell
        component="th"
        style={{ width: "8rem" }}
        scope="row"
        className={classes.tableCellStyle}
      >
        {allowedLanguageKeySwapWithValue[selectedLang]}
        {/* <CustomSelectField
          dropDownIconGapFromRight={10}
          dropDownIconHeight={12}
          dropDownIconWidth={12}
          style={{ width: "7.5rem" }}
          variant="outlined"
          value={selectedLang}
          name="language"
          onChange={handleLangChange}
        >
          <MenuItem value={"default"}>Default language</MenuItem>
          {Object.entries(allowedLanguage).map((entry) => (
            <MenuItem key={entry[0]} value={entry[1]}>
              {entry[0]}
            </MenuItem>
          ))}
        </CustomSelectField> */}
      </TableCell>
      <TableCell align="left" className={classes.tableCellStyle}>
        <CustomTextField
          rows={4}
          labelFor={category.categoryName}
          value={categoryName}
          name="categoryName"
          variant="outlined"
          onChange={handleCategoryChange}
        />
      </TableCell>
      <TableCell align="left" className={classes.tableCellStyle}>
        <Box style={{ marginTop: "-10px" }}>
          <InlineImageUploadWidget
            setImageURLFunction={setCategoryAvatarURL}
            storeName={storeName}
          />
        </Box>
      </TableCell>
      <TableCell
        align="left"
        style={{ width: "30%" }}
        className={classes.tableCellStyle}
      >
        <CustomTextField
          labelFor={category.categoryURL}
          value={categoryURL}
          name="categoryURL"
          variant="outlined"
          onChange={handleCategoryChange}
        />
      </TableCell>
      <TableCell align="left" className={classes.tableCellStyle}>
        <Grid container justify="center" className={classes.iconContainer}>
          <>
            <IconButton aria-label="pin" size="small">
              <ReplayOutlinedIcon onClick={onResetField} />
            </IconButton>
            <IconButton aria-label="edit" size="small">
              <CloseOutlinedIcon onClick={onCanceled} />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={handleUpdateCategory}
            >
              <DoneOutlinedIcon />
            </IconButton>
          </>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

function CategoryList({ category, index, id, onEdit }: ICategory) {
  const classes = useStyles();
  const { selectedLang, categoryName, categoryURL } = useCategory({ category });
  const { configuration, setCommonFrontendConfiguration } =
    useConfiguration(id);
  let categoryPinnedStatus = false;
  const onDelete = (id: number) => {
    const oldCategories = configuration?.customerCategories || [];
    const newCategories = oldCategories.filter(
      (category) => category.order !== id
    );
    setCommonFrontendConfiguration({
      customerCategories: newCategories,
    });
  };

  if (category?.isPinned) {
    categoryPinnedStatus = false;
  } else {
    categoryPinnedStatus = true;
  }

  const onPinChange = (isPinned: boolean) => {
    const categories = configuration?.customerCategories;
    const categoryIndex =
      categories?.findIndex((item) => item.order === category.order) || 0;

    categories![categoryIndex].isPinned = isPinned;

    setCommonFrontendConfiguration({
      customerCategories: categories,
    });
  };

  return (
    <TableRow
      key={category.categoryName}
      className={classes.tableRowRoot}
      style={{
        backgroundColor: `${category?.isPinned ? "#F7F6FE" : "#FFFFFF"}`,
      }}
    >
      <TableCell
        component="th"
        scope="row"
        className={classes.tableCellStyle}
      ></TableCell>
      <TableCell align="left" className={classes.tableCellStyle}>{`#${
        index + 1
      }`}</TableCell>
      <TableCell
        component="th"
        scope="row"
        className={classes.tableCellStyle}
        style={{ width: "8rem" }}
      >
        {/* <CustomSelectField
          style={{ width: "7.5rem" }}
          dropDownIconGapFromRight={10}
          dropDownIconHeight={12}
          dropDownIconWidth={12}
          variant="outlined"
          value={selectedLang}
          name="language"
          onChange={handleLangChange}
        >
          <MenuItem value={"default"}>Default language</MenuItem>
          {Object.entries(allowedLanguage).map((entry) => (
            <MenuItem key={entry[0]} value={entry[1]}>
              {entry[0]}
            </MenuItem>
          ))}
        </CustomSelectField> */}
        {allowedLanguageKeySwapWithValue[selectedLang]}
      </TableCell>
      <TableCell align="left" className={classes.tableCellStyle}>
        {categoryName}
      </TableCell>
      <TableCell align="left" className={classes.tableCellStyle}>
        <Box>
          <img
            src={category.categoryAvatarURL}
            alt={category.categoryAvatarURL}
            className={classes.categoryImage}
          />
        </Box>
      </TableCell>
      <TableCell
        align="left"
        style={{ width: "30%" }}
        className={classes.tableCellStyle}
      >
        <CustomTextField
          labelFor={categoryURL}
          value={categoryURL}
          variant="outlined"
        />
      </TableCell>
      <TableCell align="left" className={classes.tableCellStyle}>
        <Grid container justify="center" className={classes.iconContainer}>
          <>
            <IconButton
              aria-label="pin"
              size="small"
              onClick={() => onPinChange(categoryPinnedStatus)}
            >
              {category?.isPinned ? <UnPinIcon /> : <PinIcon />}
            </IconButton>
            <IconButton aria-label="edit" size="small">
              <EditIcon onClick={onEdit} />
            </IconButton>
            <IconButton aria-label="delete" size="small">
              <TrashIcon onClick={() => onDelete(category.order)} />
            </IconButton>
          </>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
