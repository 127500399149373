import {
  addAuthParameters,
  llmClient,
} from "../../../common/Client/backendClient";
import { User } from "../../../common/UserContext/User";
//[post]v1/stores/{storeId}/ab-test-experiments
export const llmProductDescriptionGenerate = async <
  T,
  K extends Record<string, unknown>
>(
  user: User,
  storeId: string,
  payload: K
): Promise<T> => {
  try {
    const response = await llmClient.post<T>(
      `stores/${storeId}/llm/content-generator?${addAuthParameters(user)}`,
      JSON.stringify({ ...payload, promptType: "product-description" })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
