import { Page } from "../common/Page/Page";
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  NativeSelect,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { DynamicRoutePath } from "../AppRoutes";
import {
  IConfiguration,
  OperationMode,
  newConfigurationId,
} from "../common/ConfigurationsContext/Configuration";
import { useConfigurations } from "../common/ConfigurationsContext/ConfigurationsContext";
import { createUseClasses } from "../common/Theme/createUseClasses";
import { MainContainer } from "../common/Page/MainContainer";
import { Alert } from "@material-ui/lab";
import { Fragment, useEffect, useMemo, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

function applyFilters<T>(data: T[], filters: ((items: T[]) => T[])[]) {
  return filters.reduce((result, filter) => filter(result), data);
}

function filterByOperationMode(status: IConfiguration["operationMode"]) {
  return (configurations: IConfiguration[]) => {
    if (!status) return configurations;
    return configurations.filter((config) => config.operationMode == status);
  };
}

const useDebounceSearchConfigurations = (
  searchInput: string = "",
  configurations: IConfiguration[] = [],
  delayInSeconds = 1
) => {
  const [searchQuery, setSearchQuery] = useState(searchInput);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchQuery(searchInput?.trim());
    }, 1000 * delayInSeconds);
    return () => clearTimeout(timeoutId);
  }, [searchInput, delayInSeconds]);

  const searchedConfiguratons = useMemo(() => {
    return configurations.filter(
      (configuration) =>
        configuration.id?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        configuration.storeName
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())
    );
  }, [searchQuery, configurations.length]);

  return { searchedConfiguratons, searchQuery };
};

type StoresLocationType = ReturnType<typeof useLocation> & {
  state: {
    message?: string;
  } | null;
};
export function Stores() {
  const { loading, configurations } = useConfigurations();
  const { state: locationState } = useLocation() as StoresLocationType;
  const [locationStateSuccessMessage, setLocationStateSuccessMessage] =
    useState(locationState?.message);

  const [selectedOperationMode, setSelectedOperationMode] =
    useState<OperationMode>();

  const filteredConfigurations = useMemo(() => {
    return applyFilters(configurations, [
      filterOutUnmodifiedNewConfiguration,
      filterByOperationMode(selectedOperationMode),
    ]);
  }, [configurations, selectedOperationMode]);

  const [searchInput, setSearchInput] = useState("");
  const { searchedConfiguratons, searchQuery } =
    useDebounceSearchConfigurations(searchInput, filteredConfigurations, 0.5);

  return (
    <Page>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {filteredConfigurations.length === 0 ? (
            <NoStoresDisclaimer />
          ) : filteredConfigurations.length === 1 ? (
            <Navigate
              to={DynamicRoutePath.StoreDashboard(
                filteredConfigurations[0].id || ""
              )}
            />
          ) : (
            <MainContainer>
              <>
                {locationState?.message ? (
                  <Collapse in={Boolean(locationStateSuccessMessage)}>
                    <Alert
                      severity="success"
                      onClose={() => {
                        setLocationStateSuccessMessage("");
                      }}
                    >
                      {locationState.message}
                    </Alert>
                  </Collapse>
                ) : null}
                {/* Filter result store count section */}
                {filteredConfigurations && filteredConfigurations.length ? (
                  <Grid item container>
                    <Typography style={{ fontWeight: 500 }} variant="subtitle1">
                      {" "}
                      Total {selectedOperationMode} Stores:{" "}
                      {filteredConfigurations.length}
                    </Typography>
                  </Grid>
                ) : null}
                {/* Search result message section */}
                <Grid item container>
                  {searchQuery && searchQuery.length ? (
                    <Typography variant="subtitle2">
                      <span style={{ fontWeight: "bold" }}>
                        {searchedConfiguratons.length
                          ? searchedConfiguratons.length
                          : "No"}{" "}
                        {selectedOperationMode}
                      </span>{" "}
                      stores found for search query:{" "}
                      <span
                        style={{ fontWeight: "bold", wordBreak: "break-word" }}
                      >
                        "{searchQuery}"
                      </span>
                    </Typography>
                  ) : null}
                </Grid>
                {/* Filter and search input section */}
                <Grid
                  item
                  container
                  justify="flex-end"
                  spacing={2}
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography variant="button"> Filter By</Typography>
                  </Grid>
                  <Grid item>
                    <NativeSelect
                      variant="outlined"
                      id="filter-by-operation-mode"
                      value={selectedOperationMode}
                      onChange={(e) =>
                        setSelectedOperationMode(
                          e.target.value
                            ? (e.target.value as OperationMode)
                            : undefined
                        )
                      }
                    >
                      <option aria-label="None" value="">
                        None
                      </option>
                      <option value={OperationMode.Production}>
                        {OperationMode.Production} Store
                      </option>
                      <option value={OperationMode.Test}>
                        {OperationMode.Test} Store
                      </option>
                    </NativeSelect>
                  </Grid>
                  {filteredConfigurations &&
                  filteredConfigurations.length > 5 ? (
                    <Grid item>
                      <SearchInput
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onClear={(e) => setSearchInput("")}
                        searchQuery={searchQuery}
                        searchedConfiguratons={searchedConfiguratons}
                      />
                    </Grid>
                  ) : null}
                </Grid>
                {/* Show store list */}
                <StoreList
                  configurations={searchedConfiguratons}
                  searchQuery={searchQuery}
                />
              </>
            </MainContainer>
          )}
          <div></div>
        </>
      )}
    </Page>
  );
}

function StoreList({
  configurations,
  searchQuery = "",
}: {
  configurations: IConfiguration[];
  searchQuery: string;
}) {
  const classes = useClasses();
  return (
    <List className={classes.list}>
      {configurations.map((configuration, index) => (
        <StoreListItem
          key={configuration.id}
          configuration={configuration}
          searchQuery={searchQuery}
        />
      ))}
    </List>
  );
}

function StoreListItem({
  configuration,
  searchQuery = "",
}: {
  configuration: IConfiguration;
  searchQuery: string;
}) {
  const navigate = useNavigate();
  const classes = useClasses();

  return (
    <ListItem
      className={classes.storeListItem}
      button
      divider
      onClick={() =>
        navigate(
          configuration.id === newConfigurationId
            ? DynamicRoutePath.StoreIntegration(configuration.id as string)
            : DynamicRoutePath.StoreMetrics(configuration.id as string)
        )
      }
    >
      <span>
        {getHighlightedText(
          configuration.storeNameFormatted || configuration.storeName,
          searchQuery
        )}{" "}
        {configuration.id === newConfigurationId ? "(Local draft)" : ""}
      </span>
    </ListItem>
  );
}

//Custom SearchInput component for adding search ability
function SearchInput({
  onChange,
  onClear,
  value,
  searchQuery = "",
  searchedConfiguratons = [],
}: {
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClear: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  value: string;
  searchQuery: string;
  searchedConfiguratons: IConfiguration[];
}) {
  return (
    <Grid container direction="column">
      <Grid item container justify="flex-end">
        <Grid item>
          <TextField
            placeholder="Search here"
            inputRef={(input) => input && input.focus()}
            size="small"
            variant="outlined"
            value={value}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <Fragment>
                  {value.length ? (
                    <IconButton onClick={onClear}>
                      <CloseIcon
                        fontSize="small"
                        style={{ cursor: "pointer" }}
                      />
                    </IconButton>
                  ) : (
                    <SearchIcon htmlColor="#7D7C7C" fontSize="small" />
                  )}
                </Fragment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function NoStoresDisclaimer() {
  return (
    <Box m={2}>
      <Typography>
        <p>Welcome to Upsy Dashboard!</p>
        <p>Your account has been created successfully!</p>
        <p>
          To gain access to your store metrics and settings, please email us
          (support@upsyshopping.com) your username (the email address you used
          to create the account) and request us to connect your store with your
          account. We will notify you once the store is connected with your
          account for you to access it.
        </p>
        <br />
        <p>
          If you still haven't purchased Upsy yet and would like to boost up
          your store sales, please contact sales@upsyshopping.com and we will
          contact you shortly!
        </p>
      </Typography>
    </Box>
  );
}

function filterOutUnmodifiedNewConfiguration(configurations: IConfiguration[]) {
  return configurations.filter(
    (configuration) =>
      configuration.id !== newConfigurationId ||
      (configuration.storeName && 0 < configuration.storeName.length)
  );
}

function getHighlightedText(text: string = "", highlight: string = "") {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: 800, color: "#6487e0" }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
}

const useClasses = createUseClasses((theme: Theme) => ({
  list: {
    display: "flex",
    maxWidth: "824px",
    gap: "8px",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      width: "min(100%, 204px)",
    },
  },
  storeListItem: {
    flex: "0 1 200px",
    border: "3px solid #B2C8FF",
    borderRadius: "5px",
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: "20px",
  },
}));
