import {
  defaultAvatar,
  defaultButtonColor,
  defaultAccentColorLight,
  defaultLanguage,
  defaultOperationModeFE,
  IConfiguration,
  IConfigurationCommon,
  IConfigurationCommonFrontend,
  IWooCommerceConfiguration,
  Language,
  OperationMode,
  OperationModeFE,
  Platform,
  WooCommerceApiVersion,
  defaultGreeting,
} from "./Configuration";
import {
  FetchShopConfigurationsResponse,
  IConfigurationCommonFrontendDynamoDB,
} from "../Client/BackendResponse";
import { FAQType } from "../../Stores/StoreSettings/FAQs";

const wooCommerceApiVersionRegex = /wc\/(v\d)\/products/g;
const parseWooCommerceApiVersion = (path: string): WooCommerceApiVersion => {
  let result;

  const match = wooCommerceApiVersionRegex.exec(path);
  if (match && match.length >= 2)
    result =
      WooCommerceApiVersion[match[1] as keyof typeof WooCommerceApiVersion];

  return result || WooCommerceApiVersion.v3;
};

const transformCommonProperties = (
  shopConfiguration: FetchShopConfigurationsResponse
): Omit<IConfigurationCommon, "platform"> => ({
  id: shopConfiguration.backendConfiguration.id,
  storeName: shopConfiguration.backendConfiguration.tenantId,
  storeNameFormatted: shopConfiguration.backendConfiguration.tenantId
    .replace(/^(ups-)/, "")
    .split("")
    .map((letter, i) => (i === 0 ? letter.toLocaleUpperCase() : letter))
    .join(""), //Capitalize first letter
  storeUrl: shopConfiguration.backendConfiguration.urlBase,
  ...transformCommonFrontendProperties(shopConfiguration.frontendConfiguration),
});

const transformOperationMode = (operationMode: OperationModeFE) => {
  return operationMode === OperationModeFE.Production
    ? OperationMode.Production
    : operationMode === OperationModeFE.Test
    ? OperationMode.Test
    : OperationMode.Production;
};

const transformCommonFrontendProperties = ({
  testMode,
  language,
  avatar,
  buttonColor,
  flows,
  customQnA,
  customerCategories,
  widgetToLeft,
  minimizedMode,
  accentColorLight,
  newArrivalTimeLimit,
  isNewArrivalTabDisabled,
  isDailyDealsDisabled,
  activePromotion,
  greetings,
  activeExperimentId,
  experiments,
}: Partial<IConfigurationCommonFrontendDynamoDB>): IConfigurationCommonFrontend => ({
  operationMode: transformOperationMode(testMode ?? defaultOperationModeFE),
  language: language ?? defaultLanguage,
  avatar: avatar ?? defaultAvatar,
  buttonColor: buttonColor ?? defaultButtonColor,
  accentColorLight: accentColorLight ?? defaultAccentColorLight,
  newArrivalTimeLimit: newArrivalTimeLimit ? newArrivalTimeLimit : null,
  isNewArrivalTabDisabled: isNewArrivalTabDisabled
    ? isNewArrivalTabDisabled
    : false,
  isDailyDealsDisabled: isDailyDealsDisabled ? isDailyDealsDisabled : false,
  activePromotion: activePromotion ? activePromotion : "",
  widgetToLeft: widgetToLeft ?? false,
  minimizedMode: minimizedMode ?? false,
  flows: flows ?? [],
  greetings: greetings ?? defaultGreeting,
  customerCategories: customerCategories ?? [],
  customQnA:
    (customQnA &&
      customQnA.map((qna: any) =>
        qna.url
          ? { ...qna, answer: qna.url, type: FAQType.Url }
          : !qna.type
          ? { ...qna, type: FAQType.Text }
          : qna
      )) ??
    [],
  activeExperimentId: activeExperimentId,
  experiments: experiments ?? [],
});

const transformToWooCommerceConfiguration = (
  shopConfiguration: FetchShopConfigurationsResponse
): IWooCommerceConfiguration => ({
  ...transformCommonProperties(shopConfiguration),
  platform: Platform.WooCommerce,
  apiVersion: parseWooCommerceApiVersion(
    shopConfiguration.backendConfiguration.productUrlPath || ""
  ),
  authStrategy: shopConfiguration.backendConfiguration.authStrategy || "",
  key: shopConfiguration.backendConfiguration.consumerKey || "",
  secret: shopConfiguration.backendConfiguration.consumerSecret || "",
  woocommerceLanguage:
    (shopConfiguration.backendConfiguration.language as Language) || "",
});

const transformToGenericConfiguration = (
  shopConfiguration: FetchShopConfigurationsResponse
): IConfiguration => ({
  ...transformCommonProperties(shopConfiguration),
  platform: shopConfiguration.backendConfiguration.type,
});

interface TransformationSpec {
  transform(shopConfiguration: FetchShopConfigurationsResponse): IConfiguration;
}

interface TransformationSpecByPlatformType {
  [key: string]: TransformationSpec;
}

export const dashboardTransformationByType: TransformationSpecByPlatformType = {
  [Platform.WooCommerce]: {
    transform: transformToWooCommerceConfiguration,
  },
  default: {
    transform: transformToGenericConfiguration,
  },
};
