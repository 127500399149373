import { FormControl, Grid, MenuItem, Typography } from "@material-ui/core";
import { SettingsWizardPageProps } from "./SettingsWizardPage";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import GreetingTab from "./GreetingTab";
import CustomSelectField from "../../common/CustomUiComponent/Select";
import { ALLOWED_LANGUAGE, allowedLanguage } from "../../Configs/language";
import React, { useState } from "react";
import { GreetingLanguageContext } from "./GrettingLangContext";

export function CommonFrontendGreeting({ id }: SettingsWizardPageProps) {
  const [selectedLang, setSelectedLang] = useState<
    ALLOWED_LANGUAGE | "default"
  >("default");

  const handleLangChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setSelectedLang(e.target.value as ALLOWED_LANGUAGE);
  };

  const classes = useClasses();
  return (
    <>
      <Grid
        container
        item
        classes={{ root: classes.gridContainerRootStyle }}
        xs={12}
        md={12}
        lg={12}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={classes.avatarContainer}
        >
          <Typography className={classes.typographyForTitle}>
            Greetings settings
          </Typography>
          <Typography className={classes.typographyForSubTitle}>
            UPSY widget shows greetings on every page. You can change these
            messages or use them with the default setting.
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ maxWidth: 655, marginLeft: -10 }}>
            <FormControl>
              <CustomSelectField
                value={selectedLang}
                labelFor="language"
                labelText="Language"
                placeholder="Language"
                variant="outlined"
                name="language"
                fullWidth
                onChange={handleLangChange}
              >
                <MenuItem value={"default"}>Default language</MenuItem>
                {Object.entries(allowedLanguage).map((entry) => (
                  <MenuItem key={entry[0]} value={entry[1]}>
                    {entry[0]}
                  </MenuItem>
                ))}
              </CustomSelectField>
            </FormControl>
          </Grid>
        </Grid>
        <GreetingLanguageContext.Provider
          value={{ lang: selectedLang, setLang: setSelectedLang }}
        >
          <GreetingTab id={id} />
        </GreetingLanguageContext.Provider>
      </Grid>
    </>
  );
}

const useClasses = createUseClasses((theme) => ({
  avatarContainer: {
    marginLeft: "5px",
    marginBottom: "10px",
  },
  typographyForTitle: {
    fontWeight: 700,
    fontSize: "20px",
    letter: "4%",
    letterHeight: "24px",
    letterSpacing: "0.04px",
    paddingBottom: "10px",
    color: "#202F4E",
  },
  typographyForSubTitle: {
    fontWeight: 500,
    fontSize: "16px",
    letter: "2%",
    letterHeight: "19.36px",
    color: "#636364",
  },
  gridItemRoot: {
    "&.MuiGrid-item": {
      padding: "4px",
    },
  },
  gridContainerRootStyle: {
    borderRadius: "15px",
    padding: "10px",
  },
}));
