import * as React from "react";
import { ReactNode } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export function Theme({ children }: { children: ReactNode }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
}

const overrides: Overrides = {
  MuiButton: {
    root: {
      textTransform: "inherit",
    },
  },
  MuiFormControl: {
    root: {
      width: "100%",
    },
  },
  MuiLink: {
    root: {
      color: "#364F83",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 500,
    },
  },
  MuiTextField: {
    root: {
      "& .MuiOutlinedInput-root": {
        background: "#ffffff",
        borderRadius: "6px",
        "& fieldset": {
          borderColor: "#6793FF",
        },
        "& input": {
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "19px",
          color: "#808081",
        },
        "&:hover fieldset": {
          borderColor: "#6793FF",
          borderWidth: "2px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#6793FF",
        },
      },
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: "0px 4px 12px 0px rgba(149, 157, 165, 0.18)",
    },
  },
};

const palette: PaletteOptions = {
  primary: {
    main: "#364F83",
  },
  secondary: {
    main: "#E26179",
  },
  text: {
    primary: "#202F4E",
    secondary: "#636364",
  },
  background: {
    default: "#FFF",
    paper: "#F7F9FF",
  },
};

const typography: TypographyOptions = {
  fontFamily: '"Inter","Montserrat", sans-serif',
  h4: {
    // Main heading
    fontFamily: '"Inter","Montserrat", sans-serif',
  },
};

const theme = createMuiTheme({
  overrides,
  palette,
  typography,
});
