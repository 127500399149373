import * as React from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { LoginCredentials } from "../common/LoginCredentials/LoginCredentials";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { StaticRoutePath } from "../AppRoutes";
import { Grid, Typography, Link } from "@material-ui/core";
import { Title } from "../common/Title/Title";
import { AuthWrapper } from "../common/AuthWrapper/AuthWrapper";

export function Login() {
  const { user, logIn } = useUserContext();

  if (user) {
    return <Navigate to={StaticRoutePath.Root} />;
  }

  return (
    <AuthWrapper>
      <Grid item container xs={12} alignItems="center" spacing={1}>
        <Grid item xs={12} style={{ marginBottom: "18px" }}>
          <Title>Login</Title>
        </Grid>
        <Grid item xs={12} justify="center">
          <LoginCredentials submitButtonTitle="Login" onSubmit={logIn} />
        </Grid>
        <Grid item justify="center">
          <Typography variant="body2">
            Don’t have an account yet?
            <Link component={RouterLink} to={StaticRoutePath.Register}>
              {" "}
              Register now
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
