import { createSvgIcon } from "@material-ui/core";

export const AIIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 98 100"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      d="M13.8846 0.00582383H69.6192C74.2133 0.00582383 78.3886 1.8839 81.4144 4.90983C84.4403 7.93558 86.3184 12.1109 86.3184 16.705V67.8045V69.5641H84.5588H13.8845H12.125V67.8045V1.76526V0.0057373L13.8846 0.00582383ZM69.6192 3.52488H15.6441V66.0451H82.7994V16.7051C82.7994 13.0824 81.3161 9.78766 78.9264 7.39802C76.5367 5.00827 73.2419 3.52488 69.6192 3.52488Z"
      fill="currentColor"
    />
    <path
      d="M95.7386 100.006H2.54078H0.78125V98.2462V67.8046V66.045H2.54078H95.7386H97.4981V67.8046V98.2462V100.006H95.7386ZM4.30031 96.4866H93.9791V69.5641H4.30031V96.4866Z"
      fill="currentColor"
    />
    <path
      d="M24.4922 21.385H73.8263V24.9041H24.4922V21.385Z"
      fill="currentColor"
    />
    <path
      d="M24.4922 33.7567H73.8263V37.2758H24.4922V33.7567Z"
      fill="currentColor"
    />
    <path
      d="M24.4922 46.1284H73.8263V49.6475H24.4922V46.1284Z"
      fill="currentColor"
    />
    <path
      d="M48.3987 89.1418H41.9701L41.0856 92.1562H35.3203L42.1826 73.8949H48.3349L55.2015 92.1562H49.2958L48.3987 89.1418ZM47.2167 85.1918L45.1971 78.6271L43.1903 85.1918H47.2167ZM57.2295 73.8949H62.8844V92.1562H57.2295V73.8949Z"
      fill="currentColor"
    />
  </svg>,
  "AIIcon"
);
