import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#202F4E",
    paddingBottom: "10px",
  },
  buttonFormatting: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formControllerText: {
    marginTop: "0px",
    color: "#C60101",
    fontSize: "14px",
    fontStyle: "italic",
  },
  errorMessage: {
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
  },
  defaultButtonStyle: {
    textTransform: "capitalize",
    marginRight: "15px",
  },
  lineBreakStyle: {
    height: "30px",
  },
}));

export default useStyles;
