import { Button } from "@material-ui/core";
import { ButtonGroupPageProps } from "../SettingsWizardPage";
import useStyles from "./style";

export default function ButtonGroup({
  submithandler,
  defaultHandler,
}: ButtonGroupPageProps) {
  const classes = useStyles();
  return (
    <div style={{ marginTop: "20px" }}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.defaultButtonStyle}
        onClick={defaultHandler}
      >
        Default
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ textTransform: "capitalize" }}
        onClick={submithandler}
      >
        Save
      </Button>
    </div>
  );
}
