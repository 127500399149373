import { createDefaultFAQ, FAQType, IFAQ } from "./FAQs";

export enum FAQsActionType {
  Init = "Init",
  SetQuestionText = "SetQuestionText",
  SetAnswerText = "SetAnswerText",
  SetAnswerUrl = "SetAnswerText",
  AddFAQ = "AddFAQ",
  AddFAQWithPayload = "AddFAQWithPayload",
  UpdateFAQWithPayload = "UpdateFAQWithPayload",
  SetFaqPined = "SetFaqPined",
  RemoveFAQ = "RemoveFAQ",
  SetFAQType = "SetFAQType",
  SetFAQRank = "SetFAQRank",
  SetBulkFAQ = "SetBulkFAQ",
  removeBulkFAQ = "RemoveBulkFAQ",
}

interface InitAction {
  type: FAQsActionType.Init;
}

export function init(): InitAction {
  return {
    type: FAQsActionType.Init,
  };
}
interface SetFAQTypeAction {
  type: FAQsActionType.SetFAQType;
  index: number;
  FAQType: FAQType;
}

export function setFAQType(index: number, FAQType: FAQType): SetFAQTypeAction {
  return {
    type: FAQsActionType.SetFAQType,
    index,
    FAQType,
  };
}

interface SetFAQRankAction {
  type: FAQsActionType.SetFAQRank;
  index: number;
  newIndex: number;
}

export function setFAQRank(index: number, newIndex: number): SetFAQRankAction {
  return {
    type: FAQsActionType.SetFAQRank,
    index,
    newIndex: newIndex,
  };
}
interface SetQuestionTextAction {
  type: FAQsActionType.SetQuestionText;
  index: number;
  text: string;
}

export function setQuestionText(
  index: number,
  text: string
): SetQuestionTextAction {
  return {
    type: FAQsActionType.SetQuestionText,
    index,
    text,
  };
}

interface SetAnswerTextAction {
  type: FAQsActionType.SetAnswerText;
  index: number;
  text: string;
}

export function setAnswerText(
  index: number,
  text: string
): SetAnswerTextAction {
  return {
    type: FAQsActionType.SetAnswerText,
    index,
    text,
  };
}

interface AddFAQAction {
  type: FAQsActionType.AddFAQ;
}

export function addFAQ(): AddFAQAction {
  return {
    type: FAQsActionType.AddFAQ,
  };
}

interface AddFAQWithPayLoadAction {
  type: FAQsActionType.AddFAQWithPayload;
  faq: IFAQ;
  oldIndex: number;
  newIndex: number;
}

// add and save faq all field once
export function AddFAQWithPayload(
  oldIndex: number,
  newIndex: number,
  faq: IFAQ
): AddFAQWithPayLoadAction {
  return {
    type: FAQsActionType.AddFAQWithPayload,
    oldIndex,
    newIndex,
    faq,
  };
}

// update faq all field once
interface UpdateFAQWithPayloadAction {
  type: FAQsActionType.UpdateFAQWithPayload;
  faq: IFAQ;
  oldIndex: number;
  newIndex: number;
}

export function UpdateFAQWithPayload(
  oldIndex: number,
  newIndex: number,
  faq: IFAQ
): UpdateFAQWithPayloadAction {
  return {
    type: FAQsActionType.UpdateFAQWithPayload,
    oldIndex,
    newIndex,
    faq,
  };
}
interface RemoveFAQAction {
  type: FAQsActionType.RemoveFAQ;
  index: number;
}

export function removeFAQ(index: number): RemoveFAQAction {
  return {
    type: FAQsActionType.RemoveFAQ,
    index,
  };
}

interface AddBulkFAQAction {
  type: FAQsActionType.SetBulkFAQ;
  faqs: IFAQ[];
}

export function addBulkFAQ(faqs: IFAQ[]): AddBulkFAQAction {
  return {
    type: FAQsActionType.SetBulkFAQ,
    faqs: faqs,
  };
}

export interface IMapIndexForFAQDelete {
  deleteFrom: number;
  deleteCount: number;
}

interface RemoveBulkFAQAction {
  type: FAQsActionType.removeBulkFAQ;
  mapIndexForFaqDelete: IMapIndexForFAQDelete;
}

export function removeBulkFAQ(
  mapIndexForFaqDelete: IMapIndexForFAQDelete
): RemoveBulkFAQAction {
  return {
    type: FAQsActionType.removeBulkFAQ,
    mapIndexForFaqDelete,
  };
}
// add isPined boolean field to check either this is pined item or not
export interface SetFaqPinedAction {
  type: FAQsActionType.SetFaqPined;
  index: number;
  isPined: boolean;
}

export function setFaqPined(
  index: number,
  isPined: boolean
): SetFaqPinedAction {
  return {
    type: FAQsActionType.SetFaqPined,
    index,
    isPined,
  };
}

export type FAQsAction =
  | InitAction
  | SetQuestionTextAction
  | SetAnswerTextAction
  | SetFAQTypeAction
  | SetFAQRankAction
  | AddFAQAction
  | AddFAQWithPayLoadAction
  | UpdateFAQWithPayloadAction
  | SetFaqPinedAction
  | RemoveFAQAction
  | AddBulkFAQAction
  | RemoveBulkFAQAction;

const removeFaqs = function removeBulkFaq(
  state: IFAQ[],
  mapIndexToFaqDelete: IMapIndexForFAQDelete
) {
  let copyState = [...state];
  copyState.splice(
    mapIndexToFaqDelete.deleteFrom,
    mapIndexToFaqDelete.deleteCount
  );
  return [...copyState];
};

function faqReRank(state: IFAQ[], oldIndex: number, newIndex: number): IFAQ[] {
  const question = state[oldIndex];
  const questionRemoved = [
    ...state.slice(0, oldIndex),
    ...state.slice(oldIndex + 1, state.length),
  ];
  const questionReplaced = [
    ...questionRemoved.slice(0, newIndex),
    question,
    ...questionRemoved.slice(newIndex, questionRemoved.length),
  ];
  console.log(question, questionRemoved, questionReplaced);
  return questionReplaced;
}

export function FAQsReducer(
  state: IFAQ[] = [], //flowFAQsReducerInitialStateNew,
  action: FAQsAction
): IFAQ[] {
  switch (action.type) {
    case FAQsActionType.SetFaqPined: {
      return state.map((FAQ, index) =>
        index === action.index
          ? {
              ...FAQ,
              isPinded: action.isPined,
            }
          : FAQ
      );
    }

    case FAQsActionType.UpdateFAQWithPayload: {
      const { oldIndex, newIndex, faq } = action;
      const newState = state.map((FAQ, index) =>
        index === oldIndex ? { ...FAQ, ...faq } : FAQ
      );
      return oldIndex === newIndex
        ? newState
        : faqReRank(newState, oldIndex, newIndex);
    }
    case FAQsActionType.SetQuestionText: {
      return state.map((FAQ, index) =>
        index === action.index
          ? {
              ...FAQ,
              question: action.text,
            }
          : FAQ
      );
    }
    case FAQsActionType.SetAnswerText: {
      return state.map((FAQ, index) =>
        index === action.index
          ? {
              ...FAQ,
              answer: action.text,
            }
          : FAQ
      );
    }
    case FAQsActionType.SetFAQType: {
      return state.map((FAQ, index) =>
        index === action.index
          ? {
              ...FAQ,
              type: action.FAQType,
            }
          : FAQ
      );
    }
    case FAQsActionType.SetFAQRank: {
      const oldIndex = action.index;
      const { newIndex } = action;
      return faqReRank(state, oldIndex, newIndex);
    }
    case FAQsActionType.AddFAQ: {
      return [...state, { ...createDefaultFAQ(), originalIndex: state.length }];
    }
    case FAQsActionType.AddFAQWithPayload: {
      const { oldIndex, newIndex, faq } = action;
      const newState = [...state, { ...faq }];
      return oldIndex === newIndex
        ? newState
        : faqReRank(newState, oldIndex, newIndex);
    }

    case FAQsActionType.RemoveFAQ: {
      return state.filter((FAQ, index) => index !== action.index);
    }
    case FAQsActionType.SetBulkFAQ: {
      let faqs = action.faqs.map((faq, index) => {
        const originalIndex = faq.originalIndex || index;
        return {
          ...faq,
          originalIndex: state.length + originalIndex,
        };
      });
      return [...state, ...faqs];
    }
    case FAQsActionType.removeBulkFAQ: {
      return removeFaqs(state, action.mapIndexForFaqDelete);
    }
    case FAQsActionType.Init:
    default:
      return state;
  }
}
