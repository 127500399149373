import { useState, useEffect } from "react";

export type ValidationErrors<T> = {
  [K in keyof T]?: string;
};

export type DirtyFields<T> = {
  [K in keyof T]?: boolean;
};

const useStateWithValidation = <T extends {}>(
  initialState: T,
  validate: (state: T) => ValidationErrors<T>
) => {
  const [state, setState] = useState<T>(initialState);
  const [errors, setErrors] = useState<ValidationErrors<T>>({});
  const [isDirty, setIsDirty] = useState<DirtyFields<T>>({});
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    const validationErrors = validate(state);
    setErrors(validationErrors);
    const isFormValid =
      Object.keys(validationErrors).length === 0 &&
      Object.values(isDirty).every((dirty) => dirty);
    setIsValid(isFormValid);
  }, [state, validate, isDirty]);

  const update = <K extends keyof T>(name: K, value: T[K], isDirty = true) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setIsDirty((prevState) => ({
      ...prevState,
      [name]: isDirty,
    }));
  };

  return {
    state,
    errors,
    isValid,
    update,
    isDirty,
  };
};

export default useStateWithValidation;
