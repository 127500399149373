import { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  MobileStepper,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { IConfiguration } from "../../common/ConfigurationsContext/Configuration";
import {
  useCommonBackendConfigurationValidator,
  useConfiguration,
  useConfigurations,
  useConfigurationTester,
} from "../../common/ConfigurationsContext/ConfigurationsContext";
import { CommonFrontendConfiguration } from "./CommonFrontendConfiguration";
import { SettingsSummary } from "./SettingsSummary";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import {
  SettingsWizardCommonPageType,
  SettingsWizardPageProps,
  SettingsWizardPagesByType,
  SettingsWizardPageType,
  fullSettingsWizardPages,
} from "./SettingsWizardPage";
import { LoadingButton } from "../../common/LoadingButton/LoadingButton";
import { SettingsSuccess } from "./SettingsSuccess";
import { CommonFrontendFAQs } from "./CommonFrontendFAQs";
import { CustomStepper } from "../../common/CustomeStepper";
import { TopContainer } from "../../common/Page/TopContainer";
import { MainContainer } from "../../common/Page/MainContainer";
import { CommonFrontendCategories } from "./CommonFrontendCategories";
import { Promotions } from "./PromotionsConfiguration";
import { CommonFrontendGreeting } from "./CommonFrontendGreeting";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import {
  PageIndexProvider,
  useCurrentPageIndex,
  useNextButtonDisableIndex,
} from "../PageIndexProvider";
function getStepperLabelsFromPageTypes(
  configurationsPages: SettingsWizardPageType[]
) {
  return configurationsPages
    .map((pageType) => {
      switch (pageType) {
        case SettingsWizardCommonPageType.CommonFrontend:
          return "Styling";
        case SettingsWizardCommonPageType.CommonFrontendFAQs:
          return "FAQs";
        case SettingsWizardCommonPageType.Summary:
          return "Summary";
        case SettingsWizardCommonPageType.Categories:
          return "Categories";
        case SettingsWizardCommonPageType.Greetings:
          return "Greetings";
        case SettingsWizardCommonPageType.Promotions:
          return "Promotions";
        default:
          return "";
      }
    })
    .filter((item) => item);
}

export function SettingsWizard({ id }: { id: string }) {
  const { configuration } = useConfiguration(id);
  const platform = configuration?.platform;
  const SettingsWizardPages = platform
    ? SettingsWizardPagesByType[platform]
    : fullSettingsWizardPages;
  const [pageType, setPageType] = useState<SettingsWizardPageType>(
    SettingsWizardPages[0]
  );
  const [steps, setSteps] = useState<string[]>([]);

  useEffect(() => {
    setSteps((prev) => [...getStepperLabelsFromPageTypes(SettingsWizardPages)]);
  }, []);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [isSaveSucceed, setIsSaveSucceed] = useState(false);

  return (
    <>
      <TopContainer style={{ paddingBottom: "16px" }}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <CustomStepper steps={steps} activeStep={activeStep} />
          </Grid>
        </Grid>
      </TopContainer>
      <MainContainer>
        <Collapse in={isSaveSucceed} style={{ marginBottom: 20 }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsSaveSucceed(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {"Thank you! setting configurations Saved successfully"}
          </Alert>
        </Collapse>
        <PageIndexProvider>
          <SettingsWizardPage
            id={id}
            pageType={pageType}
            setPageType={setPageType}
          />
          <SettingsWizardNavigation
            id={id}
            pageType={pageType}
            setPageType={setPageType}
            SettingsWizardPages={SettingsWizardPages}
            setActiveStep={setActiveStep}
            setIsSaveSucceed={setIsSaveSucceed}
          />
        </PageIndexProvider>
      </MainContainer>
    </>
  );
}

function SettingsWizardPage({
  id,
  pageType,
  setPageType,
}: SettingsWizardPageProps & {
  pageType: SettingsWizardPageType;
  setPageType: (pageType: SettingsWizardPageType) => void;
}) {
  switch (pageType) {
    case SettingsWizardCommonPageType.CommonFrontend:
      return <CommonFrontendConfiguration id={id} />;
    case SettingsWizardCommonPageType.CommonFrontendFAQs:
      return <CommonFrontendFAQs id={id} />;
    case SettingsWizardCommonPageType.Categories:
      return <CommonFrontendCategories id={id} />;
    case SettingsWizardCommonPageType.Promotions:
      return <Promotions id={id} />;
    case SettingsWizardCommonPageType.Greetings:
      return <CommonFrontendGreeting id={id} />;
    case SettingsWizardCommonPageType.Summary:
      return <SettingsSummary id={id} />;
    case SettingsWizardCommonPageType.Success:
      return <SettingsSuccess id={id} />;
    default:
      return null;
  }
}

const isIgnoreErrorBoxEnabled: boolean =
  process.env.REACT_APP_ENABLE_IGNORE_TEST_ERROR_BOX === "true";

function SettingsWizardNavigation({
  id,
  pageType,
  setPageType,
  setActiveStep,
  SettingsWizardPages,
  setIsSaveSucceed,
}: SettingsWizardPageProps & {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setIsSaveSucceed: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: SettingsWizardPageType;
  setPageType: (pageType: SettingsWizardPageType) => void;
  SettingsWizardPages: SettingsWizardPageType[];
}) {
  const { configuration, configurationError } = useConfiguration(id);
  const { testingConfiguration, testingError, testBackendShopConfiguration } =
    useConfigurationTester(configuration);

  const {
    commonBackendConfigurationValidationError,
    validateCommonBackendConfiguration,
  } = useCommonBackendConfigurationValidator(configuration);

  const classes = useNavigationButtonsClasses();
  const [ignoreError, setIgnoreError] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const minIndex = 0;
  const isSummaryPage = pageType === SettingsWizardCommonPageType.Summary;
  const isSuccessPage = pageType === SettingsWizardCommonPageType.Success;
  const { updateCurrentPageIndex } = useCurrentPageIndex();

  useEffect(() => {
    setActiveStep(pageIndex);
  }, [pageIndex, setActiveStep]);

  const moveToPage = (pageIndex: number) => {
    setPageIndex(pageIndex);
    updateCurrentPageIndex(pageIndex);
    const pageType = SettingsWizardPages[pageIndex];
    setPageType(pageType);
  };

  function previous() {
    if (minIndex < pageIndex) {
      moveToPage(pageIndex - 1);
      updateCurrentPageIndex(pageIndex - 1);
    }
  }

  return (
    <Grid container item xs={12} style={{ marginTop: "80px" }}>
      {!isSuccessPage && (
        <Grid item xs={12}>
          <MobileStepper
            classes={{ root: classes.mobileStepper }}
            className={classes.buttonsContainer}
            backButton={
              <Button
                variant="outlined"
                color="primary"
                disabled={
                  minIndex === pageIndex ||
                  isSuccessPage ||
                  testingConfiguration
                }
                onClick={previous}
              >
                Previous
              </Button>
            }
            nextButton={
              <ProceedButton
                id={id}
                pageType={pageType}
                pageIndex={pageIndex}
                moveToPage={moveToPage}
                testingConfiguration={testingConfiguration}
                testBackendShopConfiguration={testBackendShopConfiguration}
                validateCommonBackendConfiguration={
                  validateCommonBackendConfiguration
                }
                ignoreError={ignoreError}
                SettingsWizardPages={SettingsWizardPages}
                setIsSaveSucceed={setIsSaveSucceed}
              />
            }
            steps={SettingsWizardPages.length}
            activeStep={pageIndex}
            position="static"
          />
        </Grid>
      )}
      {isSummaryPage && configurationError && (
        <Grid item xs={12}>
          <Alert className={classes.alert} severity="error">
            {configurationError}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}

function ProceedButton({
  id,
  pageType,
  pageIndex,
  moveToPage,
  testingConfiguration,
  ignoreError,
  testBackendShopConfiguration,
  validateCommonBackendConfiguration,
  setIsSaveSucceed,
  SettingsWizardPages,
}: SettingsWizardPageProps & {
  pageType: SettingsWizardPageType;
  pageIndex: number;
  moveToPage: (index: number) => void;
  testingConfiguration: boolean;
  ignoreError: boolean;
  testBackendShopConfiguration: (successCallback: () => void) => void;
  validateCommonBackendConfiguration: (successCallback: () => void) => void;
  SettingsWizardPages: SettingsWizardPageType[];
  setIsSaveSucceed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const maxIndex = SettingsWizardPages.length - 1;
  const isSummaryPage = pageType === SettingsWizardCommonPageType.Summary;
  const isSuccessPage = pageType === SettingsWizardCommonPageType.Success;
  const { configuration, saveConfiguration, clearTempConfiguration } =
    useConfiguration(id);
  const { loading } = useConfigurations();
  const classes = useProceedButtonClasses();

  const { nextBtnDisableForIndex } = useNextButtonDisableIndex();

  const isNexButtonDisable = nextBtnDisableForIndex == pageIndex;

  const navigate = useNavigate();
  function next() {
    if (isNexButtonDisable) return;
    if (pageIndex < maxIndex) {
      moveToPage(pageIndex + 1);
    }
  }
  function handleCancelButton() {
    navigate("/stores");
  }

  const ConditionalButton = isSummaryPage ? (
    <LoadingButton
      classes={{ root: classes.root }}
      loading={loading}
      loadingText="Saving..."
      buttonText="Save and Publish"
      onClick={async () => {
        try {
          await saveConfiguration(configuration as IConfiguration);
          clearTempConfiguration();
          setIsSaveSucceed(true);
          moveToPage(0);
        } catch (err) {}
      }}
    />
  ) : isSuccessPage ? null : (
    <Button
      classes={{ root: classes.root }}
      variant="contained"
      color="primary"
      onClick={next}
      disabled={isNexButtonDisable}
    >
      {"Save & Next"}
    </Button>
  );

  return (
    <Grid container item justify="flex-end" style={{ gap: "20px" }}>
      <Button style={{ marginRight: "15px" }} onClick={handleCancelButton}>
        {"Cancel"}
      </Button>
      {ConditionalButton}
    </Grid>
  );
}

const useNavigationButtonsClasses = createUseClasses((theme) => ({
  alert: {
    whiteSpace: "pre-wrap",
  },
  buttonsContainer: {
    padding: `${theme.spacing(2)}px 0`,
    alignItems: "flex-end",
  },
  mobileStepper: {
    "& .MuiMobileStepper-dots": {
      display: "none",
      width: 0,
      opacity: 0,
    },
  },
}));
const useProceedButtonClasses = createUseClasses((theme) => ({
  root: {
    borderRadius: "8px",
    border: "1px solid #6793FF",
  },
}));
