import { createUseClasses } from "../../../../common/Theme/createUseClasses";

export type DividerWithContentProps = {
  thickness?: number | string;
  contentColor?: string;
  dividerColor?: string;
  children: React.ReactNode;
};

export type DividerWithContentClassesProps = Omit<
  DividerWithContentProps,
  "children"
>;
export function DividerWithContent({
  thickness,
  dividerColor,
  contentColor,
  children,
}: DividerWithContentProps) {
  const classes = useClasses({ dividerColor, contentColor, thickness });
  return (
    <div className={classes.dividerWithContent}>
      <div className="content">{children}</div>
    </div>
  );
}

const useClasses = createUseClasses((theme) => ({
  dividerWithContent: (props: DividerWithContentClassesProps) => {
    return {
      display: "flex",
      alignItems: "center",
      width: "100%",
      flexWrap: "nowrap",
      "& .content": {
        marginInline: "10px",
        color: props.contentColor ? props.contentColor : "rgba(0, 0, 0, 0.6)",
        fontWeight: "bold",
      },
      " &::before, &::after": {
        width: "100%",
        backgroundColor: props.dividerColor
          ? props.dividerColor
          : "rgba(0, 0, 0, 0.12)",
        height: props.thickness ? props.thickness : "1px",
        content: '""',
      },
    };
  },
}));
