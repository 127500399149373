import * as React from "react";
import { useState } from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { StaticRoutePath } from "../AppRoutes";
import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, TextField, Typography, Link } from "@material-ui/core";
import { Title } from "../common/Title/Title";
import { AuthWrapper } from "../common/AuthWrapper/AuthWrapper";
import StyledLabel from "../common/StyledLabel/StyledLabel";

export function ForgotPassword() {
  const { getPasswordRecoveryCode, recoverPassword } = useUserContext();
  const [email, setEmail] = useState("");
  const [codeRequested, setCodeRequested] = useState(false);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordRecovered, setPasswordRecovered] = useState(false);
  const [submitError, setSubmitError] = useState<undefined | string>();

  async function getRecoveryCode() {
    try {
      const success = await getPasswordRecoveryCode(email);
      if (success) {
        setCodeRequested(true);
      }
    } catch (error) {
      setSubmitError((error as { message?: string }).message);
    }
  }

  async function recover() {
    try {
      const success = await recoverPassword(email, code, newPassword);
      if (success) {
        setPasswordRecovered(true);
      }
    } catch (error) {
      setSubmitError((error as { message?: string }).message);
    }
  }

  return (
    <AuthWrapper>
      <Grid container item xs={12} alignItems="center" spacing={1}>
        <Grid item xs={12} style={{ marginBottom: "18px" }}>
          <Title>Forgot Password</Title>
        </Grid>
        {passwordRecovered ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">
                Your password was succesfully reset!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link component={RouterLink} to={StaticRoutePath.Login}>
                Login from here
              </Link>
            </Grid>
          </>
        ) : codeRequested ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">Check your email: {email}</Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledLabel>Code</StyledLabel>
              <TextField
                size="small"
                placeholder="Code"
                value={code}
                variant="outlined"
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledLabel>New Password</StyledLabel>
              <TextField
                size="small"
                variant="outlined"
                placeholder="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={recover}
              >
                Set new password
              </Button>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <p>{submitError}</p>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <StyledLabel>Email</StyledLabel>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={getRecoveryCode}
              >
                Recover password
              </Button>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <p>{submitError}</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <Link component={RouterLink} to={StaticRoutePath.Login}>
                Login from here
              </Link>
            </Grid>
          </>
        )}
      </Grid>
    </AuthWrapper>
  );
}
