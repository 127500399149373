import { Navigate, useParams } from "react-router-dom";
import {
  DynamicRoutePath,
  IStoreRouteParams,
  StaticRoutePath,
} from "../../AppRoutes";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { SettingsWizard } from "./SettingsWizard";
import { Store } from "../Store";
import { newConfigurationId } from "../../common/ConfigurationsContext/Configuration";

export function StoreSettings() {
  const { id = "" } = useParams<IStoreRouteParams>();
  const { configuration } = useConfiguration(id);
  if (id == newConfigurationId || !configuration) {
    return <Navigate to={StaticRoutePath.NotFound} replace={true} />;
  }
  return (
    <Store>
      <SettingsWizard id={id} />
    </Store>
  );
}
