import Grid from "@material-ui/core/Grid/Grid";
import { CustomStepper } from "../../../common/CustomeStepper";
import { TopContainer } from "../../../common/Page/TopContainer";
import { Store } from "../../Store";
import { MainContainer } from "../../../common/Page/MainContainer";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import { createUseClasses } from "../../../common/Theme/createUseClasses";
import DescriptionGenerator, {
  DescriptionGeneratorProps,
} from "./DescriptionGenerator";
import {
  ConsentAndInfoScreen,
  ConsentAndInfoScreenProps,
} from "./ConstentAndInfoScreen";
import {
  DescriptionHistory,
  DescriptionHistoryProps,
} from "./DescriptionHistory";
import {
  ProductDescriptionProvider,
  useProductDescriptionContext,
} from "../Context/productDescriptionGenerate";

const steps = ["AI Content generation"];

// type ParentComponentProps = {
//   component: ChildComponentsProps;
//   children?: React.ReactNode,
// };
const childComponentsMap = {
  ConsentAndInfoScreen: (props: ConsentAndInfoScreenProps) => (
    <ConsentAndInfoScreen {...props} />
  ),
  DescriptionGenerator: (props: DescriptionGeneratorProps) => (
    <DescriptionGenerator {...props} />
  ),
  DescriptionHistory: (props: DescriptionHistoryProps) => (
    <DescriptionHistory {...props} />
  ),
};
function ParentComponent() {
  const { componentProps, componentType } = useProductDescriptionContext();
  const ComponentToRender = childComponentsMap[
    componentType
  ] as React.ComponentType<any>;
  if (!ComponentToRender) return null;
  return <ComponentToRender {...componentProps} />;
}

export function LLMProductDescritionGenerate() {
  const classes = useClasses();

  return (
    <>
      <Store>
        <TopContainer style={{ paddingBottom: "16px" }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <CustomStepper
                steps={steps}
                activeStep={0}
                hideStepperProgressBar
                stepLabelContainerAlignment="left"
              />
            </Grid>
          </Grid>
        </TopContainer>
        <MainContainer style={{ position: "relative" }}>
          <Typography className={classes.title}>
            Product description generator
          </Typography>
          <Typography className={classes.subTitle}>
            {
              //TODO: This text have to be change by actual content
            }
            Create product descriptions using ChatGPT
          </Typography>
          <Divider className={classes.divider} />
          <Grid>
            <Typography className={classes.subTitle}>
              Lets generate AI base best product description
            </Typography>
          </Grid>
          <Divider className={classes.dividerSmall} />
          <Grid container item xs={12} lg={12}>
            <Grid
              item
              container
              alignItems="center"
              direction="column"
              style={{ columnGap: 12, rowGap: 8 }}
              justify="space-between"
              xs={12}
            >
              <ProductDescriptionProvider>
                <ParentComponent />
              </ProductDescriptionProvider>
            </Grid>
          </Grid>
        </MainContainer>
      </Store>
    </>
  );
}

const useClasses = createUseClasses((theme) => ({
  title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px 0px",
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#636364",
  },
  divider: {
    margin: "30px 0",
  },
  dividerSmall: {
    margin: "16px 0",
  },
}));
