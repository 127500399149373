import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Box,
} from "@material-ui/core";
import * as React from "react";
import { ReactNode, useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ApiError from "../Client/ApiError";
import { CreateUserApiError } from "../Client/BackendResponse";
import { isValidEmail } from "../utils/validation";
import { Link as RouterLink } from "react-router-dom";
import { StaticRoutePath } from "../../AppRoutes";
import StyledLabel from "../StyledLabel/StyledLabel";

type LoginCredentialsProps = {
  submitButtonTitle: string;
  onSubmit: (username: string, password: string) => void;
  beforeContent?: ReactNode;
};

export function LoginCredentials({
  submitButtonTitle,
  onSubmit,
  beforeContent,
}: LoginCredentialsProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [submitError, setSubmitError] = useState<undefined | string>();
  const [submitErrorApiError, setSubmitErrorApiError] =
    useState<CreateUserApiError>({});
  async function submit() {
    if (!isValidEmail(email) || !isValidPassword(password)) {
      setShowValidationErrors(true);
      return;
    }
    try {
      await onSubmit(email, password);
    } catch (error) {
      if (error instanceof ApiError && error.jsonBody) {
        setSubmitErrorApiError(error.jsonBody);
      } else {
        setSubmitError((error as { message?: string })?.message);
      }
    }
  }

  const hasEmailError =
    Boolean(submitError) ||
    Boolean(submitErrorApiError.user) ||
    (showValidationErrors && !isValidEmail(email));
  const hasPasswordError =
    Boolean(submitError) ||
    Boolean(submitErrorApiError.password) ||
    (showValidationErrors && !isValidPassword(password));

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      {beforeContent}
      <Grid item xs={12}>
        <StyledLabel> Email </StyledLabel>
        <TextField
          variant="outlined"
          error={hasEmailError}
          placeholder="Email"
          size="small"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setSubmitError(undefined);
          }}
          helperText={submitError || submitErrorApiError.user}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledLabel> Password </StyledLabel>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Password"
          error={hasPasswordError}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setSubmitError(undefined);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={submitError || submitErrorApiError.password}
        />
        <Box component="div" mt={1}>
          <Link component={RouterLink} to={StaticRoutePath.ForgotPassword}>
            Forgot password?
          </Link>
        </Box>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={submit}
            fullWidth
          >
            {submitButtonTitle}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function isValidPassword(password: string) {
  return password.length;
}
