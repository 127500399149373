import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import { SetStateAction, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import ImageUpload from "../../Stores/StoreSettings/ImageUpload";

export interface inlineImageUploadWidgetInterface {
  setImageURLFunction: React.Dispatch<React.SetStateAction<string>>;
  storeName: string | undefined;
}

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

export function InlineImageUploadWidget({
  setImageURLFunction,
  storeName,
}: inlineImageUploadWidgetInterface) {
  const classes = useClasses();
  const [file, setFile] = useState<string | null>(null);
  const { uploadImage } = ImageUpload();
  const handleFileChange = async (file: SetStateAction<File>) => {
    if (file instanceof Blob) {
      handleImageUpload(file);
    }
  };
  const handleImageUpload = async (file: File) => {
    try {
      const data = await uploadImage(file, storeName);
      setImageURLFunction(data.result);
      setFile("Image Uploaded Successfully.");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid
      container
      item
      xs={12}
      sm={4}
      md={12}
      lg={12}
      className={classes.imageBoxContainer}
    >
      <FileUploader
        handleChange={handleFileChange}
        name="file"
        types={fileTypes}
      >
        <Box className={classes.imageContainer}>
          <Typography className={classes.imageTypo}>
            {file ? file : "Upload Image."}
          </Typography>
        </Box>
      </FileUploader>
    </Grid>
  );
}

const useClasses = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
    background: "rgb(252, 238, 230)",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    padding: "0.7rem",
    borderRadius: "0.5rem",
  },
  avatarContainer: {
    marginLeft: "1px",
    marginBottom: "1px",
  },
  typographyForLogoUploadTitle: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "0.04em",
    color: "#202F4E",
  },
  typographyForLogoUploadRules: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "20px",
    letterSpacing: "0.02em",
    color: "#636364",
  },
  gridItemRoot: {
    "&.MuiGrid-item": {
      padding: "4px",
    },
  },

  buttonColorBox: {
    height: "15px",
    width: "15px",
    marginTop: "105px",
    marginLeft: "5px",
  },
  imageContainer: {
    paddingTop: "15px",
    paddingBottom: "30px",
    width: "250px",
    height: "18px",
    backgroundColor: "#FCEEE6",
    borderStyle: "dashed",
    textAlign: "center",
    border: "1px dashed  #6D6D6D",
    borderRadius: "5px",
  },
  imageTypo: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.5px",
    color: "#636364",
  },
  imageLink: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.5px",
    color: "#0093B4",
    marginRight: "5px",
    textDecoration: "underline",
  },
  button: {
    height: "35px",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "capitalize",
    borderRadius: "10px",
  },
  imageUploaderButton: {
    minWidth: "160px",
  },
  imageBoxContainer: {
    [theme.breakpoints.up("lg")]: {
      gap: "10px",
    },
    gap: "30px",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));
