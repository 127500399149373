export default function useHelper() {
  const minCharLimit = 2;
  const countCharacter = (str: string, limit = 30) => {
    if (str.length < minCharLimit) return true;
    return str.length > limit;
  };

  const errorMessageLabel = (length = 30) =>
    `Minimum ${minCharLimit} characters and Max ${length} characters`;

  return {
    countCharacter,
    errorMessageLabel,
  };
}
