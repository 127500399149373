import * as React from "react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  register,
  logIn,
  getCurrentUser,
  confirmEmail,
  resendConfirmationCode,
  getPasswordRecoveryCode,
  recoverPassword,
  logOut,
} from "./CognitoUserPool";
import { User, UserRole } from "./User";

const LoadingContext = createContext<boolean>(true);
const UserContext = createContext<User>(null);
const SetUserContext = createContext<(user: User) => void>(() => {});

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>(null);

  useEffect(() => {
    getCurrentUser().then((currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
  }, []);

  return (
    <LoadingContext.Provider value={loading}>
      <UserContext.Provider value={user}>
        <SetUserContext.Provider value={setUser}>
          {children}
        </SetUserContext.Provider>
      </UserContext.Provider>
    </LoadingContext.Provider>
  );
}

export interface IUserContext {
  loading: boolean;
  user: User;
  logIn: (email: string, password: string) => Promise<void>;
  register: (
    fullName: string,
    email: string,
    password: string
  ) => Promise<void>;
  confirmEmail: (email: string, confirmationCode: string) => Promise<void>;
  resendConfirmationCode: typeof resendConfirmationCode;
  getPasswordRecoveryCode: typeof getPasswordRecoveryCode;
  recoverPassword: typeof recoverPassword;
  logOut: () => void;
}

export function useUserContext(): IUserContext {
  const setUser = useContext(SetUserContext);

  return {
    loading: useContext(LoadingContext),
    user: useContext(UserContext),
    logIn: async (email: string, password: string) => {
      const { user: loggedInUser } = await logIn(email, password);
      setUser(loggedInUser);
    },
    register: async (fullName: string, email: string, password: string) => {
      const registeredUser = await register(fullName, email, password);
      setUser(registeredUser);
    },
    confirmEmail: async (email: string, confirmationCode: string) => {
      await confirmEmail(email, confirmationCode);
      setUser(null);
    },
    resendConfirmationCode,
    getPasswordRecoveryCode,
    recoverPassword,
    logOut: () => {
      logOut();
      setUser(null);
    },
  };
}

export function isAdmin(user: User) {
  return user && user.role == UserRole.admin ? true : false;
}
