import React, { useContext } from "react";
import { ALLOWED_LANGUAGE } from "../../Configs/language";

type GretingLanguageContextType = {
  lang: ALLOWED_LANGUAGE | "default";
  setLang: React.Dispatch<React.SetStateAction<ALLOWED_LANGUAGE | "default">>;
};
export const GreetingLanguageContext =
  React.createContext<GretingLanguageContextType | null>(null);

export const useGreetingLang = () => {
  const context = useContext(GreetingLanguageContext);
  if (!context)
    throw new Error(
      "useGreetingLang must be used within a GreetingLanguageContextProvider"
    );
  return context;
};
