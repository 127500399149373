import { Language } from "../common/ConfigurationsContext/Configuration";
// export const allowedLanguage = {
//   english: "en" as const,
//   finish: "fi" as const,
//   swedish: "sv" as const,
//   german: "de" as const,
//   dutch: "nl" as const,
// };
export const allowedLanguage = Language;

export type ALLOWED_LANGUAGE_KEY = keyof typeof allowedLanguage;
export type ALLOWED_LANGUAGE = typeof allowedLanguage[ALLOWED_LANGUAGE_KEY];
