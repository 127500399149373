import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  ActivePromotion,
  IConfiguration,
  IConfigurationCommonFrontend,
} from "../../common/ConfigurationsContext/Configuration";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { SettingsWizardPageProps } from "./SettingsWizardPage";
import * as React from "react";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import {
  useCurrentPageIndex,
  useNextButtonDisableIndex,
} from "../PageIndexProvider";
import { Alert } from "@material-ui/lab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`promotions-tabpanel-${index}`}
      aria-labelledby={`promotions-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `promotions-tab-${index}`,
    "aria-controls": `promotions-tabpanel-${index}`,
  };
}

enum NewArrivalTimeLimit {
  _14DAYS = "14 Days",
  _30DAYS = "30 Days",
  _90DAYS = "90 Days",
  _180DAYS = "180 Days",
  _365DAYS = "365 Days",
}

// 1 days = 1000(milliseconds)*60(seconds)*60(minutes)*24(hours) = 86,400,000
const mapNewArrivalTimeLimit = new Map<NewArrivalTimeLimit, number>([
  [NewArrivalTimeLimit._14DAYS, 1209600000], // 14 days = 86400000*14 = 1209600000
  [NewArrivalTimeLimit._30DAYS, 2592000000], // 30 days = 86400000*30 = 2592000000
  [NewArrivalTimeLimit._90DAYS, 7776000000], // 90 days = 86400000*90 = 7776000000
  [NewArrivalTimeLimit._180DAYS, 15552000000], // 180 days = 86400000*180 = 15552000000
  [NewArrivalTimeLimit._365DAYS, 31536000000], // 365 days = 86400000*365 = 31536000000
]);

export function Promotions({ id }: SettingsWizardPageProps) {
  const [activeTab, setActiveTab] = React.useState(0);
  const { configuration, setCommonFrontendConfiguration } =
    useConfiguration(id);

  const { currentPageIndex } = useCurrentPageIndex();
  const { updateNextBtnDisableForIndex } = useNextButtonDisableIndex();

  React.useEffect(() => {
    if (!configuration?.activePromotion) {
      updateNextBtnDisableForIndex(currentPageIndex);
    } else {
      updateNextBtnDisableForIndex(-1);
    }
  }, [configuration?.activePromotion]);

  React.useEffect(() => {
    /**
     * If new arrival is currently active promotion and new arrival tab is disabled then active promotion will be unset
     * If Daily deals is currently active promotion and daily deals tab is disabled then active promotion will be unset
     */
    const activePromotionWillBeUnset =
      (configuration?.activePromotion == ActivePromotion.DAILY_DEALS &&
        configuration?.isDailyDealsDisabled) ||
      (configuration?.activePromotion == ActivePromotion.NEW_ARRIVAL &&
        configuration?.isNewArrivalTabDisabled);

    if (activePromotionWillBeUnset) {
      setCommonFrontendConfiguration({
        activePromotion: "",
      });
    }
  }, [
    configuration?.isDailyDealsDisabled,
    configuration?.isNewArrivalTabDisabled,
  ]);

  const classes = useClasses();
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Typography
        gutterBottom
        style={{ fontWeight: 700 }}
        variant="h6"
        color="textPrimary"
      >
        Promotions settings
      </Typography>
      <Tabs
        className={classes.tabs}
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Promotions settings tabs"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Daily deals" {...a11yProps(0)} />
        <Tab label="New arrivals" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <DailyDealsPanel
          configuration={configuration as IConfiguration}
          setCommonFrontendConfiguration={setCommonFrontendConfiguration}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <NewArrivalPanel
          configuration={configuration as IConfiguration}
          setCommonFrontendConfiguration={setCommonFrontendConfiguration}
        />
      </TabPanel>

      {!configuration?.activePromotion ? (
        <Alert severity="error">
          {"Please choose an active promotion type before continuing"}
        </Alert>
      ) : null}
    </>
  );
}

interface ICommonProps {
  configuration: IConfiguration;
  setCommonFrontendConfiguration: (
    commonFrontendConfiguration: Partial<IConfigurationCommonFrontend>
  ) => void;
}

function DailyDealsPanel({
  configuration,
  setCommonFrontendConfiguration,
}: ICommonProps) {
  const classes = useClasses();
  const { isDailyDealsDisabled, activePromotion } = configuration;

  function handleDisableChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCommonFrontendConfiguration({
      isDailyDealsDisabled: e.target?.checked ? e.target.checked : false,
    });
  }

  function handleActivePromotion(e: React.ChangeEvent<HTMLInputElement>) {
    setCommonFrontendConfiguration({
      activePromotion: e.target?.checked ? ActivePromotion.DAILY_DEALS : "",
    });
  }

  return (
    <>
      <Grid
        container
        className={classes.transitionOnOpacity}
        style={{
          opacity: isDailyDealsDisabled ? 0.7 : 1,
          minHeight: 395,
          flexDirection: "column",
        }}
      >
        <Grid item style={{ marginBottom: 24 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  activePromotion == ActivePromotion.DAILY_DEALS ? true : false
                }
                name="dailyDealsActivePromotion"
                color="primary"
                onChange={handleActivePromotion}
                disabled={isDailyDealsDisabled}
              />
            }
            label={
              <Typography
                gutterBottom
                style={{ fontWeight: 500 }}
                color="textSecondary"
              >
                Activate Daily deals as a selected Promotion
              </Typography>
            }
          />
        </Grid>
        <Grid item>
          <Typography
            gutterBottom
            style={{ fontWeight: 500 }}
            color="textSecondary"
          >
            Upsy will automatically fetch actively discounted products from your
            product catalog and promote them in the Daily Deals tab. As you
            discount products in your selection, Upsy will detect them and start
            promoting them.
          </Typography>
        </Grid>
      </Grid>
      <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid container>
        <Grid item>
          <FormControlLabel
            className={classes.transitionOnOpacity}
            style={!isDailyDealsDisabled ? { opacity: 0.7 } : { opacity: 1 }}
            control={
              <Checkbox
                checked={isDailyDealsDisabled}
                name="isDailyDealsDisabled"
                color="primary"
                onChange={handleDisableChange}
              />
            }
            label={
              <Typography
                gutterBottom
                style={{ fontWeight: 500 }}
                color="textSecondary"
              >
                Disable and hide the Daily Deals feature from Upsy
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

function NewArrivalPanel({
  configuration,
  setCommonFrontendConfiguration,
}: ICommonProps) {
  const classes = useClasses();
  const { isNewArrivalTabDisabled, newArrivalTimeLimit, activePromotion } =
    configuration;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCommonFrontendConfiguration({
      newArrivalTimeLimit: e.target.value ? Number(e.target.value) : null,
    });
  }

  function handleDisableChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCommonFrontendConfiguration({
      isNewArrivalTabDisabled: e.target?.checked ? e.target.checked : false,
    });
  }

  function handleActivePromotion(e: React.ChangeEvent<HTMLInputElement>) {
    setCommonFrontendConfiguration({
      activePromotion: e.target?.checked ? ActivePromotion.NEW_ARRIVAL : "",
    });
  }

  return (
    <>
      <Grid
        container
        style={isNewArrivalTabDisabled ? { opacity: 0.4 } : {}}
        className={classes.transitionOnOpacity}
      >
        <Grid item style={{ marginBottom: 24 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  activePromotion == ActivePromotion.NEW_ARRIVAL ? true : false
                }
                name="newArrivalActivePromotion"
                color="primary"
                onChange={handleActivePromotion}
                disabled={isNewArrivalTabDisabled}
              />
            }
            label={
              <Typography
                gutterBottom
                style={{ fontWeight: 500 }}
                color="textSecondary"
              >
                Activate Newly arrivals as a selected Promotion
              </Typography>
            }
          />
        </Grid>
        <Typography
          gutterBottom
          style={{ fontWeight: 500 }}
          color="textSecondary"
        >
          UPSY widget will automatically show new products in the New Arrivals
          tab. When you add new products to the website, UPSY will detect them
          and start showing the new products under this tab.
        </Typography>
        <Grid item>
          <Typography
            gutterBottom
            style={{ fontWeight: 500, marginTop: 18 }}
            color="textSecondary"
          >
            Please select the time frame for the new products. You can always
            change this settings.
          </Typography>
          <Grid container style={{ marginTop: 24 }}>
            <Grid item>
              <FormControl>
                <RadioGroup
                  aria-labelledby="new-arrival-time-limit-threshold"
                  name="newArrivalTimeLimit"
                  value={
                    newArrivalTimeLimit
                      ? newArrivalTimeLimit.toString()
                      : mapNewArrivalTimeLimit
                          .get(NewArrivalTimeLimit._90DAYS)
                          ?.toString()
                  }
                  onChange={handleChange}
                >
                  {Array.from(mapNewArrivalTimeLimit.entries()).map(
                    (mapValue) => {
                      return (
                        <FormControlLabel
                          classes={{ disabled: classes.disabled }}
                          disabled={isNewArrivalTabDisabled}
                          key={mapValue[0]}
                          value={mapValue[1].toString()}
                          control={<Radio color="primary" />}
                          label={mapValue[0]}
                        />
                      );
                    }
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid container>
        <Grid item>
          <FormControlLabel
            className={classes.transitionOnOpacity}
            style={!isNewArrivalTabDisabled ? { opacity: 0.7 } : { opacity: 1 }}
            control={
              <Checkbox
                checked={isNewArrivalTabDisabled}
                name="isNewArrivalTabDisabled"
                color="primary"
                onChange={handleDisableChange}
              />
            }
            label={
              <Typography
                gutterBottom
                style={{ fontWeight: 500 }}
                color="textSecondary"
              >
                Disable and hide the Newly Arrived feature from Upsy
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

const useClasses = createUseClasses((theme) => ({
  tabs: {
    borderBottom: "1px solid #CBD5E1",
    width: "fit-content",
  },
  transitionOnOpacity: {
    transition: "opacity 0.3s ease-in",
  },
  disabled: {
    cursor: "not-allowed !important",
  },
}));
