import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { IConfiguration } from "../ConfigurationsContext/Configuration";

export type StoreDeleteConfirmBoxProps = {
  isOpen: boolean;
  configuration: IConfiguration | undefined;
  onDelete: () => void;
  updateIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  errorMessage: string | null;
};

export default function StoreDeleteConfirmBox({
  isOpen,
  configuration,
  updateIsOpen,
  onDelete,
  errorMessage,
  loading = false,
}: StoreDeleteConfirmBoxProps) {
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setHasError(Boolean(errorMessage));
  }, [errorMessage]);

  const handleCloseBtn = () => {
    updateIsOpen(false);
  };

  const handleClose = () => {
    if (loading) {
      return;
    }
    updateIsOpen(false);
  };

  const ActionButton = () => {
    if (loading) {
      return (
        <Button
          color="secondary"
          endIcon={<CircularProgress color="inherit" size={16} />}
        >
          {`Deleting ${
            configuration && configuration.storeNameFormatted
              ? configuration.storeNameFormatted.toLowerCase()
              : ""
          } ..`}
        </Button>
      );
    } else if (hasError) {
      return (
        <>
          <Button onClick={handleCloseBtn} color="primary" autoFocus>
            Close
          </Button>
          <Button onClick={onDelete} color="secondary">
            Try-again
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button onClick={handleCloseBtn} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={onDelete} color="secondary">
            {`Yes, delete ${
              configuration && configuration.storeNameFormatted
                ? configuration.storeNameFormatted.toLowerCase()
                : ""
            }`}
          </Button>
        </>
      );
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      onBackdropClick={handleClose}
      aria-labelledby="delete-alert-dialog-title"
      aria-describedby="delete-alert-dialog-description"
    >
      <DialogTitle id="delete-alert-dialog-title">{`Are you sure you want to delete the store ${
        configuration && configuration.storeNameFormatted
          ? configuration.storeNameFormatted.toLowerCase()
          : ""
      }?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-alert-dialog-description">
          <Typography gutterBottom>
            Deleting the store will remove it from the dashboard and our system.
            Deletion is permanent so make sure you are removing the correct
            store before proceeding.
          </Typography>
          {hasError ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{ActionButton()}</DialogActions>
    </Dialog>
  );
}
