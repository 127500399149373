import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { CustomHtmlTooltip } from "../../../common/Tooltip/Tooltip";
import CustomTextField from "../../../common/CustomUiComponent/TextField";
import { DividerWithContent } from "./Components/DividerWithContent";
import useStateWithValidation, {
  ValidationErrors,
} from "../../../common/Hooks/useStateWithValidation";
import { IStoreRouteParams } from "../../../AppRoutes";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { useProductDescriptionContext } from "../Context/productDescriptionGenerate";
import { Star } from "@material-ui/icons";

function apiKeyValidationRules(state: { apiKey: string | undefined }) {
  const errors: ValidationErrors<{ apiKey: string }> = {};
  const { apiKey } = state;
  const trimedApiKey = apiKey?.trim();
  if (!trimedApiKey) {
    errors.apiKey = "API key is required";
  } else if (trimedApiKey.length < 10) {
    errors.apiKey = "API key must be at least 10 characters long";
  } else if (trimedApiKey.length > 100) {
    errors.apiKey = "API key must be at most 100 characters long";
  }
  return errors;
}
export type ConsentAndInfoScreenProps = {
  children?: React.ReactNode;
};
export function useApiKey() {
  const { id = "" } = useParams<IStoreRouteParams>();
  const localStorageKey = `${id}:openai:apiKey`;
  const [apiKey, updateApiKey] = useLocalStorage(localStorageKey, "");
  return { apiKey, updateApiKey };
}

export function ConsentAndInfoScreen(props: ConsentAndInfoScreenProps) {
  const { apiKey, updateApiKey } = useApiKey();
  const { updateComponentType, updateIsWithUpsy } =
    useProductDescriptionContext();
  const initialApiKeyState = {
    apiKey,
  };

  const {
    state: apiKeyState,
    errors,
    isDirty,
    update,
    isValid,
  } = useStateWithValidation(initialApiKeyState, apiKeyValidationRules);
  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item container>
        <Typography
          style={{
            fontSize: 14,
            lineHeight: 1.75,
            marginBlock: 16,
          }}
        >
          Our AI-powered tool creates the best product descriptions tailored to
          your needs. Simply enter the product name and attributes/tags, and let
          our AI do the rest. Whether you need descriptions for a new launch or
          to refresh existing listings, our generator ensures high-quality,
          unique content every time.
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            lineHeight: 1.75,
            marginTop: 8,
            marginBottom: 4,
            fontWeight: "bold",
          }}
        >
          How It Works
        </Typography>
        <ol>
          <li>
            <b>Enter product name and attributes:</b> Provide essential
            information about your product, such as name and features.
          </li>
          <li>
            <b>Generate Descriptions:</b> Use your OpenAI API Key to generate
            unlimited product descriptions. No API key? No problem! Try our AI
            feature for free and create up to 10 product descriptions.
          </li>
          <li>
            <b> Review and Publish:</b> Review the generated descriptions, make
            any necessary tweaks, and publish them to your store.
          </li>
        </ol>
        <Grid item xs={12}>
          <CustomHtmlTooltip title="Enter you openai api key">
            <CustomTextField
              required
              type="text"
              labelText="API Key"
              labelFor="API Key"
              variant="outlined"
              placeholder="Enter your openai API Key"
              autoComplete="off"
              onChange={(e) => {
                updateApiKey(e.target.value);
                update("apiKey", e.target.value);
              }}
              value={apiKeyState.apiKey}
              error={errors.apiKey && isDirty.apiKey ? true : false}
              helperText={errors.apiKey && isDirty.apiKey ? errors.apiKey : ""}
            />
          </CustomHtmlTooltip>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          style={{ marginLeft: "auto", marginBottom: 20, marginTop: 10 }}
        >
          <Button
            fullWidth
            onClick={() => {
              updateComponentType("DescriptionGenerator");
              updateIsWithUpsy(false);
              //TODO::
            }}
            disabled={!isValid}
            variant="contained"
            color="primary"
            size="medium"
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <DividerWithContent thickness="1.3px">
        <span>OR</span>
      </DividerWithContent>
      <Grid item container direction="column" style={{ gap: 12 }}>
        <Typography
          style={{
            fontSize: "15px",
            fontWeight: 500,
            lineHeight: "22px",
            color: "#636364",
          }}
        >
          Try the AI feature for free
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon style={{ minWidth: 16 }}>
              <Star style={{ height: 12, width: 12 }} />
            </ListItemIcon>
            <ListItemText primary="Create up to 10 free product descriptions" />
          </ListItem>
        </List>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            updateComponentType("DescriptionGenerator");
            updateIsWithUpsy(true);
            //TODO::
          }}
        >
          Try AI magic
        </Button>
      </Grid>
    </Grid>
  );
}
