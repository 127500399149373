import { useState } from "react";
import { ChildComponentsProps } from "../Types/ProductDescription";

export function useProductDescriptionGeneratorChildComponent(
  type?: ChildComponentsProps["type"]
) {
  const [componentType, updateComponentType] = useState<
    ChildComponentsProps["type"]
  >(type ? type : "ConsentAndInfoScreen");
  const componentProps: ChildComponentsProps = (() => {
    switch (componentType) {
      case "ConsentAndInfoScreen":
        return { type: "ConsentAndInfoScreen", props: {} } as const;
      case "DescriptionGenerator":
        return { type: "DescriptionGenerator", props: {} } as const;
      case "DescriptionHistory":
        return { type: "DescriptionHistory", props: {} } as const;
      default:
        return { type: "ConsentAndInfoScreen", props: {} } as const;
    }
  })();
  return { componentType, componentProps, updateComponentType };
}
