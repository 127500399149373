import * as React from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { StaticRoutePath } from "../AppRoutes";
import { Button, Grid, TextField, Typography, Link } from "@material-ui/core";
import { Title } from "../common/Title/Title";
import { AuthWrapper } from "../common/AuthWrapper/AuthWrapper";
import StyledLabel from "../common/StyledLabel/StyledLabel";

export function ConfirmEmail() {
  const { user, confirmEmail, resendConfirmationCode } = useUserContext();
  const [confirmationCode, setConfirmationCode] = useState("");
  const [codeResent, setCodeResent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<undefined | string>();

  async function confirm() {
    try {
      if (user) {
        await confirmEmail(user.email, confirmationCode);
        setSuccess(true);
      } else {
        throw Error("confirm: Unexpected error");
      }
    } catch (error) {
      setSubmitError((error as { message?: string })?.message);
    }
  }

  async function resend() {
    try {
      if (user) {
        await resendConfirmationCode(user.email);
        setCodeResent(true);
      } else {
        throw Error("resend: Unexpected error");
      }
    } catch (error) {
      setSubmitError((error as { message?: string })?.message);
    }
  }

  return (
    <AuthWrapper>
      <Grid container item xs={12} alignItems="center" spacing={1}>
        <Grid item xs={12} style={{ marginBottom: "18px" }}>
          <Title>Confirm Email</Title>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          {success ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Email confirmed succesfully!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link component={RouterLink} to={StaticRoutePath.Login}>
                  Go to login
                </Link>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <StyledLabel>Code</StyledLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Code"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={confirm}
                >
                  Confirm
                </Button>
              </Grid>
              {codeResent && (
                <Grid item xs={12}>
                  Code was resent
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={resend}
                >
                  Resend code{codeResent ? " again" : ""}
                </Button>
              </Grid>
              {submitError && (
                <Grid item xs={12}>
                  {submitError}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
