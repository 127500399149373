import {
  Grid,
  Typography,
  Box,
  Button,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { SetStateAction, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import ImageUpload from "../../Stores/StoreSettings/ImageUpload";
import { useConfiguration } from "../ConfigurationsContext/ConfigurationsContext";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

export interface imageUploadWidgetInterface {
  id: string;
  setImageURLFunction: React.Dispatch<React.SetStateAction<string>>;
  buttonLabelName: string;
}

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const defaultAvatarName = "cloud.png";

const getPublicFile = (path: string) => `${process.env.PUBLIC_URL}${path}`;

export function ImageUploadWidget({
  id,
  setImageURLFunction,
  buttonLabelName,
}: imageUploadWidgetInterface) {
  const classes = useClasses();
  const { configuration } = useConfiguration(id);
  const [file, setFile] = useState(getPublicFile("cloud.png"));
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [trackFileUpload, setTrackFileUpload] = useState(false);
  const { uploadImage } = ImageUpload();
  const handleFileChange = (file: SetStateAction<File>) => {
    if (file instanceof Blob) {
      setFileToUpload(file);
      setFile(URL.createObjectURL(file));
    }
  };
  const handleImageUpload = async () => {
    try {
      setTrackFileUpload(false);
      if (file === defaultAvatarName) {
        alert("Please Select a file to upload");
        return false;
      }
      const data = await uploadImage(fileToUpload, configuration?.storeName);
      setImageURLFunction(data.result);
      setTrackFileUpload(true);
      setFile(getPublicFile("cloud.png"));
    } catch (error) {}
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setTrackFileUpload(false);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        classes={{ root: classes.gridItemRoot }}
        className={classes.avatarContainer}
      >
        <Typography className={classes.typographyForLogoUploadTitle}>
          Upload your logo
        </Typography>
        <Typography className={classes.typographyForLogoUploadRules}>
          PNG,JPEG,GIF,JPG files are allowed. Max file size 512px x 512px.
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={4}
        md={12}
        lg={12}
        className={classes.imageBoxContainer}
      >
        <FileUploader
          handleChange={handleFileChange}
          name="file"
          types={fileTypes}
        >
          <Box className={classes.imageContainer}>
            <img
              alt="avatar"
              src={file}
              width={file === defaultAvatarName ? "54" : "120"}
              height={file === defaultAvatarName ? "44" : "90"}
              style={{ marginBottom: "32px" }}
            />
            {file === defaultAvatarName && (
              <Typography className={classes.imageTypo}>
                Drag & Drop or{" "}
                <Link className={classes.imageLink} to="#">
                  browse
                </Link>
                from your pc.
              </Typography>
            )}
          </Box>
        </FileUploader>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          classes={{ root: classes.imageUploaderButton }}
          className={classes.button}
          onClick={handleImageUpload}
        >
          {buttonLabelName}
        </Button>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={trackFileUpload}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Image Uploaded successfully"
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
}

const useClasses = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
    background: "rgb(252, 238, 230)",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    padding: "0.7rem",
    borderRadius: "0.5rem",
  },
  avatarContainer: {
    marginLeft: "5px",
    marginBottom: "10px",
  },
  typographyForLogoUploadTitle: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "0.04em",
    color: "#202F4E",
  },
  typographyForLogoUploadRules: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "20px",
    letterSpacing: "0.02em",
    color: "#636364",
  },
  gridItemRoot: {
    "&.MuiGrid-item": {
      padding: "4px",
    },
  },

  buttonColorBox: {
    height: "15px",
    width: "15px",
    marginTop: "105px",
    marginLeft: "5px",
  },
  imageContainer: {
    paddingTop: "36px",
    paddingBottom: "28px",
    width: "450px",
    height: "159px",
    backgroundColor: "#FCEEE6",
    borderStyle: "dashed",
    textAlign: "center",
    border: "1px dashed  #6D6D6D",
    borderRadius: "5px",
  },
  imageTypo: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.5px",
    color: "#636364",
  },
  imageLink: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.5px",
    color: "#0093B4",
    marginRight: "5px",
    textDecoration: "underline",
  },
  button: {
    height: "35px",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "capitalize",
    borderRadius: "10px",
  },
  imageUploaderButton: {
    minWidth: "160px",
  },
  imageBoxContainer: {
    [theme.breakpoints.up("lg")]: {
      gap: "10px",
    },
    gap: "30px",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "68px",
  },
}));
