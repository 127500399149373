import { IConfigurationCommonFrontendDynamoDB } from "../../../common/Client/BackendResponse";

export enum activeWidgetScreenEnum {
  WIDGETEXPANDED = "WIDGETEXPANDED",
  WIDGETMINIFIED = "WIDGETMINIFIED",
  GREETINGLG = "GREETINGLG",
  GREETINGSM = "GREETINGSM",
  FEEDBACK = "FEEDBACK",
  THANKYOU = "THANKYOU",
  ANYQUESTION = "ANYQUESTION",
}

export type ActiveWidgetScreenType = `${activeWidgetScreenEnum}`;

export enum UpsyContextEnum {
  UP_SELL = "UP_SELL",
  CROSS_SELL = "CROSS_SELL",
  RELATED_SELL = "RELATED_SELL",
  CATEGORY = "CATEGORY",
  NEW_ARRIVAL = "NEW_ARRIVAL",
  DAILY_DEALS = "DAILY_DEALS",
  FAQ = "faq",
}

export type UpsyContextType = `${UpsyContextEnum}`;

export interface IWidget {
  updateConfig: (config: Partial<IConfigurationCommonFrontendDynamoDB>) => void;
  getWidgetScreen: () => ActiveWidgetScreenType;
  getWidgetContext: () => UpsyContextType;
  setWidgetScreen: (screen: ActiveWidgetScreenType) => void;
  setWidgetContext: (context: UpsyContextType) => void;
  subscribeScreen: (
    callback: (screen: ActiveWidgetScreenType) => void
  ) => Function;
  subscribeContext: (callback: (screen: UpsyContextType) => void) => Function;
}
