import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useConfiguration } from "../../../common/ConfigurationsContext/ConfigurationsContext";
import CustomTextField from "../../../common/CustomUiComponent/TextField";
import { SettingsWizardPageProps } from "../SettingsWizardPage";
import ButtonGroup from "./ButtonGroup";
import useStyles from "./style";
import { useGreetingLang } from "../GrettingLangContext";
import useHelper from "./useHelper";

export default function Upsell({ id }: SettingsWizardPageProps) {
  const classes = useStyles();
  const { configuration, setCommonFrontendConfiguration } =
    useConfiguration(id);
  const { countCharacter, errorMessageLabel } = useHelper();
  const { lang } = useGreetingLang();
  const upperText =
    lang === "default"
      ? configuration?.greetings?.upSell?.upperText || ""
      : configuration?.greetings?.upSell?.locale?.[lang]?.upperText || "";
  const [upperTextState, setUpperTextState] = useState(upperText);
  useEffect(() => {
    setUpperTextState(upperText);
  }, [lang, upperText]);
  const defaultHandler = () => {
    const defaultUpperText = configuration?.greetings?.upSell?.upperText || "";
    setUpperTextState(defaultUpperText);
  };
  const submithandler = () => {
    if (countCharacter(upperTextState, 20)) return false;
    let updatedData = {
      ...(configuration?.greetings?.upSell || {}),
      upperText: upperTextState || "",
    };

    if (lang !== "default") {
      updatedData = {
        ...(configuration?.greetings?.upSell || {}),
        upperText: configuration?.greetings?.upSell?.upperText || "",
        locale: {
          ...(configuration?.greetings?.upSell?.locale || {}),
          [lang]: {
            upperText: upperTextState || "",
          },
        },
      };
    }

    setCommonFrontendConfiguration({
      greetings: {
        ...configuration?.greetings,
        upSell: updatedData,
      },
    });
  };
  const handleChange = (value: string) => {
    setUpperTextState(value);
  };
  return (
    <>
      <Typography className={classes.title}>Up Sell Upper Text</Typography>
      <CustomTextField
        onChange={(e) => handleChange(e.target.value)}
        value={upperTextState}
        error={countCharacter(upperTextState, 20)}
        placeholder={"Leave this empty for default upper text"}
        helperText={countCharacter(upperTextState, 20) && errorMessageLabel(20)}
      />
      <ButtonGroup
        submithandler={submithandler}
        defaultHandler={defaultHandler}
      />
    </>
  );
}
