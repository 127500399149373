import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  defaultAvatar,
  defaultButtonColor,
  getWooCommerceVersion,
  IConfigurationCommonFrontend,
  IWooCommerceConfiguration,
  Platform,
} from "../../common/ConfigurationsContext/Configuration";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ReactNode, useEffect, useState } from "react";
import { IntegrationWizardPageProps } from "./IntegrationWizardPage";
import { Title, TitleVariant } from "../../common/Title/Title";
import { LoadingButton } from "../../common/LoadingButton/LoadingButton";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { ExpandMore, ArrowRight } from "@material-ui/icons";

export function ConfigurationSummary({ id }: IntegrationWizardPageProps) {
  const { configuration } = useConfiguration(id);
  const {
    platform,
    storeName,
    storeNameFormatted,
    storeUrl,
    apiVersion,
    key,
    secret,
    operationMode,
    language,
    avatar,
    buttonColor,
    flows,
    customQnA,
  } = configuration as IWooCommerceConfiguration;

  const classes = useClasses();

  return (
    <>
      <Title variant={TitleVariant.Secondary}>Summary</Title>
      <Grid container justify="flex-end">
        <SummaryRow label="Platform" value={platform} />
        <SummaryRow
          label="Store name"
          value={storeNameFormatted || storeName}
        />
        <SummaryRow label="Store URL" value={storeUrl} />
        {platform === Platform.WooCommerce && (
          <>
            <SummaryRow
              label="WooCommerce version"
              value={apiVersion ? getWooCommerceVersion(apiVersion) : ""}
            />
            <SummaryRow label="WooCommerce key" value={key} />
            <SummaryRow label="WooCommerce secret" value={secret} />
          </>
        )}
        <SummaryRow label="Operation mode" value={operationMode} />
        <SummaryRow label="Language" value={language} />
      </Grid>
    </>
  );
}

function SummaryRow({ label, value }: { label: ReactNode; value: ReactNode }) {
  return (
    <SummaryLabelAndValue
      label={label}
      value={<Typography variant="body2">{value}</Typography>}
    />
  );
}

function SummaryLabelAndValue({
  label,
  value,
  classes,
}: {
  label: ReactNode;
  value: ReactNode;
  classes?: string;
}) {
  return (
    <>
      <Grid item xs={12} md={3} className={classes}>
        <Typography variant="subtitle2">{label}:</Typography>{" "}
      </Grid>
      <Grid item xs={11} md={9} className={classes}>
        {value}
      </Grid>
    </>
  );
}

const useClasses = createUseClasses((theme) => ({
  summaryRow: {
    marginTop: "2em",
  },
}));
