import {
  AuthorizationStrategy,
  getWooCommerceVersion,
  IConfigurationWooCommerceSpecific,
  IWooCommerceConfiguration,
  Language,
  WooCommerceApiVersion,
} from "../../common/ConfigurationsContext/Configuration";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { FormControl, Grid, MenuItem, Typography } from "@material-ui/core";
import {
  IntegrationWizardPageProps,
  IntegrationWizardPageType,
} from "./IntegrationWizardPage";
import { CustomHtmlTooltip } from "../../common/Tooltip/Tooltip";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import CustomTextField from "../../common/CustomUiComponent/TextField";
import CustomSelectField from "../../common/CustomUiComponent/Select";

export function WooCommerceSpecificConfiguration({
  id,
  setPageType,
}: IntegrationWizardPageProps & {
  setPageType: (pageType: IntegrationWizardPageType) => void;
}) {
  const classes = useWooCommerceSpecificConfigurationClasses();
  const { configuration, setWooCommerceSpecificConfiguration } =
    useConfiguration(id);
  const { apiVersion, key, secret, woocommerceLanguage } =
    getPartialWooCommerceSpecificConfiguration(
      configuration as IWooCommerceConfiguration
    );

  return (
    <>
      <Typography className={classes.title}>WooCommerce Information</Typography>
      <Typography className={classes.subTitle}>
        {
          "Provide a REST API consumer key for your WooCommerce installation (WooCommerce > Settings > Advanced > REST API)."
        }
      </Typography>
      <Grid container item xs={12} md={9} lg={8} justify="space-between">
        <Grid item xs={12} lg={5}>
          <CustomHtmlTooltip title="Provide the version of your stores WooCommerce installation.">
            <FormControl>
              <CustomSelectField
                labelFor="WooCommerce version"
                labelText="WooCommerce version"
                placeholder="WooCommerce version"
                variant="outlined"
                fullWidth
                value={apiVersion}
                onChange={(event) =>
                  setWooCommerceSpecificConfiguration({
                    apiVersion: event.target.value as WooCommerceApiVersion,
                  })
                }
              >
                <MenuItem value={WooCommerceApiVersion.v3}>
                  {getWooCommerceVersion(WooCommerceApiVersion.v3)}
                </MenuItem>
                <MenuItem value={WooCommerceApiVersion.v2}>
                  {getWooCommerceVersion(WooCommerceApiVersion.v2)}
                </MenuItem>
                <MenuItem value={WooCommerceApiVersion.v1}>
                  {getWooCommerceVersion(WooCommerceApiVersion.v1)}
                </MenuItem>
              </CustomSelectField>
            </FormControl>
          </CustomHtmlTooltip>
        </Grid>
        <Grid item xs={12} lg={5}>
          <CustomHtmlTooltip title="Select the language with which to filter products of a single language.">
            <FormControl>
              <CustomSelectField
                labelFor="Product Language Filter"
                labelText="Product Language Filter"
                placeholder="Product Language Filter"
                variant="outlined"
                fullWidth
                value={woocommerceLanguage || ""}
                onChange={(event) =>
                  setWooCommerceSpecificConfiguration({
                    woocommerceLanguage: event.target.value as Language,
                  })
                }
                defaultValue={""}
                displayEmpty
                dropDownIconGapFromRight={0}
              >
                <MenuItem value={""}>All products are in one language</MenuItem>
                <MenuItem value={Language.Finnish}>
                  Filter for Finnish products
                </MenuItem>
                <MenuItem value={Language.English}>
                  Filter for English products
                </MenuItem>
                <MenuItem value={Language.Swedish}>
                  Filter for Swedish products
                </MenuItem>
                <MenuItem value={Language.German}>
                  Filter for German products
                </MenuItem>
                <MenuItem value={Language.Netherland}>
                  Filter for Netherland products
                </MenuItem>
              </CustomSelectField>
            </FormControl>
          </CustomHtmlTooltip>
        </Grid>
        <Grid xs={12}>
          <CustomHtmlTooltip title="Provide a REST API consumer key for your WooCommerce installation (WooCommerce > Settings > Advanced > REST API).">
            <CustomTextField
              labelText="Consumer key"
              labelFor="Consumer key"
              placeholder="Consumer key"
              fullWidth
              value={key}
              onChange={(event) =>
                setWooCommerceSpecificConfiguration({ key: event.target.value })
              }
            />
          </CustomHtmlTooltip>
        </Grid>
        <Grid item xs={12}>
          <CustomHtmlTooltip title="Provide a REST API consumer secret for your WooCommerce installation (WooCommerce > Settings > Advanced > REST API).">
            <CustomTextField
              labelText="Consumer secret"
              labelFor="Consumer secret"
              placeholder="Consumer secret"
              value={secret}
              onChange={(event) =>
                setWooCommerceSpecificConfiguration({
                  secret: event.target.value,
                })
              }
            />
          </CustomHtmlTooltip>
        </Grid>
      </Grid>
      {/* <ConfigurationInputContainer>
          <Tooltip
            placement="top-end"
            title="Provide the type of authorization used for the query."
          >
            <FormControl>
              <InputLabel>Query authorization type</InputLabel>
              <Select
                value={authStrategy}
                onChange={(event) =>
                  setWooCommerceSpecificConfiguration({
                    authStrategy: event.target.value as AuthorizationStrategy,
                  })
                }
              >
                <MenuItem value={AuthorizationStrategy.basic}>
                  {AuthorizationStrategy.basic}
                </MenuItem>
                <MenuItem value={AuthorizationStrategy.query}>
                  {AuthorizationStrategy.query}
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </ConfigurationInputContainer> */}
    </>
  );
}

function getPartialWooCommerceSpecificConfiguration(
  configuration?: IWooCommerceConfiguration
): IConfigurationWooCommerceSpecific {
  return {
    key: configuration?.key ?? "",
    secret: configuration?.secret ?? "",
    apiVersion: configuration?.apiVersion ?? WooCommerceApiVersion.v3,
    authStrategy: configuration?.authStrategy ?? AuthorizationStrategy.basic,
    woocommerceLanguage: configuration?.woocommerceLanguage ?? "",
  };
}

const useWooCommerceSpecificConfigurationClasses = createUseClasses(
  (theme) => ({
    title: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "24px",
      margin: "30px 0 10px 0",
    },
    subTitle: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",
      color: "#636364",
      margin: "10px 0 24px 0",
    },
  })
);
