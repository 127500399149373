import { GREETINGS } from "./../../Stores/StoreSettings/GREETINGs";
import { CATEGORY, IFAQ } from "../../Stores/StoreSettings/FAQs";
import { IFlowRule } from "../../Stores/StoreSettings/flowRules";

export const newConfigurationId = "new";

export enum Platform {
  WooCommerce = "WOOCOMMERCE",
  MyCashFlow = "MYCASHFLOW",
  GoogleShoppingXML = "GOOGLE_SHOPPING_XML",
  Magento = "MAGENTO",
  Refox = "REFOX",
  Shopify = "SHOPIFY",
  Sotka = "SOTKA",
  Liana = "LIANA",
}

export type IBackendConfigurationType =
  | `${Platform.WooCommerce}`
  | `${Platform.MyCashFlow}`
  | `${Platform.GoogleShoppingXML}`
  | `${Platform.Magento}`
  | `${Platform.Refox}`
  | `${Platform.Shopify}`
  | `${Platform.Sotka}`
  | `${Platform.Liana}`;

export const defaultPlatform = Platform.WooCommerce;

export enum OperationMode {
  Test = "Test",
  Production = "Production",
}

export enum OperationModeFE {
  Test = "1",
  Production = "0",
}

export const defaultOperationMode = OperationMode.Test;

export const defaultOperationModeFE = OperationModeFE.Production;

export enum Language {
  English = "en",
  Finnish = "fi",
  Swedish = "sv",
  German = "de",
  Netherland = "nl",
}

export enum DisplayLanguage {
  en = "English",
  fi = "Finnish",
  sv = "Swedish",
  de = "German",
}

export const defaultLanguage = Language.Finnish;

export enum AuthorizationStrategy {
  query = "Query",
  basic = "Basic",
  oauth = "OAuth",
}

export enum WooCommerceApiVersion {
  v1 = "v1",
  v2 = "v2",
  v3 = "v3",
}

export enum WooCommerceVersion {
  v26X = "2.6.X",
  v30X = "3.0.X",
  v35X = "3.5.X",
}

interface WooCommerceVersionPair {
  wooCommerceVersion: WooCommerceVersion;
  apiVersion: WooCommerceApiVersion;
}

const wooCommerceVersionPairs: WooCommerceVersionPair[] = [
  {
    wooCommerceVersion: WooCommerceVersion.v26X,
    apiVersion: WooCommerceApiVersion.v1,
  },
  {
    wooCommerceVersion: WooCommerceVersion.v30X,
    apiVersion: WooCommerceApiVersion.v2,
  },
  {
    wooCommerceVersion: WooCommerceVersion.v35X,
    apiVersion: WooCommerceApiVersion.v3,
  },
];

export function getWooCommerceVersion(apiVersion: WooCommerceApiVersion) {
  return (
    wooCommerceVersionPairs.find(
      (pair) => pair.apiVersion === apiVersion
    ) as WooCommerceVersionPair
  ).wooCommerceVersion;
}

export interface IConfigurationCommonBackend {
  id: string;
  platform?: IBackendConfigurationType;
  storeName: string;
  storeNameFormatted: string;
  storeUrl: string;
}
export enum ActivePromotion {
  NEW_ARRIVAL = "NEW_ARRIVAL",
  DAILY_DEALS = "DAILY_DEALS",
}
export interface IExperiment {
  endTs?: number | null; // end time of the experiment
  experimentId: string;
  experimentName: string;
  startTs: number;
  tenantId: string;
}
export interface IConfigurationCommonFrontend {
  operationMode: OperationMode;
  language: Language;
  avatar: string;
  buttonColor: string;
  accentColorLight: string;
  widgetToLeft: boolean;
  minimizedMode?: boolean;
  flows: IFlowRule[];
  customQnA: IFAQ[];
  greetings: GREETINGS;
  customerCategories: CATEGORY[];
  newArrivalTimeLimit?: number | null;
  isNewArrivalTabDisabled?: boolean;
  isDailyDealsDisabled?: boolean;
  activePromotion?: `${ActivePromotion}` | "";
  activeExperimentId?: IExperiment["experimentId"] | null;
  experiments?: IExperiment[];
}

export const defaultAvatar =
  "https://upsy.shoppinghelper.net/files/images/avatar/avatar.jpg";
export const defaultButtonColor = "#000000";
export const defaultAccentColorLight = "#000000";

export type IConfigurationCommon = IConfigurationCommonBackend &
  IConfigurationCommonFrontend;

export interface IConfigurationWooCommerceSpecific {
  key: string;
  secret: string;
  apiVersion: WooCommerceApiVersion;
  authStrategy: string;
  woocommerceLanguage?: Language | "";
}

export interface IWooCommerceConfiguration
  extends IConfigurationCommon,
    IConfigurationWooCommerceSpecific {
  platform: `${Platform.WooCommerce}`;
}

export interface IMyCashFlowConfiguration extends IConfigurationCommon {
  platform: `${Platform.MyCashFlow}`;
}

export interface IGoogleShoppingXMLConfiguration extends IConfigurationCommon {
  platform: `${Platform.GoogleShoppingXML}`;
}

export interface IMagentoConfiguration extends IConfigurationCommon {
  platform: `${Platform.Magento}`;
}

export interface IRefoxConfiguration extends IConfigurationCommon {
  platform: `${Platform.Refox}`;
}

export interface IShopifyConfiguration extends IConfigurationCommon {
  platform: `${Platform.Shopify}`;
}

export interface ILianaConfiguration extends IConfigurationCommon {
  platform: `${Platform.Liana}`;
}

export interface ISotkaConfiguration extends IConfigurationCommon {
  platform: `${Platform.Sotka}`;
}

export type IConfiguration = Partial<
  | IWooCommerceConfiguration
  | IMyCashFlowConfiguration
  | IMagentoConfiguration
  | IGoogleShoppingXMLConfiguration
  | IRefoxConfiguration
  | IShopifyConfiguration
  | ILianaConfiguration
  | ISotkaConfiguration
>;
export type IBackendConfiguration = IBackendConfigurationCommon &
  IBackendConfigurationShopSpecific;

export interface IBackendConfigurationCommon {
  id: string;
  disabled?: boolean;
  mlConfig?: object;
  name?: string;
  tenantId: string;
  type?: IBackendConfigurationType;
  urlBase: string;
}

export interface IBackendConfigurationShopSpecific {
  accessToken?: string;
  apiKey?: string;
  authUser?: string;
  authPassword?: string;
  categoryPageBasePath?: string;
  categoryUrlPath?: string;
  consumerKey?: string;
  consumerSecret?: string;
  convertPrices?: boolean;
  homePageUrl?: string;
  language?: string;
  pageSize?: number;
  productPageBasePath?: string;
  productUrlPath?: string;
  scrapePrice?: boolean;
  updateCategories?: boolean;
  version?: number;
  authStrategy?: string;
}

export const defaultGreeting = {
  homePage: {
    textInBubble: "Hi! What is on your mind?",
  },
  category: {
    upperText: "Hello there!",
  },
  newArrival: {
    upperText: "Hello there",
  },
  dailyDeal: {
    upperText: "Hello there",
  },
  faq: {
    textInBubble: "Any questions?",
    upperText: "Hello there",
  },
  feedback: {
    textInBubble: "Did I manage to help you with your purchase?",
    positiveFeedback: "Thank you! What did you like it?",
    negativeFeedback: "Thank you! What can we improve?",
    thankYouText: "Thank you!",
  },
  upSell: {
    upperText: "",
  },
  crossSell: {
    upperText: "",
  },
};
