import { backendImageUploadClient } from "../../common/Client/backendClient";
import { getAwsTokenFromLocalStorage } from "../../common/utils/token";

export default function ImageUpload() {
  const uploadImage = async (
    img: File | null,
    storeName = ""
  ): Promise<any> => {
    try {
      if (storeName === "" || !img) {
        return false;
      }

      let data = new FormData();
      data.append("file", img);
      const response = await backendImageUploadClient.post(
        `/upload/image/${storeName}`,
        data,
        {
          headers: {
            Authorization: getAwsTokenFromLocalStorage(),
          },
        }
      );
      return response && response.data;
    } catch (err) {
      throw new Error("Failed to upload image");
    }
  };

  return {
    uploadImage,
  };
}
