import axios, { AxiosError } from "axios";
import {
  abTestClient,
  addAuthParameters,
} from "../../common/Client/backendClient";
import { IExperiment } from "../../common/ConfigurationsContext/Configuration";
import { User } from "../../common/UserContext/User";

//[get]stores/{storeId}/ab-test-experiments/quicksight-embedded-url
export const getAbTestQuickSightEmbedUrl = async (
  user: User,
  storeId: string
) => {
  try {
    const response = await abTestClient.get<{ embedUrl: string }>(
      `stores/${storeId}/ab-test-experiments/quicksight-embedded-url?${addAuthParameters(
        user
      )}`
    );
    return response.data;
  } catch (e) {
    throw processErrorResponse(e as Error | AxiosError);
  }
};

//[post]v1/stores/{storeId}/ab-test-experiments
export const startAbTest = async (
  user: User,
  storeId: string,
  payload?: Record<string, unknown>
) => {
  try {
    const response = await abTestClient.post<IExperiment>(
      `stores/${storeId}/ab-test-experiments?${addAuthParameters(user)}`,
      JSON.stringify(payload)
    );
    return response.data;
  } catch (e) {
    throw processErrorResponse(e as Error | AxiosError);
  }
};

//[patch]/v1/stores/{storeId}/ab-test-experiments/{experimentId}
export const endAbTest = async (
  user: User,
  storeId: string,
  experimentId: string
): Promise<IExperiment> => {
  try {
    const response = await abTestClient.patch<IExperiment>(
      `stores/${storeId}/ab-test-experiments/${experimentId}?${addAuthParameters(
        user
      )}`
    );
    return response.data;
  } catch (e) {
    throw processErrorResponse(e as Error | AxiosError);
  }
};

//[post]stores/{storeId}/ab-test-experiments/request-activation
export const requestStartAbTest = async (
  user: User,
  storeId: string,
  payload?: Record<string, unknown>
) => {
  try {
    const response = await abTestClient.post(
      `stores/${storeId}/ab-test-experiments/request-activation?${addAuthParameters(
        user
      )}`,
      payload
    );
    return response.data;
  } catch (e) {
    throw processErrorResponse(e as Error | AxiosError);
  }
};

interface IErrorResponse {
  responseMessage?: string;
  response?: {
    errors: {
      errorTitle: string;
    }[];
  };
}
function processErrorResponse(e: Error | AxiosError) {
  if (!axios.isAxiosError(e)) {
    console.error("not axios error");
    return e;
  }
  const errorResponse: IErrorResponse = e.response?.data;
  const errorMessage =
    errorResponse?.response?.errors?.[0]?.errorTitle ??
    "Sorry something went wrong!";
  return new ABTestError(errorMessage);
}
export class ABTestError extends Error {}
