import { useParams } from "react-router-dom";
import { useUserContext } from "../../../common/UserContext/UserContext";
import { IStoreRouteParams } from "../../../AppRoutes";
import { useState } from "react";
import { useLocalStorage } from "react-use";
import {
  DescriptionGenerateApiPayLoad,
  ILLMChat,
} from "../Types/ProductDescription";
import { llmProductDescriptionGenerate } from "../ProductDescription/product-description-generator.service";
import axios from "axios";

export function useProductDescriptionChat() {
  const { user } = useUserContext();
  const { id = "" } = useParams<IStoreRouteParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null | string>(null);
  const localStorageKey = `${id}:openai:response:product-description`;
  const [_, setChats] = useLocalStorage<ILLMChat[]>(localStorageKey, []);
  const chats = _ || [];
  const clearError = () => {
    setError(null);
  };
  const generate = async ({
    title,
    attributes,
    withUpsy,
    language,
    apiKey,
  }: Omit<DescriptionGenerateApiPayLoad, "prompt"> &
    Pick<ILLMChat, "title" | "withUpsy" | "attributes">) => {
    try {
      setError(null);
      setLoading(true);
      const { chat, chatId } = await llmProductDescriptionGenerate<
        { chat: string; chatId: string },
        DescriptionGenerateApiPayLoad
      >(user, id, {
        apiKey,
        language,
        prompt: generateProductDescriptionPrompt(title, language, attributes),
      });

      setChats((prev) => [
        ...(prev || []),
        {
          id: chatId,
          title,
          description: chat,
          attributes,
          withUpsy,
        },
      ]);
      setLoading(false);
      return { chat, chatId };
    } catch (e) {
      setLoading(false);
      setError(formatProductDescriptionGenerateNetworkError(e));
    }
  };

  return { chats, error, loading, generate, clearError };
}

function generateProductDescriptionPrompt(
  title: string,
  language: string,
  attributes = ""
): string {
  return `Generate a compelling product description for this product title - ${title} ${
    attributes ? `and product attributes - ${attributes}` : ""
  }. Make sure the description highlights the key features, benefits, and unique selling points of the product. Write the description in ${language}`;
}

function formatProductDescriptionGenerateNetworkError(e: unknown) {
  let error = "";
  if (axios.isAxiosError(e)) {
    console.log(e.response?.data?.response?.errors);
    const errors = (e.response?.data?.response?.errors || []) as {
      errorCode?: string;
      errorTitle?: string;
      errorDebugDescription?: string;
    }[];
    const firstError = errors[0] || {};
    error =
      firstError.errorDebugDescription ||
      firstError.errorTitle ||
      "Something went wrong, please try again.";
  } else if (e instanceof Error) {
    error = e.message;
  } else if (typeof e === "string" && e) {
    error = e;
  } else {
    error = "Something went wrong, please try again.";
  }
  return error;
}
