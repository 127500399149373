import { useEffect, useRef, useState } from "react";
import { ILLMChat } from "../../Types/ProductDescription";
import { useCopyToClipboard } from "react-use";
import { Button, Grid, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { Skeleton } from "@material-ui/lab";
import { CopyIcon } from "../../../../common/Icons/CopyIcon";

type TimeoutType = ReturnType<typeof setTimeout>;
export function ChatCard({
  chat: { id, title, description = "", attributes = "" },
}: {
  chat: ILLMChat;
}) {
  const copyTimeoutHandlerRef = useRef<TimeoutType | null>(null);
  const [_, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (copyTimeoutHandlerRef.current)
        clearTimeout(copyTimeoutHandlerRef.current);
    };
  }, []);

  const handleCopy = (text: string) => {
    if (copyTimeoutHandlerRef.current)
      clearTimeout(copyTimeoutHandlerRef.current);
    try {
      copyToClipboard(text);
      setIsCopied(true);
      copyTimeoutHandlerRef.current = setTimeout(() => {
        setIsCopied(false);
      }, 2500);
    } catch (error) {
      setIsCopied(false);
    }
  };

  return (
    <Grid
      container
      item
      direction="column"
      style={{
        gap: "4px",
        padding: "12px",
        boxShadow: "2px 2px 10px 0 rgba(0,0,0,0.1)",
      }}
    >
      <Typography style={{ fontWeight: 500 }}>Product title</Typography>
      <Typography style={{ lineHeight: 1.75 }}>{title}</Typography>
      {attributes ? (
        <>
          <Typography style={{ fontWeight: 500 }}>
            Product attributes/tags
          </Typography>
          <Typography style={{ lineHeight: 1.75 }}>{attributes}</Typography>
        </>
      ) : null}
      {description ? (
        <Grid container item>
          <Grid
            container
            item
            justify="space-between"
            style={{ alignItems: "center" }}
          >
            <Typography style={{ fontWeight: 500 }}>
              Product description
            </Typography>
            <Button onClick={() => handleCopy(description)}>
              {!isCopied ? (
                <Grid container item style={{ alignItems: "center" }}>
                  <CopyIcon fontSize="small" style={{ color: "#a3a2a2" }} />
                  <Typography style={{ marginLeft: "5px", fontSize: "12px" }}>
                    Copy
                  </Typography>
                </Grid>
              ) : (
                <Grid container item style={{ alignItems: "center" }}>
                  <DoneIcon fontSize="small" style={{ color: "#a3a2a2" }} />
                  <Typography style={{ marginLeft: "5px", fontSize: "12px" }}>
                    Copied!
                  </Typography>
                </Grid>
              )}
            </Button>
          </Grid>
          <Typography style={{ lineHeight: 1.75 }}>{description}</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

export function ChatCardSkeleton() {
  return (
    <Grid item container style={{ gap: 8 }}>
      <Grid direction="column" item container style={{ width: "100%" }}>
        <Skeleton variant="text" width={200} animation={false} />
        <Skeleton variant="text" animation="wave" width={"75%"} />
      </Grid>
      <Grid direction="column" item container style={{ width: "100%" }}>
        <Skeleton variant="text" width={200} animation={false} />
        <Skeleton variant="text" animation="wave" width={"80%"} />
      </Grid>
      <Grid direction="column" item container style={{ width: "100%" }}>
        <Skeleton variant="text" width={200} animation={false} />
        <Skeleton variant="text" animation="wave" width={"85%"} />
        <Skeleton variant="text" animation="wave" width={"90%"} />
        <Skeleton variant="text" animation="wave" width={"95%"} />
        <Skeleton variant="text" animation="wave" width={"95%"} />
        <Skeleton variant="text" animation="wave" width={"45%"} />
      </Grid>
    </Grid>
  );
}
