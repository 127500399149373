import * as React from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { LoginCredentials } from "../common/LoginCredentials/LoginCredentials";
import { StaticRoutePath } from "../AppRoutes";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { Grid, TextField, Typography, Link } from "@material-ui/core";
import { useState } from "react";
import { Title } from "../common/Title/Title";
import { AuthWrapper } from "../common/AuthWrapper/AuthWrapper";
import StyledLabel from "../common/StyledLabel/StyledLabel";

export function Register() {
  const { user, register } = useUserContext();
  const [fullName, setFullName] = useState("");
  const shouldShowConfirmEmailView = user && !user.verified;

  async function handleSubmit(email: string, password: string) {
    await register(fullName, email, password);
  }

  if (user) {
    return <Navigate to={StaticRoutePath.Root} />;
  }

  return shouldShowConfirmEmailView ? (
    <Navigate to={StaticRoutePath.ConfirmEmail} />
  ) : (
    <AuthWrapper>
      <Grid container item xs={12} alignItems="center" spacing={1}>
        <Grid item xs={12} style={{ marginBottom: "18px" }}>
          <Title>Register</Title>
        </Grid>
        <LoginCredentials
          submitButtonTitle="Register"
          onSubmit={handleSubmit}
          beforeContent={
            <Grid item xs={12}>
              <StyledLabel> Full Name </StyledLabel>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Grid>
          }
        />
        <Grid item justify="center">
          <Typography variant="body2">
            Already registered?{" "}
            <Link component={RouterLink} to={StaticRoutePath.Login}>
              Login
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
