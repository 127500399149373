import { styled } from "@material-ui/styles";

const StyledLabel = styled("label")({
  display: "block",
  fontWeight: 500,
  fontSize: "16px",
  margin: "2px 0px 3px",
});

export default StyledLabel;
