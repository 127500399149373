import React from "react";
import TextField from "@material-ui/core/TextField";
import { ReactElementPropsType } from "../ElementProps";
import { createUseClasses } from "../Theme/createUseClasses";
import { Grid } from "@material-ui/core";

export default function CustomTextField(
  props: ReactElementPropsType<typeof TextField> & {
    labelText?: string;
    labelFor?: string;
    labelClassName?: string;
  }
) {
  const classes = useClasses(props);
  const { labelText, labelFor, labelClassName } = props;
  return (
    <Grid container direction="column" item xs={12}>
      {labelText && (
        <Grid item xs={12}>
          <label htmlFor={labelFor} className={labelClassName || classes.label}>
            {labelText}
          </label>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          id={labelFor}
          classes={{ root: classes.inputRoot }}
          variant="outlined"
          {...props}
        />
      </Grid>
    </Grid>
  );
}

const useClasses = createUseClasses((theme) => ({
  label: (props: { required?: boolean }) => {
    return {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",
      color: "#202F4E",
      display: "block",
      width: "100%",
      margin: "10px 0px",
      "&:after": {
        content: props.required ? "'*'" : "''",
      },
    };
  },
  inputRoot: {
    "& .MuiInputBase-root": {
      "&:is(.Mui-disabled)": {
        backgroundColor: "rgba(0,0,0,0.1)",
        color: "rgba(0,0,0,0.3)",
        "&:not(:hover) fieldset": {
          border: "1px solid rgba(0,0,0,0.2)",
        },
      },
      "& textarea": {
        resize: (props: { multiline?: boolean }) =>
          props?.multiline ? "vertical" : null,
        flex: (props: { multiline?: boolean }) => (props?.multiline ? 1 : null),
      },
      background: "#ffffff",
      marginBottom: "16px",
      boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.04)",
      borderRadius: "6px",
      "& fieldset": {
        borderColor: "#E1EAFF",
      },
      "& input": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        paddingBlock: "16px",
        paddingLeft: "16px",
        color: "#636364",
      },
      "&:hover fieldset": {
        borderColor: "#E1EAFF",
        borderWidth: "2px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6793FF",
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      marginTop: "-8px",
      color: "#C60101",
      fontSize: "14px",
      fontStyle: "italic",
    },
  },
}));
