import {
  defaultAvatar,
  defaultButtonColor,
  defaultLanguage,
  defaultOperationMode,
  DisplayLanguage,
  IConfiguration,
  IConfigurationCommonFrontend,
  Language,
  OperationMode,
  defaultAccentColorLight,
} from "../../common/ConfigurationsContext/Configuration";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { UpsyPreview, UpsyPreviewContainer } from "./UpsyPreview";
import { SettingsWizardPageProps } from "./SettingsWizardPage";

import { CompactPicker } from "react-color";
import { CustomHtmlTooltip } from "../../common/Tooltip/Tooltip";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { transformCommonProperties } from "../../common/ConfigurationsContext/dashboardToBackendTransformer";
import CustomTextField from "../../common/CustomUiComponent/TextField";
import CustomSelectField from "../../common/CustomUiComponent/Select";
import { useEffect, useState } from "react";
import { ImageUploadWidget } from "../../common/ImageUploader/ImageUpload";

export function CommonFrontendConfiguration({ id }: SettingsWizardPageProps) {
  const { configuration, setCommonFrontendConfiguration } =
    useConfiguration(id);
  const {
    operationMode,
    language,
    avatar,
    buttonColor,
    widgetToLeft,
    minimizedMode,
    accentColorLight,
  } = getPartialCommonFrontendConfiguration(configuration);
  const classes = useClasses();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommonFrontendConfiguration({
      widgetToLeft: event.target.value === "true" ? true : false,
    });
  };
  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommonFrontendConfiguration({
      minimizedMode: event.target.value === "true" ? true : false,
    });
  };

  const [imageURL, setImageURL] = useState(avatar);

  const config = configuration
    ? transformCommonProperties(configuration).frontendConfiguration
    : null;

  useEffect(() => {
    setCommonFrontendConfiguration({
      avatar: imageURL,
    });
  }, [imageURL]);

  return (
    <>
      <Grid container item xs={12} md={9} lg={7} spacing={2}>
        <ImageUploadWidget
          id={id}
          setImageURLFunction={setImageURL}
          buttonLabelName="Change Logo"
        />
        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={classes.avatarContainer}
        >
          <Typography
            className={classes.typographyForLogoUploadTitle}
            style={{ marginBottom: "14px" }}
          >
            Select Operation Mode
          </Typography>
          <Typography
            className={classes.typographyForLogoUploadRules}
            style={{ marginBottom: "22px" }}
          >
            You can always change the setting.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomHtmlTooltip title="Select whether this configuration is used in production environment or not.">
            <FormControl>
              <CustomSelectField
                labelFor="Operation mode"
                labelText="Operation mode"
                placeholder="Operation mode"
                variant="outlined"
                fullWidth
                value={operationMode}
                onChange={(event) =>
                  setCommonFrontendConfiguration({
                    operationMode: event.target.value as OperationMode,
                  })
                }
              >
                <MenuItem value={OperationMode.Test}>
                  {OperationMode.Test}
                </MenuItem>
                <MenuItem value={OperationMode.Production}>
                  {OperationMode.Production}
                </MenuItem>
              </CustomSelectField>
            </FormControl>
          </CustomHtmlTooltip>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <CustomHtmlTooltip title="Select upsy UI language.">
            <FormControl>
              <CustomSelectField
                labelFor="Language"
                labelText="Language"
                placeholder="Language"
                variant="outlined"
                fullWidth
                disabled={true}
                value={language}
                onChange={(event) =>
                  setCommonFrontendConfiguration({
                    language: event.target.value as Language,
                  })
                }
              >
                <MenuItem value={Language.Finnish}>
                  {DisplayLanguage.fi}
                </MenuItem>
                <MenuItem value={Language.English}>
                  {DisplayLanguage.en}
                </MenuItem>
                <MenuItem value={Language.Swedish}>
                  {DisplayLanguage.se}
                </MenuItem>
              </CustomSelectField>
            </FormControl>
          </CustomHtmlTooltip>
        </Grid> */}
        {/* Upsy widget mode  */}
        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={classes.avatarContainer}
        >
          <Typography className={classes.typographyForLogoUploadTitle}>
            Select widget mode
          </Typography>
          <Typography className={classes.typographyForLogoUploadRules}>
            You can always change this setting.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={`${classes.avatarContainer}`}
        >
          <RadioGroup
            aria-label="upsy widget mode"
            name="mode"
            classes={{ root: classes.radioGroupRootStyle }}
            value={minimizedMode ? "true" : "false"}
            onChange={handleModeChange}
          >
            <FormControlLabel
              value={"false"}
              control={<Radio color="primary" />}
              label="Normal"
            />
            <FormControlLabel
              value={"true"}
              control={<Radio color="primary" />}
              label="Minimized"
            />
          </RadioGroup>
        </Grid>
        {/* Widget direction */}
        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={classes.avatarContainer}
        >
          <Typography className={classes.typographyForLogoUploadTitle}>
            Select widget direction
          </Typography>
          <Typography className={classes.typographyForLogoUploadRules}>
            You can always change this setting.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={`${classes.avatarContainer} ${classes.gridItemBreak}`}
        >
          <RadioGroup
            aria-label="direction"
            name="direction"
            classes={{ root: classes.radioGroupRootStyle }}
            value={widgetToLeft ? "true" : "false"}
            onChange={handleChange}
          >
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="Right aligned"
            />
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="Left aligned"
            />
          </RadioGroup>
        </Grid>

        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={classes.avatarContainer}
        >
          <Typography className={classes.typographyForLogoUploadTitle}>
            Select widget color
          </Typography>
          <Typography className={classes.typographyForLogoUploadRules}>
            Select one color for the widget style. One of the brand colors works
            the best.
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridItemBreak}>
          <CustomHtmlTooltip title="Select button color for upsy widget (see preview in the right corner).">
            <div>
              <Typography variant="subtitle1">Button color</Typography>
              <CompactPicker
                styles={{ default: { compact: { width: "100%" } } }}
                color={buttonColor}
                onChangeComplete={(color) =>
                  setCommonFrontendConfiguration({
                    buttonColor: color.hex,
                  })
                }
              />
            </div>
          </CustomHtmlTooltip>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex" }}
          className={classes.gridItemBreak}
        >
          <Typography
            className={classes.typographyForLogoUploadRules}
            style={{ marginTop: "100px" }}
          >
            Your primary selected color:
          </Typography>
          <Box
            className={classes.buttonColorBox}
            style={{ backgroundColor: `${buttonColor}` }}
          ></Box>
        </Grid>

        <Grid
          item
          xs={12}
          classes={{ root: classes.gridItemRoot }}
          className={classes.avatarContainer}
        >
          <Typography className={classes.typographyForLogoUploadTitle}>
            Select Secondary widget color
          </Typography>
          <Typography className={classes.typographyForLogoUploadRules}>
            Select one color for the Secondary widget style.
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridItemBreak}>
          <CustomHtmlTooltip title="Select Secondary color for upsy widget (see preview in the bottom corner).">
            <div>
              <Typography variant="subtitle1">Secondary color</Typography>
              <CompactPicker
                styles={{ default: { compact: { width: "100%" } } }}
                color={accentColorLight}
                onChangeComplete={(color) =>
                  setCommonFrontendConfiguration({
                    accentColorLight: color.hex,
                  })
                }
              />
            </div>
          </CustomHtmlTooltip>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex" }}
          className={classes.gridItemBreak}
        >
          <Typography
            className={classes.typographyForLogoUploadRules}
            style={{ marginTop: "100px" }}
          >
            Your secondary selected color:
          </Typography>
          <Box
            className={classes.buttonColorBox}
            style={{ backgroundColor: `${accentColorLight}` }}
          ></Box>
        </Grid>
        <Typography
          className={classes.typographyForLogoUploadTitle}
          style={{ marginBottom: "24px" }}
        >
          Widget preview
        </Typography>
      </Grid>
      {config && (
        //eslint-disable-next-line react/jsx-no-undef
        <Grid item xs={12} lg={7}>
          <UpsyPreviewContainer>
            <UpsyPreview config={config} />
          </UpsyPreviewContainer>
        </Grid>
      )}
    </>
  );
}

function getPartialCommonFrontendConfiguration(
  configuration?: IConfiguration
): Omit<
  IConfigurationCommonFrontend,
  "flows" | "customQnA" | "customerCategories" | "greetings"
> {
  return {
    operationMode: configuration?.operationMode ?? defaultOperationMode,
    language: configuration?.language ?? defaultLanguage,
    widgetToLeft: (configuration?.widgetToLeft ?? false) as boolean,
    minimizedMode: (configuration?.minimizedMode ?? false) as boolean,
    avatar:
      configuration?.avatar && configuration?.avatar !== ""
        ? configuration?.avatar
        : defaultAvatar,
    buttonColor:
      configuration?.buttonColor && configuration?.buttonColor !== ""
        ? configuration?.buttonColor
        : defaultButtonColor,
    accentColorLight:
      configuration?.accentColorLight && configuration?.accentColorLight !== ""
        ? configuration?.accentColorLight
        : defaultAccentColorLight,
  };
}

const useClasses = createUseClasses((theme) => ({
  root: {
    width: "100%",
    display: "block",
    background: "rgb(252, 238, 230)",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    padding: "0.7rem",
    borderRadius: "0.5rem",
  },
  avatarContainer: {
    marginLeft: "5px",
    marginBottom: "10px",
  },
  typographyForLogoUploadTitle: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "0.04em",
    color: "#202F4E",
  },
  typographyForLogoUploadRules: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "20px",
    letterSpacing: "0.02em",
    color: "#636364",
  },
  gridItemRoot: {
    "&.MuiGrid-item": {
      padding: "4px",
    },
  },
  radioGroupRootStyle: {
    "&.MuiFormGroup-root": {
      flexDirection: "row",
    },
  },
  buttonColorBox: {
    height: "15px",
    width: "15px",
    marginTop: "105px",
    marginLeft: "5px",
  },
  gridItemBreak: {
    marginBottom: "68px",
  },
  imageContainer: {
    paddingTop: "36px",
    paddingBottom: "28px",
    width: "450px",
    height: "159px",
    backgroundColor: "#FCEEE6",
    borderStyle: "dashed",
    textAlign: "center",
    border: "1px dashed  #6D6D6D",
    borderRadius: "5px",
  },
  imageTypo: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.5px",
    color: "#636364",
  },
  imageLink: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.5px",
    color: "#0093B4",
    marginRight: "5px",
    textDecoration: "underline",
  },
  button: {
    height: "35px",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "capitalize",
    borderRadius: "10px",
  },
  imageUploaderButton: {
    minWidth: "160px",
  },
  imageBoxContainer: {
    [theme.breakpoints.up("lg")]: {
      gap: "10px",
    },
    gap: "30px",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "68px",
  },
  dropDownLabelStyle: {
    padding: "18.5px 16px",
  },
}));
