import {
  Grid,
  Typography,
  Box,
  FormControl,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import CustomSelectField from "../../common/CustomUiComponent/Select";
import CustomTextField from "../../common/CustomUiComponent/TextField";
import { ImageUploadWidget } from "../../common/ImageUploader/ImageUpload";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { CustomHtmlTooltip } from "../../common/Tooltip/Tooltip";
import { SettingsWizardPageProps } from "./SettingsWizardPage";
import { CATEGORY } from "./FAQs";
import { useCategory } from "./CommonFrontendCategories";

const orderIncrementBy = 2;

export function CategoryForm({ id }: SettingsWizardPageProps) {
  const [categoryAvatarURL, setCategoryAvatarURL] = useState("");
  const { setCommonFrontendConfiguration, configuration } =
    useConfiguration(id);

  const categoryLength =
    (configuration && configuration.customerCategories?.length) || 0;
  const defaultOrder = categoryLength + 1;
  const [order, setOrder] = useState(defaultOrder);

  useEffect(() => {
    const categoryLength =
      (configuration && configuration.customerCategories?.length) || 0;
    const defaultOrder = categoryLength + 1;
    setOrder(defaultOrder);
  }, [categoryLength]);

  const defaultCategory: CATEGORY = {
    categoryName: "",
    categoryAvatarURL: "",
    categoryURL: "",
    order,
  };
  const {
    resetLang,
    handleCategoryChange,
    categoryName,
    categoryURL,
    categoryState,
    resetCategory,
  } = useCategory({
    category: defaultCategory,
  });

  const handleCategorySubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const oldCategory = configuration?.customerCategories ?? [];

    if (order < oldCategory.length + 1) {
      const categoryReplaced = [
        ...oldCategory.slice(0, order - 1),
        {
          categoryName: categoryState.categoryName,
          categoryURL: categoryState.categoryURL,
          categoryAvatarURL,
          order: Date.now(),
          isPinned: false,
          locale: {
            ...categoryState.locale,
          },
        },
        ...oldCategory.slice(order - 1, oldCategory.length),
      ];

      setCommonFrontendConfiguration({
        customerCategories: categoryReplaced,
      });
    } else {
      setCommonFrontendConfiguration({
        customerCategories: [
          ...oldCategory,
          {
            categoryName: categoryState.categoryName,
            categoryURL: categoryState.categoryURL,
            categoryAvatarURL,
            order: Date.now(),
            isPinned: false,
            locale: {
              ...categoryState.locale,
            },
          },
        ],
      });
    }
    resetInputFields();
  };

  const resetInputFields = () => {
    setOrder(defaultOrder);
    resetCategory();
    resetLang();
  };

  const classes = useClasses();
  return (
    <>
      <Grid item xs={12} sm={6}>
        <CustomHtmlTooltip title="Enter Category Name">
          <CustomTextField
            required
            labelText="Category Name"
            labelFor="Category Name"
            variant="outlined"
            placeholder="Write category name"
            name="categoryName"
            value={categoryName}
            onChange={handleCategoryChange}
          />
        </CustomHtmlTooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomHtmlTooltip title="Choose which order the added category appears in the list">
          <FormControl>
            <CustomSelectField
              labelFor="Order"
              labelText="Order"
              placeholder="Order"
              name="order"
              variant="outlined"
              value={order}
              fullWidth
              onChange={(e) => {
                setOrder(e.target.value as number);
              }}
            >
              <MenuItem value={1}>1</MenuItem>
              {configuration?.customerCategories?.map((_, index) => (
                <MenuItem key={index} value={index + orderIncrementBy}>
                  {index + orderIncrementBy}
                </MenuItem>
              ))}
            </CustomSelectField>
          </FormControl>
        </CustomHtmlTooltip>
      </Grid>

      <Grid item xs={12}>
        <CustomHtmlTooltip title="Provide an web address to an image to be used as Category avatar.">
          <ImageUploadWidget
            id={id}
            setImageURLFunction={setCategoryAvatarURL}
            buttonLabelName="Upload Image"
          />
        </CustomHtmlTooltip>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.categoryTitleStyle}>
          Category URL
        </Typography>
        <Typography
          className={classes.categorySubTitleStyle}
          style={{ marginLeft: "10px" }}
        >
          Add the URL of the selected category page from your website.We need to
          link UPSY and your website's category page.
        </Typography>
        <Typography
          className={classes.categoryInstructionStyle}
          style={{ marginLeft: "10px" }}
        >
          {" "}
          For example:{" "}
        </Typography>
        <Box style={{ display: "flex", marginLeft: "10px" }}>
          {" "}
          <Typography className={classes.categoryInstructionStyle}>
            {" "}
            Category:{" "}
          </Typography>
          <Typography className={classes.categoryInstructionExampleStyle}>
            Dronet
          </Typography>{" "}
        </Box>
        <Box style={{ display: "flex", marginLeft: "10px" }}>
          {" "}
          <Typography className={classes.categoryInstructionStyle}>
            {" "}
            Link:{" "}
          </Typography>
          <Typography className={classes.categoryInstructionExampleStyle}>
            https://www.fotonordic.fi/category/1054/dronet{" "}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <CustomHtmlTooltip title="Add the URL of the selected category page from your website. We need to link UPSY and your website's category page.">
          <CustomTextField
            required
            variant="outlined"
            placeholder="Add Category URL here"
            value={categoryURL}
            name="categoryURL"
            onChange={handleCategoryChange}
          />
        </CustomHtmlTooltip>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainerStyle}>
        <Button
          variant="contained"
          className={classes.cancelButtonStyle}
          onClick={resetInputFields}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "capitalize" }}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleCategorySubmit(e)
          }
        >
          Add
        </Button>
      </Grid>
    </>
  );
}

const useClasses = createUseClasses(() => ({
  avatarContainer: {
    marginLeft: "5px",
    marginBottom: "10px",
  },
  typographyForLogoUploadTitle: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "0.04em",
    color: "#202F4E",
  },
  gridItemRoot: {
    "&.MuiGrid-item": {
      padding: "4px",
    },
  },
  categoryTitleStyle: {
    color: "rgb(32, 47, 78)",
    width: "100%",
    margin: "8px 0px",
    display: "block",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
  },
  gridContainerRootStyle: {
    backgroundColor: "#E1EAFF",
    borderRadius: "15px",
    padding: "10px",
  },
  categorySubTitleStyle: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22.4px",
    letter: "2%",
    color: "#636364",
    marginBottom: "20px",
  },
  categoryInstructionStyle: {
    fontStyle: "italic",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.04em",
    color: "#364F83",
  },
  categoryInstructionExampleStyle: {
    fontStyle: "italic",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.04em",
    color: "#364F83",
    marginLeft: "2px",
  },
  cancelButtonStyle: {
    marginRight: "20px",
    backgroundColor: "white",
    color: "#364F83",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  buttonContainerStyle: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "80px",
  },
}));
