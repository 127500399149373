/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

import { IStoreRouteParams, StaticRoutePath } from "../../AppRoutes";
import {
  useConfiguration,
  useRefreshConfigurations,
} from "../../common/ConfigurationsContext/ConfigurationsContext";
import { Store } from "../Store";
import { isAdmin, useUserContext } from "../../common/UserContext/UserContext";
import { MainContainer } from "../../common/Page/MainContainer";
import { TopContainer } from "../../common/Page/TopContainer";
import { CustomStepper } from "../../common/CustomeStepper";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { Switch } from "@material-ui/core";
import { LoadingButton } from "../../common/LoadingButton/LoadingButton";
import {
  QuicksightEmbedDateParameters,
  QuicksightEmbedParameters,
  embedQuicksight,
  setQuicksightParameters,
} from "./quicksight";
import CustomSelectField from "../../common/CustomUiComponent/Select";
import CustomTextField from "../../common/CustomUiComponent/TextField";
import {
  endAbTest,
  getAbTestQuickSightEmbedUrl,
  requestStartAbTest,
  startAbTest,
} from "./ab-test.service";
import {
  IConfiguration,
  IExperiment,
} from "../../common/ConfigurationsContext/Configuration";
import { User } from "../../common/UserContext/User";

function useExperiment(
  activeExperimentId: string | undefined | null,
  experiments: IExperiment[]
) {
  const { user } = useUserContext();
  const isAdminUser = isAdmin(user);

  const filteredExperiments = useMemo(
    () => experiments.filter((exp) => isAdminUser || !!exp?.endTs),
    [experiments, isAdminUser]
  );

  const sortedExperiments = useMemo(
    () => [...filteredExperiments].sort((a, b) => b.startTs - a.startTs),
    [filteredExperiments]
  );

  const initialExperiment = sortedExperiments.find(
    (exp) => exp.experimentId === activeExperimentId
  );

  const [experimentId, setExperimentId] = useState(
    initialExperiment ? initialExperiment.experimentId : ""
  );

  useEffect(() => {
    const [latestExperiment] = sortedExperiments;
    setExperimentId(latestExperiment?.experimentId || "");
  }, [sortedExperiments]);

  return { experimentId, setExperimentId, experiments: filteredExperiments };
}
//Main Ab test screen
export function ABTest() {
  const { id = "" } = useParams<IStoreRouteParams>();
  const { user } = useUserContext();
  const { configuration } = useConfiguration(id);
  const [steps] = useState<string[]>(["A/B Test"]);
  let { experiments: configExperiments = [], activeExperimentId = "" } =
    (configuration || {}) as IConfiguration;
  const isAdminUser = isAdmin(user);

  const activeExperiment = getActiveExperiment(
    activeExperimentId,
    configExperiments
  );
  const { experimentId, setExperimentId, experiments } = useExperiment(
    activeExperimentId,
    configExperiments
  );
  const selectedExperiment = experiments.find(
    (item) => item.experimentId === experimentId
  );
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    dateValidationError,
    updateEndDate,
    updateStartDate,
    resetValidation,
  } = useDateRange(selectedExperiment);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  function handleDrawerClose() {
    setIsDrawerOpen(false);
  }
  function handleDrawerOpen() {
    setIsDrawerOpen(true);
  }
  const classes = useAbTestConfigurationClasses();
  const { iframeRef, loaded } = useEmbed({
    dateRange: {
      startDate: startDate,
      endDate: endDate,
    },
    experimentId: experimentId || "",
  });
  if (!configuration) {
    return <Navigate to={StaticRoutePath.NotFound} replace={true} />;
  }

  return (
    <>
      <Store>
        <TopContainer style={{ paddingBottom: "16px" }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <CustomStepper
                steps={steps}
                activeStep={0}
                hideStepperProgressBar
                stepLabelContainerAlignment="left"
              />
            </Grid>
          </Grid>
        </TopContainer>
        <MainContainer>
          <Typography className={classes.title}>
            Store A/B test Information
          </Typography>
          <Typography className={classes.subTitle}>
            {
              //TODO: This text have to be change by actual content
            }
            You are performing an <b>A/B</b> test to compare the performance on
            this store when the Upsy is shown versus when it is hidden.
          </Typography>
          <Divider className={classes.divider} />
          <Grid>
            <Typography className={classes.subTitle}>
              Lets understand more about upsy <b>A/B</b> test.{" "}
              <span>&rarr;</span> Read this before requesting <b>A/B</b> test.
              <Button onClick={handleDrawerOpen}>Click here</Button>
            </Typography>
            <Drawer
              anchor={"top"}
              open={isDrawerOpen}
              onClose={handleDrawerClose}
            >
              <Grid>
                <Grid
                  style={{
                    width: "95%",
                    maxWidth: 1280,
                    margin: "30px auto",
                    paddingBlock: 24,
                    paddingInline: 16,
                  }}
                >
                  <IconButton
                    size="small"
                    style={{ position: "fixed", right: 10, top: 10 }}
                    onClick={handleDrawerClose}
                  >
                    <CloseIcon></CloseIcon>
                  </IconButton>
                  <Typography variant="h6" style={{ marginBottom: 5 }}>
                    What is an <b>A/B</b> test?
                  </Typography>
                  <Typography>
                    An A/B test is a simple experiment where two versions of
                    something, like a webpage or an app feature, are compared to
                    see which one performs better. By showing version A to one
                    group of people and version B to another, you can measure
                    which version achieves your goal more effectively. It's a
                    way to make decisions based on data and improve results.
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ marginTop: 16, marginBottom: 5 }}
                  >
                    What is upsy <b>A/B</b> test?
                  </Typography>
                  <Typography>
                    Upsy A/B test means, that 50% of the visitors are seeing
                    variant A (Upsy shown) and 50% of the visitors are seeing
                    variant B (Upsy hidden). In upsy A/B testing, customers are
                    randomly divided into two variants (50%-50%), where one sees
                    the webshop with Upsy and the other sees a webshop without
                    Upsy
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ marginTop: 16, marginBottom: 5 }}
                  >
                    Why upsy <b>A/B</b> test for?
                  </Typography>
                  <Typography variant="body1">
                    With A/B test you can test, how does webshop with Upsy
                    perform comparing to webshop without Upsy. Then, the results
                    are compared to see which variant performs better. Upsy A/B
                    test will gather only those purchases where customer has
                    landed to order-confirmation / thank-you page and actually
                    purchased a product. Upsy A/B test lasts for minimum of 14
                    days. A/B test needs enough purchases and traffic to provide
                    accurate results. Upsy customer success will monitor the
                    test to get required accuracy.
                  </Typography>
                </Grid>
              </Grid>
            </Drawer>
          </Grid>
          <Divider className={classes.dividerSmall} />
          <Grid container item xs={12} lg={12}>
            <Grid
              item
              container
              alignItems="center"
              direction="row"
              style={{ columnGap: 12, rowGap: 8 }}
              justify="space-between"
              xs={12}
            >
              {isAdminUser ? (
                <AdminAction
                  activeExperiment={activeExperiment}
                  user={user}
                  storeId={id}
                />
              ) : (
                <MerchantAction
                  activeExperiment={activeExperiment}
                  user={user}
                  storeId={id}
                />
              )}
            </Grid>
          </Grid>
          <Divider className={classes.dividerSmall} />
          <Grid item container style={{ gap: 18, marginTop: 32 }}>
            {dateValidationError ? (
              <Grid item xs={12}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={resetValidation}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                >
                  {dateValidationError}
                </Alert>
              </Grid>
            ) : null}
            <Grid item xs={12} md={3}>
              <CustomTextField
                onChange={(e) => {
                  updateStartDate(e.target.value);
                }}
                value={startDate}
                variant="outlined"
                type="date"
                labelText="Start Date"
                labelFor="ab-test-start-date"
                id="ab-test-start-date"
                inputProps={{
                  min: minDate,
                  max: maxDate,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomTextField
                onChange={(e) => {
                  updateEndDate(e.target.value);
                }}
                value={endDate}
                variant="outlined"
                type="date"
                labelText="End Date"
                labelFor="ab-test-end-date"
                id="ab-test-end-date"
                inputProps={{
                  min: minDate,
                  max: maxDate,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5} lg={3} style={{ marginLeft: "auto" }}>
              <FormControl>
                <CustomSelectField
                  value={experimentId || " "}
                  onChange={(e) => {
                    setExperimentId(e.target.value as string);
                  }}
                  fullWidth
                  variant="outlined"
                  labelText="Select A/B test experiment"
                  labelFor="ab-test-experiment"
                  defaultValue={" "}
                  id="ab-test-experiment"
                >
                  <MenuItem value={" "}>
                    <small>No Experiment</small>
                  </MenuItem>
                  {experiments.map((exp) => (
                    <MenuItem value={exp.experimentId} key={exp.experimentId}>
                      <small>
                        {
                          <>
                            <span style={{ fontWeight: 500 }}>
                              {exp.experimentName}{" "}
                            </span>
                            <span style={{ fontSize: 11 }}>
                              {`(${formatDate(exp.startTs)} - ${
                                exp.endTs ? formatDate(exp.endTs) : "Running"
                              })`}
                            </span>
                          </>
                        }
                      </small>
                    </MenuItem>
                  ))}
                </CustomSelectField>
              </FormControl>
            </Grid>
          </Grid>
          <>
            <>
              <div style={{ display: selectedExperiment ? "block" : "none" }}>
                <div style={{ paddingBlock: 15 }}>
                  <span style={{ fontWeight: 500 }}>Showing result for </span>
                  <span style={{ fontWeight: 700 }}>
                    {selectedExperiment
                      ? selectedExperiment.experimentName
                      : "No Experiment"}{" "}
                  </span>
                  <span
                    style={{
                      fontSize: 12,
                      wordSpacing: 0,
                    }}
                  >
                    {selectedExperiment
                      ? `(${formatDate(selectedExperiment.startTs)} - ${
                          selectedExperiment.endTs
                            ? formatDate(selectedExperiment.endTs)
                            : "Running"
                        })`
                      : ""}
                  </span>
                </div>
                <div className={classes.metricsContainer} ref={iframeRef}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={`${classes.loaderContainer} ${
                      loaded ? classes.loaded : ""
                    }`}
                  >
                    {!loaded ? <CircularProgress /> : null}
                  </Grid>
                </div>
              </div>
              <div
                style={{
                  padding: 70,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  display: selectedExperiment ? "none" : "flex",
                }}
              >
                <svg
                  width="184"
                  height="152"
                  viewBox="0 0 184 152"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                      <ellipse
                        fill-opacity=".8"
                        fill="#F5F5F7"
                        cx="67.797"
                        cy="106.89"
                        rx="67.797"
                        ry="12.668"
                      ></ellipse>
                      <path
                        d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        fill="#AEB8C2"
                      ></path>
                      <path
                        d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                        fill="url(#linearGradient-1)"
                        transform="translate(13.56)"
                      ></path>
                      <path
                        d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        fill="#F5F5F7"
                      ></path>
                      <path
                        d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        fill="#DCE0E6"
                      ></path>
                    </g>
                    <path
                      d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                      fill="#DCE0E6"
                    ></path>
                    <g transform="translate(149.65 15.383)" fill="#FFF">
                      <ellipse
                        cx="20.654"
                        cy="3.167"
                        rx="2.849"
                        ry="2.815"
                      ></ellipse>
                      <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                    </g>
                  </g>
                </svg>
                <p>no data</p>
              </div>
            </>
          </>
        </MainContainer>
      </Store>
    </>
  );
}
//Utility function to get running experiment
function getActiveExperiment(
  activeExperimentId?: string | null,
  experiments: IExperiment[] = []
) {
  if (!activeExperimentId) {
    return null;
  }
  return experiments.find(
    (experiment) => experiment.experimentId === activeExperimentId
  );
}
function MerchantAction({
  user,
  storeId,
  activeExperiment,
}: {
  user: User;
  storeId: string;
  activeExperiment?: IExperiment | null;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  function updateError(error: string) {
    setError(error);
  }
  function updateSuccess(isSuccess: boolean) {
    setIsSuccess(isSuccess);
  }

  async function requestForStartAbTest() {
    if (activeExperiment) {
      return;
    }
    setIsLoading(true);
    updateSuccess(false);
    updateError("");
    try {
      await requestStartAbTest(user, storeId);
      updateSuccess(true);
    } catch (e) {
      updateError((e instanceof Error ? e.message : e) as string);
    } finally {
      setIsLoading(false);
    }
  }

  const classes = useAbTestConfigurationClasses();
  return (
    <>
      <Collapse style={{ width: "100%" }} in={isSuccess}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                updateSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Your request has been successfully submitted! The Upsy team will be in
          touch with you shortly.
        </Alert>
      </Collapse>
      <Collapse style={{ width: "100%" }} in={Boolean(error)}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                updateError("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Collapse>
      <Typography className={classes.subTitle}>
        To start an <b>A/B</b> test with <b>Upsy</b>, just send a request. Our
        team will swiftly set up the test, delivering tailored insights for
        seamless optimization. Let's optimize together!
      </Typography>
      <LoadingButton
        style={{ width: 150 }}
        onClick={requestForStartAbTest}
        loading={isLoading}
        disabled={Boolean(activeExperiment)}
        variant="outlined"
        color="primary"
        endIcon={<SendIcon style={{ fontSize: 14 }} />}
        buttonText="Send Request"
      />
      <Grid item xs={12}>
        <Typography
          variant="body2"
          gutterBottom
          style={{ fontStyle: "italic", color: "green" }}
        >
          {activeExperiment ? (
            <span>
              There's an ongoing <b>A/B</b> test labeled "
              <b>{activeExperiment.experimentName}</b>" initiated in{" "}
              {new Date(activeExperiment.startTs).toDateString()}. Upon its
              completion, you'll gain access to the results and can then request
              a new A/B test.
            </span>
          ) : null}
        </Typography>
      </Grid>
    </>
  );
}

function AdminAction({
  user,
  storeId,
  activeExperiment,
}: {
  user: User;
  storeId: string;
  activeExperiment?: IExperiment | null;
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useAbTestConfigurationClasses();
  const refreshConfigurations = useRefreshConfigurations();

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
    setError("");
  }

  async function handleExperiment() {
    try {
      setLoading(true);
      if (activeExperiment) {
        await endAbTest(user, storeId, activeExperiment!.experimentId);
      } else {
        //Perform a/b test start functionality
        await startAbTest(user, storeId);
      }
      await refreshConfigurations();
      handleDialogClose();
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Sorry! something went wrong!");
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {activeExperiment ? (
            <span>
              End <b>"{activeExperiment.experimentName}"</b> A/B Test?
            </span>
          ) : (
            <span>
              Start a New <b>A/B</b> Test Experiment?
            </span>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* TODO: Have to update this text */}
            {activeExperiment ? (
              <span>
                Are you sure you want to end the "
                <b>{activeExperiment.experimentName}</b>" A/B test? Ending this
                test will finalize the comparison between the versions where the
                Upsy is shown and where it is hidden on this store.
              </span>
            ) : (
              <span>
                Launch a new <b> A/B</b> test to compare on this store with the
                Upsy shown or hidden variant. Discover which variant leads to
                more purchases and optimize your store accordingly.
              </span>
            )}
          </DialogContentText>
          <Grid>{error ? <Alert severity="error">{error}</Alert> : null}</Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            cancel
          </Button>
          <LoadingButton
            variant="text"
            onClick={handleExperiment}
            loadingText="processing..."
            loading={loading}
            buttonText={!error ? "Yes" : "Retry?"}
          />
        </DialogActions>
      </Dialog>
      <Typography className={classes.subTitle} style={{ fontSize: 15 }}>
        Please use this switch to start or end the <b>A/B</b> test.
      </Typography>
      <FormGroup row className={classes.subTitle}>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              checked={Boolean(activeExperiment)}
              color="primary"
              onChange={handleDialogOpen}
            />
          }
          label="Start"
        />
        <p style={{ marginLeft: 10, fontSize: "1rem", fontWeight: "normal" }}>
          End
        </p>
      </FormGroup>
      {activeExperiment ? (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ fontStyle: "italic", color: "green" }}
          >
            <span>
              <b>"{activeExperiment.experimentName}</b>" is currently running,
              started on {new Date(activeExperiment.startTs).toDateString()}.
            </span>
          </Typography>
        </Grid>
      ) : null}
    </>
  );
}

// Define Date range State Type
interface DateRangeState {
  startDate: string;
  endDate: string;
  startDateValidationError: string;
  endDateValidationError: string;
}

// Define Date range Action Type
type DateRangeAction =
  | {
      type: "startDate";
      payload: {
        date: string;
        minDate: string;
        maxDate: string;
      };
    }
  | {
      type: "endDate";
      payload: {
        date: string;
        minDate: string;
        maxDate: string;
      };
    }
  | {
      type: "resetValidation";
    };

// Create Date range Reducer Function
function dateRangeReducer(
  state: DateRangeState,
  action: DateRangeAction
): DateRangeState {
  switch (action.type) {
    case "resetValidation": {
      return {
        ...state,
        startDateValidationError: "",
        endDateValidationError: "",
      };
    }
    case "startDate": {
      const { maxDate, minDate, date } = action.payload;
      if (!isValidDate(date, minDate, maxDate)) {
        return {
          ...state,
          startDateValidationError: `Start date must be between ${minDate} and ${maxDate}`,
        };
      }

      return {
        ...state,
        startDate: date,
        startDateValidationError: "",
      };
    }
    case "endDate": {
      const { maxDate, minDate, date } = action.payload;
      if (!isValidDate(date, minDate, maxDate)) {
        return {
          ...state,
          endDateValidationError: `End date must be between ${minDate} and ${maxDate}`,
        };
      }

      return {
        ...state,
        endDate: date,
        endDateValidationError: "",
      };
    }
    default:
      return state;
  }
}
//Validate date range
function isValidDate(date: string, minDate: string, maxDate: string) {
  // Early return for non-string inputs
  if (
    [typeof date, typeof minDate, typeof maxDate].every(
      (item) => item !== "string"
    )
  ) {
    return false;
  }
  const dateTs = new Date(formatDate(new Date(date).getTime())).getTime();
  const minDateTs = new Date(minDate).getTime();
  const maxDateTs = new Date(maxDate).getTime();
  const isValid = dateTs >= minDateTs && dateTs <= maxDateTs && !isNaN(dateTs);
  return isValid;
}
function formatDate(timestamp: number) {
  // Create a new Date object from the timestamp
  const date = new Date(timestamp);
  // Extract the year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  // Format the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
}

function useDateRange(experiment: IExperiment | null | undefined) {
  const { id = "" } = useParams<IStoreRouteParams>();
  const minDate = experiment?.startTs
    ? formatDate(experiment.startTs)
    : formatDate(Date.now());
  const maxDate = experiment?.endTs
    ? formatDate(experiment.endTs)
    : formatDate(Date.now());

  function updateStartDate(date: string) {
    dispatch({
      type: "startDate",
      payload: {
        date: date,
        minDate,
        maxDate,
      },
    });
  }
  function updateEndDate(date: string) {
    dispatch({
      type: "endDate",
      payload: {
        date: date,
        minDate,
        maxDate,
      },
    });
  }
  function updateStartAndEndDate(startDate: string, endDate: string) {
    updateStartDate(startDate);
    updateEndDate(endDate);
  }

  function resetValidation() {
    dispatch({ type: "resetValidation" });
  }
  useEffect(() => {
    updateStartAndEndDate(minDate, maxDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDate, maxDate]);

  const initialDateRange: DateRangeState = {
    startDate: minDate,
    endDate: maxDate,
    startDateValidationError: "",
    endDateValidationError: "",
  };

  const [
    { startDate, endDate, startDateValidationError, endDateValidationError },
    dispatch,
  ] = useReducer(dateRangeReducer, initialDateRange);
  return {
    startDate,
    endDate,
    dateValidationError: startDateValidationError || endDateValidationError,
    updateEndDate,
    updateStartAndEndDate,
    updateStartDate,
    resetValidation,
    minDate,
    maxDate,
  };
}
// Hook for embeding quicksight dashboard
function useEmbed(
  parameters: QuicksightEmbedDateParameters & { experimentId?: string }
) {
  const { id = "" } = useParams<IStoreRouteParams>();
  const quicksightParameters: Partial<QuicksightEmbedParameters> = useMemo(
    () => ({
      ...parameters,
      customer: id,
    }),
    [parameters, id]
  );
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loaded, setLoaded] = useState(false);

  const { user } = useUserContext();
  const [url, setUrl] = useState<string>("");
  const [dashboard, setDashboard] = useState<any>();

  useEffect(() => {
    getAbTestQuickSightEmbedUrl(user, id)
      .then((res) => {
        setUrl(res.embedUrl);
      })
      .catch((_) => setLoaded(true));
  }, [user, id]);

  useEffect(() => {
    if (iframeRef.current && url) {
      setDashboard(
        embedQuicksight(
          iframeRef.current,
          url,
          quicksightParameters as QuicksightEmbedParameters,
          setLoaded
        )
      );
    }
    // eslint-disable-next-line
  }, [url]);

  const dependencyParameterString = JSON.stringify(quicksightParameters);
  useEffect(() => {
    if (dashboard && loaded) {
      setQuicksightParameters(
        dashboard as any,
        quicksightParameters as QuicksightEmbedParameters
      );
    }
  }, [dashboard, dependencyParameterString, loaded]);

  return {
    iframeRef,
    loaded,
  };
}

const useAbTestConfigurationClasses = createUseClasses((theme) => ({
  metricsContainer: {
    height: "80vh",
    position: "relative",
    marginTop: 15,
    background: "rgba(239, 239, 240,1)",
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px 0px",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 1,
    transition: "opacity 0.5s",
  },
  loaded: {
    opacity: 0,
    pointerEvents: "none",
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#636364",
  },
  divider: {
    margin: "30px 0",
  },
  dividerSmall: {
    margin: "16px 0",
  },
}));
