import {
  AppBar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  DynamicRoutePath,
  IStoreRouteParams,
  StaticRoutePath,
} from "../../AppRoutes";
import * as React from "react";
import { NavLink, useParams } from "react-router-dom";
import { createUseClasses } from "../Theme/createUseClasses";
import { Title } from "../Title/Title";
import { NavLinkProps } from "react-router-dom";

import {
  useConfiguration,
  useConfigurations,
} from "../ConfigurationsContext/ConfigurationsContext";
import { useUserContext, isAdmin } from "../UserContext/UserContext";
import sidebarBgImg from "./../../common/SVG/sidebar-background.svg";
import logOutIcon from "./../../common/SVG/log-out.svg";
import palceholderBgImage from "./../../common/SVG/placeholderImg.svg";
import { ReactComponent as Logo } from "./../../common/SVG/sidenav-logo.svg";
import { Add } from "@material-ui/icons";
import { newConfigurationId } from "../ConfigurationsContext/Configuration";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CompareIcon from "@material-ui/icons/Compare";
import { AIIcon } from "../Icons/AIIcon";
const UpsyNavLink = React.forwardRef<any, any>(
  (props: NavLinkProps & { activeClassName: string }, ref) => (
    <NavLink
      ref={ref}
      to={props.to}
      className={(activeProps: { isActive: boolean }) => {
        return `${props.className} ${
          activeProps.isActive ? props.activeClassName : ""
        }`;
      }}
    >
      {props.children}
    </NavLink>
  )
);

const getPublicFile = (path: string) => `${process.env.PUBLIC_URL}${path}`;
console.log(process.env.PUBLIC_URL);

export type NavigationPropsType = {
  onOpenDeleteConfirm: () => void;
};
export function DesktopNavigation({
  onOpenDeleteConfirm,
}: NavigationPropsType) {
  const { user, logOut } = useUserContext();
  const classes = useDesktopClasses();
  const { configurations } = useConfigurations();
  const { id = "" } = useParams<IStoreRouteParams>();
  const { configuration } = useConfiguration(id);

  return (
    <div className={`${classes.listContainer} ${classes.customScrollbar}`}>
      <List className={classes.topListItemWrapper}>
        <ListItem className={classes.logo}>
          <Logo />
        </ListItem>
        <ListItem className={classes.flex}>
          <div
            className={classes.avatar}
            style={{
              backgroundImage: `url("${
                configuration?.avatar || palceholderBgImage
              }")`,
            }}
          ></div>
          <Grid container item xs={12} direction="column">
            <Typography className={classes.userName}>{user?.name}</Typography>
            <Typography className={classes.storeName} color="textSecondary">
              {" "}
              {configuration?.storeName?.replace("ups-", "")}
            </Typography>
          </Grid>
        </ListItem>
      </List>
      <List className={classes.middleListItemWrapper}>
        {isAdmin(user) ? (
          <ListItem
            button
            component={UpsyNavLink}
            end
            to={DynamicRoutePath.StoreIntegration(newConfigurationId)}
            activeClassName={id == newConfigurationId ? classes.active : ""}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="New store" />
          </ListItem>
        ) : null}
        {configurations.length > 1 ? (
          <ListItem
            button
            component={UpsyNavLink}
            end
            to={StaticRoutePath.Stores + "/"}
            activeClassName={classes.active}
          >
            <ListItemIcon>
              <img
                alt="Stores"
                src={getPublicFile("dashboard.svg")}
                height={20}
                width={20}
              ></img>
            </ListItemIcon>
            <ListItemText primary="Stores" />
          </ListItem>
        ) : (
          <></>
        )}
        {id && id != newConfigurationId ? (
          <>
            <ListItem
              button
              component={UpsyNavLink}
              to={DynamicRoutePath.StoreIntegration(id)}
              activeClassName={id != newConfigurationId ? classes.active : ""}
            >
              <ListItemIcon>
                <img
                  alt="Integration"
                  src={getPublicFile("integration.svg")}
                  height={20}
                  width={20}
                ></img>
              </ListItemIcon>
              <ListItemText primary="Integration" />
            </ListItem>
            <ListItem
              button
              component={UpsyNavLink}
              to={DynamicRoutePath.StoreSettings(id)}
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <img
                  alt="Settings"
                  src={getPublicFile("settings.svg")}
                  height={20}
                  width={20}
                ></img>
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem
              button
              component={UpsyNavLink}
              to={DynamicRoutePath.StoreMetrics(id)}
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <img
                  alt="Results"
                  src={getPublicFile("results.svg")}
                  height={20}
                  width={20}
                ></img>
              </ListItemIcon>
              <ListItemText primary="Results" />
            </ListItem>
            <ListItem
              button
              component={UpsyNavLink}
              to={DynamicRoutePath.abTest(id)}
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <CompareIcon />
              </ListItemIcon>
              <ListItemText primary="A/B test" />
            </ListItem>
            <ListItem
              button
              component={UpsyNavLink}
              to={DynamicRoutePath.generateAiProductDescription(id)}
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <AIIcon />
              </ListItemIcon>
              <ListItemText primary="AI tools[Beta]" />
            </ListItem>
            {isAdmin(user) ? (
              <ListItem
                button
                onClick={() => {
                  onOpenDeleteConfirm();
                }}
              >
                <ListItemIcon>
                  <DeleteOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Delete Store" />
              </ListItem>
            ) : null}
          </>
        ) : (
          <></>
        )}
      </List>
      <List className={classes.bottomListItemWrapper}>
        <ListItem button onClick={logOut}>
          <ListItemIcon>
            <img alt="Account" src={logOutIcon} height={20} width={20}></img>
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
        <ListItem
          button
          component={UpsyNavLink}
          to={
            id ? DynamicRoutePath.StoreWithAccount(id) : StaticRoutePath.Account
          }
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <img
              alt="Account"
              src={getPublicFile("profile.svg")}
              height={20}
              width={20}
            ></img>
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
      </List>
    </div>
  );
}

export function MobileNavigation({ onOpenDeleteConfirm }: NavigationPropsType) {
  const classes = useMobileClasses();
  const { id = "" } = useParams<IStoreRouteParams>();
  const { logOut, user } = useUserContext();
  return (
    <AppBar>
      <Toolbar>
        <Grid container justify="space-between">
          <Grid item container alignItems="center" xs={9}>
            <Title>upsy</Title>
            <IconButton
              component={UpsyNavLink}
              to={StaticRoutePath.Stores + "/"}
              activeClassName={classes.active}
            >
              <img
                alt="Stores"
                src={getPublicFile("dashboard.svg")}
                height={20}
                width={20}
                className={classes.brighter}
              ></img>
            </IconButton>
            {isAdmin(user) ? (
              <IconButton
                component={UpsyNavLink}
                to={DynamicRoutePath.StoreIntegration(newConfigurationId)}
                activeClassName={id == newConfigurationId ? classes.active : ""}
              >
                <Add style={{ color: "#ffff" }} />
              </IconButton>
            ) : null}
            {id && id != newConfigurationId ? (
              <>
                <IconButton
                  component={UpsyNavLink}
                  to={DynamicRoutePath.StoreIntegration(id)}
                  activeClassName={
                    id != newConfigurationId ? classes.active : ""
                  }
                >
                  <img
                    alt="Integration"
                    src={getPublicFile("integration.svg")}
                    height={20}
                    width={20}
                    className={classes.brighter}
                  ></img>
                </IconButton>
                <IconButton
                  component={UpsyNavLink}
                  to={DynamicRoutePath.StoreSettings(id)}
                  activeClassName={classes.active}
                >
                  <img
                    alt="Settings"
                    src={getPublicFile("settings.svg")}
                    height={20}
                    width={20}
                    className={classes.brighter}
                  ></img>
                </IconButton>
                <IconButton
                  component={UpsyNavLink}
                  to={DynamicRoutePath.StoreMetrics(id)}
                  activeClassName={classes.active}
                >
                  <img
                    alt="Results"
                    src={getPublicFile("results.svg")}
                    height={20}
                    width={20}
                    className={classes.brighter}
                  ></img>
                </IconButton>
                <IconButton
                  component={UpsyNavLink}
                  to={DynamicRoutePath.abTest(id)}
                  activeClassName={classes.active}
                >
                  <CompareIcon style={{ color: "white" }} />
                </IconButton>
                <IconButton
                  component={UpsyNavLink}
                  to={DynamicRoutePath.generateAiProductDescription(id)}
                  activeClassName={classes.active}
                >
                  <AIIcon style={{ color: "white" }} />
                </IconButton>
                {isAdmin(user) ? (
                  <IconButton
                    onClick={() => {
                      onOpenDeleteConfirm();
                    }}
                  >
                    <DeleteOutlinedIcon style={{ color: "white" }} />
                  </IconButton>
                ) : null}
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            xs={3}
            item
            container
            alignItems="flex-start"
            justify="flex-end"
          >
            <IconButton edge="end" onClick={logOut}>
              <img
                alt="LogoutIcon"
                src={logOutIcon}
                height={20}
                width={20}
                className={classes.brighter}
              ></img>
            </IconButton>
            <IconButton
              edge="end"
              component={UpsyNavLink}
              to={StaticRoutePath.Account}
              activeClassName={classes.active}
            >
              <img
                alt="Profile"
                src={getPublicFile("profile.svg")}
                height={20}
                width={20}
                className={classes.brighter}
              ></img>
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const useDesktopClasses = createUseClasses((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    justifyContent: "center",
    margin: 0,
    padding: 0,
    marginTop: 32,
    paddingBottom: "16px",
  },
  flex: {
    display: "flex",
  },
  avatar: {
    marginRight: "12px",
    height: "48px",
    width: "48px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flexGrow: 0,
    flexShrink: 0,
  },
  active: {
    background: "rgba(192, 210, 255, 0.36)",
    color: theme.palette.text.primary,
    position: "relative",
    "&:before": {
      width: "8px",
      height: "100%",
      background: "rgba(178, 200, 255, 1)",
      position: "absolute",
      left: "-8px",
      content: '""',
      "border-radius": "4px 0px 0px 4px",
    },
  },
  topListItemWrapper: {
    borderBottom: "1px solid #F1F4F8",
    paddingTop: 0,
  },
  middleListItemWrapper: {
    flex: 1,
    marginTop: "55px",
    marginBottom: "50px",
    color: theme.palette.text.secondary,
  },
  bottomListItemWrapper: {
    color: theme.palette.text.secondary,
  },
  listContainer: {
    paddingLeft: "2em",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundImage: `url("${sidebarBgImg}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  customScrollbar: {
    overflowY: "auto",
    "scrollbar-width": "thin",
    "scrollbar-color": "#dfdfdf #F5F5F5",
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.15)",
      "border-radius": "10px",
      "background-color": "#F5F5F5",
    },

    "&::-webkit-scrollbar": {
      width: "5px",
      "background-color": "#F5F5F5",
    },

    "&::-webkit-scrollbar-thumb": {
      "border-radius": "10px",
      "box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
      "background-color": "#dfdfdf",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      "background-color": "#cfcfcf",
    },
  },
  userName: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.02em",
  },
  storeName: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.02em",
  },
}));

const useMobileClasses = createUseClasses((theme: Theme) => ({
  appBarItem: {
    color: theme.palette.background.paper,
  },
  active: {
    background: "rgba(192, 210, 255, 0.36)",
    "&:nth-of-type(1)": {
      marginLeft: "3px",
    },
  },
  brighter: {
    filter: "brightness(3)",
  },
}));
