export function getAwsTokenFromLocalStorage() {
  let keys = Object.keys(localStorage),
    i = keys.length,
    tokenValue = "";

  while (i--) {
    if (keys[i].includes("idToken")) {
      tokenValue = keys[i];
      break;
    }
  }
  return localStorage.getItem(tokenValue);
}

function getAwsRefreshTokenFromLocalStorage() {
  let keys = Object.keys(localStorage),
    i = keys.length,
    tokenValue = "";

  while (i--) {
    if (keys[i].includes("refreshToken")) {
      tokenValue = keys[i];
      break;
    }
  }
  return localStorage.getItem(tokenValue);
}

export async function generateNewAccessToken() {
  try {
    const response = await fetch(`${process.env.REACT_APP_AWS_COGNITO_URL}`, {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      },
      mode: "cors",
      cache: "no-cache",
      method: "POST",
      body: JSON.stringify({
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
          REFRESH_TOKEN: getAwsRefreshTokenFromLocalStorage(),
        },
      }),
    });
    const data = await response.json();

    localStorage.setItem(
      "CognitoIdentityServiceProvider.7r2h0qk48nvd2dk055sn4g4fc8.8686061b-8e7a-47bb-b814-1c24a7e39e62.idToken",
      data.AuthenticationResult.IdToken
    );

    return data.AuthenticationResult.IdToken;
  } catch (error) {
    console.log(error);
  }
}
