import { ReactNode, createContext, useContext, useReducer } from "react";

const initialState = {
  currentPageIndex: 0,
  nextBtnDisableForIndex: -1,
};

const actionName = {
  UPDATE_PAGE_INDEX: "UPDATE_PAGE_INDEX",
  UPDATE_NEXT_BTN_DISABLE_FOR_INDEX: "UPDATE_NEXT_BTN_DISABLE_FOR_INDEX",
} as const;

type ActionType =
  | {
      type: typeof actionName.UPDATE_PAGE_INDEX;
      payload: typeof initialState.currentPageIndex;
    }
  | {
      type: typeof actionName.UPDATE_NEXT_BTN_DISABLE_FOR_INDEX;
      payload: typeof initialState.nextBtnDisableForIndex;
    };

interface PageIndexContextType {
  state: typeof initialState;
  dispatch: React.Dispatch<ActionType>;
}

const reducer = (
  state: typeof initialState,
  action: ActionType
): typeof initialState => {
  switch (action.type) {
    case actionName.UPDATE_PAGE_INDEX:
      return { ...state, currentPageIndex: action.payload };
    case actionName.UPDATE_NEXT_BTN_DISABLE_FOR_INDEX:
      return { ...state, nextBtnDisableForIndex: action.payload };
    default:
      return state;
  }
};

const PageIndexContext = createContext<PageIndexContextType | undefined>(
  undefined
);

export const PageIndexProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PageIndexContext.Provider value={{ state, dispatch }}>
      {children}
    </PageIndexContext.Provider>
  );
};

const currentPageUpdateAction = (
  index: typeof initialState.currentPageIndex
): ActionType => {
  return { type: actionName.UPDATE_PAGE_INDEX, payload: index };
};

export const useCurrentPageIndex = () => {
  const {
    state: { currentPageIndex },
    dispatch,
  } = useContext(PageIndexContext)!;
  const updateCurrentPageIndex = (
    index: typeof initialState.currentPageIndex
  ) => {
    dispatch(currentPageUpdateAction(index));
  };

  return {
    currentPageIndex,
    updateCurrentPageIndex,
  };
};

const nextButtonDisableIndexAction = (
  index: typeof initialState.nextBtnDisableForIndex
): ActionType => {
  return { type: actionName.UPDATE_NEXT_BTN_DISABLE_FOR_INDEX, payload: index };
};

export const useNextButtonDisableIndex = () => {
  const {
    state: { nextBtnDisableForIndex },
    dispatch,
  } = useContext(PageIndexContext)!;
  const updateNextBtnDisableForIndex = (
    index: typeof initialState.nextBtnDisableForIndex
  ) => {
    dispatch(nextButtonDisableIndexAction(index));
  };

  return {
    nextBtnDisableForIndex,
    updateNextBtnDisableForIndex,
  };
};
