import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useConfiguration } from "../../../common/ConfigurationsContext/ConfigurationsContext";
import CustomTextField from "../../../common/CustomUiComponent/TextField";
import { SettingsWizardPageProps } from "../SettingsWizardPage";
import ButtonGroup from "./ButtonGroup";
import LineBreak from "./LineBreak";
import useStyles from "./style";
import useHelper from "./useHelper";
import { useGreetingLang } from "../GrettingLangContext";

export default function Faq({ id }: SettingsWizardPageProps) {
  const classes = useStyles();
  const { configuration, setCommonFrontendConfiguration } =
    useConfiguration(id);

  const { countCharacter, errorMessageLabel } = useHelper();
  const { lang } = useGreetingLang();
  const upperText =
    lang === "default"
      ? configuration?.greetings?.faq?.upperText || ""
      : configuration?.greetings?.faq?.locale?.[lang]?.upperText || "";
  const textInBubble =
    lang === "default"
      ? configuration?.greetings?.faq?.textInBubble || ""
      : configuration?.greetings?.faq?.locale?.[lang]?.textInBubble || "";
  const [faq, setFaq] = useState({ upperText, textInBubble });

  useEffect(() => {
    setFaq((prev) => {
      return {
        ...prev,
        textInBubble,
        upperText,
      };
    });
  }, [lang, upperText, setFaq, textInBubble]);

  const defaultHandler = () => {
    const defaultUpperText = configuration?.greetings?.faq?.upperText || "";
    const defaultTextInBubble =
      configuration?.greetings?.faq?.textInBubble || "";
    setFaq((prev) => {
      return {
        ...prev,
        upperText: defaultUpperText,
        textInBubble: defaultTextInBubble,
      };
    });
  };
  const submithandler = () => {
    if (
      countCharacter(faq.textInBubble, 30) ||
      countCharacter(faq.upperText, 20)
    )
      return false;
    let updatedData = {
      ...(configuration?.greetings?.faq || {}),
      upperText: faq.upperText || "",
      textInBubble: faq.textInBubble || "",
    };

    if (lang !== "default") {
      updatedData = {
        ...(configuration?.greetings?.faq || {}),
        upperText: configuration?.greetings?.faq?.upperText || "",
        textInBubble: configuration?.greetings?.faq?.textInBubble || "",
        locale: {
          ...(configuration?.greetings?.faq?.locale || {}),
          [lang]: {
            upperText: faq.upperText || "",
            textInBubble: faq.textInBubble || "",
          },
        },
      };
    }

    setCommonFrontendConfiguration({
      greetings: {
        ...configuration?.greetings,
        faq: updatedData,
      },
    });
  };
  const handleChange = (name: string, value: string) => {
    setFaq((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <>
      <Typography className={classes.title}>Text in Bubble</Typography>
      <CustomTextField
        name="textInBubble"
        value={faq.textInBubble}
        onChange={(e) => handleChange("textInBubble", e.target.value)}
        error={countCharacter(faq.textInBubble, 30)}
        placeholder={"Any questions?"}
        helperText={
          countCharacter(faq.textInBubble, 30) && errorMessageLabel(30)
        }
      />
      <LineBreak />
      <Typography className={classes.title}>Upper Text</Typography>
      <CustomTextField
        name="upperText"
        onChange={(e) => handleChange("upperText", e.target.value)}
        value={faq.upperText}
        error={countCharacter(faq.upperText, 20)}
        placeholder={"Hello there!"}
        helperText={countCharacter(faq.upperText, 20) && errorMessageLabel(20)}
      />
      <ButtonGroup
        submithandler={submithandler}
        defaultHandler={defaultHandler}
      />
    </>
  );
}
