import * as React from "react";
import { Page } from "../common/Page/Page";
import { Button, Grid } from "@material-ui/core";
import { useUserContext } from "../common/UserContext/UserContext";
import { TextField } from "@material-ui/core";
import { Title } from "../common/Title/Title";
import { createUseClasses } from "../common/Theme/createUseClasses";
import { MainContainer } from "../common/Page/MainContainer";

export function Account() {
  const { user, logOut } = useUserContext();
  const classes = useClasses();
  return (
    <Page title={<Title>Account</Title>}>
      <MainContainer>
        {user && (
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={9} lg={3}>
              <TextField
                label="Name"
                value={user.name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={9} lg={3}>
              <TextField
                label="Email"
                value={user.email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" color="secondary" onClick={logOut}>
                Log out
              </Button>
            </Grid>
          </Grid>
        )}
      </MainContainer>
    </Page>
  );
}

const useClasses = createUseClasses((theme) => ({
  root: {
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "row",
    },
  },
}));
