import { Platform } from "../../common/ConfigurationsContext/Configuration";

export interface SettingsWizardPageProps {
  id: string;
}

export interface GreetingHelperPayloadType {
  id: string;
  textInBubble?: string;
  upperText?: string;
  message?: string;
  positiveFeedback?: string;
  negativeFeedback?: string;
  thankYouText?: string;
}
export interface ErrorMessagePageProps {
  characterLength: string;
}

export interface ButtonGroupPageProps {
  defaultHandler: () => void;
  submithandler: () => void;
}

export enum SettingsWizardCommonPageType {
  CommonFrontend = "Styling",
  Summary = "Summary",
  Greetings = "Greetings",
  CommonFrontendFAQs = "FAQs",
  Promotions = "Promotions",
  Categories = "Categories",
  Success = "Success",
}

export type SettingsWizardPageType = SettingsWizardCommonPageType | Platform;

export const fullSettingsWizardPages: SettingsWizardPageType[] = [
  SettingsWizardCommonPageType.CommonFrontend,
  SettingsWizardCommonPageType.CommonFrontendFAQs,
  SettingsWizardCommonPageType.Categories,
  SettingsWizardCommonPageType.Promotions,
  SettingsWizardCommonPageType.Greetings,
  SettingsWizardCommonPageType.Summary,
];

export const SettingsWizardPagesByType = {
  [Platform.WooCommerce]: fullSettingsWizardPages,
  [Platform.Liana]: fullSettingsWizardPages,
  [Platform.Magento]: fullSettingsWizardPages,
  [Platform.MyCashFlow]: fullSettingsWizardPages,
  [Platform.GoogleShoppingXML]: fullSettingsWizardPages,
  [Platform.Refox]: fullSettingsWizardPages,
  [Platform.Sotka]: fullSettingsWizardPages,
  [Platform.Shopify]: fullSettingsWizardPages,
};
