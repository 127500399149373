import * as React from "react";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  IConfiguration,
  newConfigurationId,
} from "../../common/ConfigurationsContext/Configuration";
import { Grid, Typography } from "@material-ui/core";
import { IntegrationWizardPageProps } from "./IntegrationWizardPage";
import { DynamicRoutePath, StaticRoutePath } from "../../AppRoutes";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function ConfigurationSuccess({ id }: IntegrationWizardPageProps) {
  const { configuration } = useConfiguration(id);
  const navigate = useNavigate();
  const redirectTimeInMilliseconds = 1000;
  const { storeName, storeNameFormatted } = configuration as IConfiguration;

  useEffect(() => {
    const timeout = setTimeout(() => {
      const navigateURL =
        id == newConfigurationId
          ? StaticRoutePath.Stores
          : DynamicRoutePath.StoreSettings(id);
      const navigationState = {
        message:
          id != newConfigurationId
            ? ""
            : "Create new store configurations successfully!",
      };
      navigate(navigateURL, { state: navigationState });
    }, redirectTimeInMilliseconds);
    return () => {
      clearInterval(timeout);
    };
  }, []);

  return (
    <>
      <Typography variant="h5">Success</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1">
            Store configuration {storeNameFormatted || storeName}{" "}
            {id == newConfigurationId ? "created" : "updated"} successfully!
          </Typography>
          {id != newConfigurationId ? (
            <Typography variant="body1">
              Go to <Link to={StaticRoutePath.Stores}>stores list</Link> or{" "}
              <Link to={DynamicRoutePath.StoreMetrics(storeName as string)}>
                {storeNameFormatted || storeName} dashboard
              </Link>
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
