import * as React from "react";
import { ReactNode } from "react";
import { DesktopNavigation, MobileNavigation } from "../Navigation/Navigation";
import { Box, Grid, Hidden } from "@material-ui/core";
import { FullPageHeightGrid } from "./FullPageHeightGrid";
import { createUseClasses } from "../Theme/createUseClasses";
import WelcomeScreen from "../../Stores/Dashboard/Welcome";
import { TopContainer } from "./TopContainer";
import StoreDeleteConfirmBox from "../ConfirmBox/StoreDeleteConfirmBox";
import { useConfiguration } from "../ConfigurationsContext/ConfigurationsContext";
import { useNavigate, useParams } from "react-router-dom";
import { IStoreRouteParams, StaticRoutePath } from "../../AppRoutes";

export function Page({
  title,
  internalNavigation,
  children,
}: {
  title?: ReactNode;
  internalNavigation?: ReactNode;
  children?: ReactNode;
}) {
  //state initialize
  const [isStoreDeleteDialogOpen, setIsStoreDeleteDialogOpen] =
    React.useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = React.useState<
    string | null
  >(null);

  //hooks call
  const classes = useClasses();
  const { id = "" } = useParams<IStoreRouteParams>();
  const navigate = useNavigate();
  const { configuration, deleteConfiguration } = useConfiguration(id);

  //handlers
  const handleOpenDeleteConfirmBox = () => {
    if (!id || !configuration) {
      console.error(
        "Failed to open delete confirm dialog because store id or configuration is empty/invalid"
      );
      return;
    }
    setDeleteErrorMessage(null);
    setIsStoreDeleteDialogOpen(true);
  };

  const storeDeleteHandler = async () => {
    if (!id || !configuration) {
      setIsStoreDeleteDialogOpen(false);
      return;
    }
    setIsDeleteLoading(true);

    try {
      const storeName = configuration.storeNameFormatted;
      await deleteConfiguration(id);
      navigate(StaticRoutePath.Stores, {
        replace: true,
        state: { message: `Successfully deleted store: ${storeName}` },
      });
    } catch (error) {
      if (error instanceof Error) {
        setDeleteErrorMessage(error.message);
      } else {
        setDeleteErrorMessage(
          `There is an error happending while deleting the store ${configuration.storeNameFormatted}. This might be cause by network failure or api failure.`
        );
      }
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return (
    <FullPageHeightGrid container>
      <Hidden smDown>
        <Grid item md={3} lg={2} className={classes.navigationContainer}>
          <DesktopNavigation onOpenDeleteConfirm={handleOpenDeleteConfirmBox} />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <MobileNavigation onOpenDeleteConfirm={handleOpenDeleteConfirmBox} />
      </Hidden>
      <Grid
        item
        xs={12}
        md={9}
        lg={10}
        className={classes.pageHeaderAndContent}
      >
        <TopContainer style={{ paddingTop: 36, paddingBottom: 36 }}>
          <WelcomeScreen />
          {internalNavigation && (
            <Box className={classes.noMarginTop}>{internalNavigation}</Box>
          )}
        </TopContainer>
        {children && (
          <Box className={`${classes.pageContent} ${classes.noMarginTop}`}>
            {children}
          </Box>
        )}
      </Grid>
      <StoreDeleteConfirmBox
        configuration={configuration}
        loading={isDeleteLoading}
        errorMessage={deleteErrorMessage}
        onDelete={storeDeleteHandler}
        updateIsOpen={setIsStoreDeleteDialogOpen}
        isOpen={isStoreDeleteDialogOpen}
      />
    </FullPageHeightGrid>
  );
}

const useClasses = createUseClasses((theme) => ({
  noMarginTop: {
    marginTop: 0,
  },
  navigationContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  pageHeaderAndContent: {
    maxHeight: "100vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F4F3F1",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "56px",
    },
  },
  pageContent: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));
