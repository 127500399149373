import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "./Stores/Dashboard/Dashboard";
import { Lobby } from "./Lobby/Lobby";
import { useUserContext } from "./common/UserContext/UserContext";
import { Loader } from "./common/Loader/Loader";
import { Login } from "./Login/Login";
import { Register } from "./Register/Register";
import { ConfirmEmail } from "./Register/ConfirmEmail";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { Account } from "./Account/Account";
import { Stores } from "./Stores/Stores";
import { Metrics } from "./Stores/Metrics/Metrics";
import { NotFound } from "./404/NotFound";
import { PasswordResetRequired } from "./PasswordResetRequired/PasswordResetRequired";
import { StoreIntegration } from "./Stores/StoreIntegration/StoreIntegration";
import { StoreSettings } from "./Stores/StoreSettings/StoreSettings";
import { ABTest } from "./Stores/AbTest";
import { LLMProductDescritionGenerate } from "./Stores/AI/ProductDescription";

export enum StaticRoutePath {
  Root = "/",
  Login = "/login",
  Register = "/register",
  ConfirmEmail = "/confirm-email",
  ForgotPassword = "/forgot-password",
  PasswordResetRequired = "/password-reset-required",
  abTest = "/ab-test",
  generateAiProductDescription = "/ai-tools",
  Stores = "/stores",
  Account = "/account",
  NotFound = "*",
}

export const DynamicRoutePath = {
  Store: (id: string) => `${StaticRoutePath.Stores}/${id}`,
  StoreIntegration: (id: string) =>
    `${StaticRoutePath.Stores}/${id}/integration`,
  StoreSettings: (id: string) => `${StaticRoutePath.Stores}/${id}/settings`,
  StoreDashboard: (id: string) => `${StaticRoutePath.Stores}/${id}/dashboard`,
  StoreMetrics: (id: string) => `${StaticRoutePath.Stores}/${id}/metrics`,
  StoreWithAccount: (id: string) => `${StaticRoutePath.Stores}/${id}/account`,
  PasswordResetRequired: (email: string) =>
    `${StaticRoutePath.PasswordResetRequired}/${email}`,
  abTest: (id: string) => `${StaticRoutePath.abTest}/${id}`,
  generateAiProductDescription: (id: string) =>
    `${StaticRoutePath.generateAiProductDescription}/${id}`,
};

export interface IStoreRouteParams extends Record<string, string | undefined> {
  id?: string;
}
export interface IPasswordResetRequiredParams
  extends Record<string, string | undefined> {
  email?: string;
}
export function AppRoutes() {
  return (
    <Routes>
      <Route path={StaticRoutePath.Root} element={<Lobby />}></Route>
      <Route path={StaticRoutePath.Login} element={<Login />}></Route>
      <Route path={StaticRoutePath.Register} element={<Register />}></Route>
      <Route
        path={StaticRoutePath.ConfirmEmail}
        element={<ConfirmEmail />}
      ></Route>
      <Route
        path={StaticRoutePath.ForgotPassword}
        element={<ForgotPassword />}
      ></Route>
      <Route
        path={DynamicRoutePath.PasswordResetRequired(":email")}
        element={<PasswordResetRequired />}
      ></Route>
      <Route
        path={StaticRoutePath.Stores}
        element={
          <PrivateRoute>
            <Stores />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={DynamicRoutePath.Store(":id")}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={DynamicRoutePath.generateAiProductDescription(":id")}
        element={
          <PrivateRoute>
            <LLMProductDescritionGenerate />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={DynamicRoutePath.abTest(":id")}
        element={
          <PrivateRoute>
            <ABTest />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={DynamicRoutePath.StoreDashboard(":id")}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={DynamicRoutePath.StoreMetrics(":id")}
        element={
          <PrivateRoute>
            <Metrics />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={DynamicRoutePath.StoreIntegration(":id")}
        element={
          <PrivateRoute>
            <StoreIntegration />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={DynamicRoutePath.StoreSettings(":id")}
        element={
          <PrivateRoute>
            <StoreSettings />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={StaticRoutePath.Account}
        element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={DynamicRoutePath.StoreWithAccount(":id")}
        element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path={StaticRoutePath.NotFound}
        element={
          <PrivateRoute>
            <NotFound />
          </PrivateRoute>
        }
      ></Route>
    </Routes>
  );
}

function PrivateRoute({ children }: any) {
  const { loading, user } = useUserContext();
  return loading ? (
    <Loader />
  ) : user && user.verified ? (
    <>{children}</>
  ) : (
    <Navigate to={StaticRoutePath.Login} />
  );
}
