import { Box, Grid, Typography } from "@material-ui/core";
import { ReactComponent as EmojiHandIcon } from "../../common/SVG/handwave.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useUserContext } from "../../common/UserContext/UserContext";

export default function WelcomeScreen() {
  const { user } = useUserContext();

  const classes = useStyles();

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item>
        <Typography
          className={classes.welcomeTypography}
          variant="h6"
          display="inline"
        >
          Welcome,
        </Typography>
        <Typography
          className={classes.userStyles}
          variant="subtitle1"
          display="inline"
        >
          {user?.name}
        </Typography>
        <EmojiHandIcon className={classes.welcomeEmoji} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  welcomeTypography: {
    fontWeight: 700,
    marginRight: "10px",
    lineHeight: "29px",
    letterSpacing: "0.02em",
    fontSize: "24px",
  },
  userStyles: {
    fontWeight: 400,
    lineHeight: "29px",
    letterSpacing: "0.02em",
    fontSize: "24px",
  },
  welcomeEmoji: {
    height: "22.822589874267578px",
    width: "20.90808868408203px",
    borderRadius: "0px",
    marginLeft: "10px",
  },
}));
